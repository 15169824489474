import { createContext, useState } from 'react';

export const GridContext = createContext({
  shouldClearSelection: null,
  setShouldClearSelection: () => {}
});

function NRReactGridContextWrapper({ children }) {
  const [shouldClearSelection, setShouldClearSelection] = useState(false);

  const defaultValues = {
    shouldClearSelection,
    setShouldClearSelection
  };

  return <GridContext.Provider value={defaultValues}>{children}</GridContext.Provider>;
}

export default NRReactGridContextWrapper;
