import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import NRScansIcon from '../../NRIcons/NRScansIcon/NRScansIcon';
import NRHighlightedText from '../NRHighlightedText/NRHighlightedText';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { CONSTANTS } from '../../constants/constants';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const LABELS = {
  SUCCESS: 'Complete',
  INPROGRESS: 'In Progress',
  FAIL: 'Failed'
};

const { IN_PROGRESS, COMPLETED, FAILED } = CONSTANTS.TABLE.ASSETS.STATUS;

function NRStatus({ statusValue, fontSize, style }) {
  const theme = useTheme();
  const status = statusValue;
  switch (statusValue) {
    case COMPLETED:
      return (
        <NRHighlightedText
          icon={<CheckCircleOutlinedIcon style={{ marginRight: theme.spacing(1), fontSize: '13px' }} />}
          color={theme.palette.green[500]}
          highlightAll={true}
          text={<span style={{ lineHeight: '20px' }}>{LABELS[statusValue]}</span>}
          {...(!!fontSize && { fontSize })}
          {...(!!style && { style })}
        />
      );
    case IN_PROGRESS:
      return (
        <NRHighlightedText
          icon={<FontAwesomeIcon icon={faPlay} style={{ marginRight: theme.spacing(1) }} size={'xs'} />}
          color={theme.palette.secondary.light}
          highlightAll={true}
          text={<span style={{ lineHeight: '20px' }}>{LABELS[statusValue]}</span>}
          {...(!!fontSize && { fontSize })}
          {...(!!style && { style })}
        />
      );
    case FAILED:
      return (
        <NRHighlightedText
          icon={<CancelOutlinedIcon style={{ marginRight: theme.spacing(1), fontSize: '13px' }} />}
          color={theme.palette.primary.main}
          highlightAll={true}
          text={<span style={{ lineHeight: '20px' }}>{LABELS[statusValue]}</span>}
          {...(!!fontSize && { fontSize })}
          {...(!!style && { style })}
        />
      );
    default:
      return (
        <NRHighlightedText
          icon={<NRScansIcon style={{ marginRight: theme.spacing(1) }} />}
          color={theme.palette.neutral[900]}
          text={'N/A'}
          highlightAll={true}
          {...(!!fontSize && { fontSize })}
          {...(!!style && { style })}
        />
      );
  }
}
export default NRStatus;
