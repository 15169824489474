export default theme => ({
  button: {
    padding: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    cursor: 'pointer',
    minWidth: 'unset',
    color: theme.palette.text.icon,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      color: theme.palette.text.secondary
    }
  }
});
