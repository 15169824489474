function NRExploitCodeIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7V9C4 9.55 3.55 10 3 10H2V14H3C3.55 14 4 14.45 4 15V17C4 18.65 5.35 20 7 20H10V18H7C6.45 18 6 17.55 6 17V15C6 13.7 5.16 12.58 4 12.17V11.83C5.16 11.42 6 10.3 6 9V7C6 6.45 6.45 6 7 6H10V4H7C5.35 4 4 5.35 4 7Z"
        fill={color}
      />
      <path
        d="M21 10C20.45 10 20 9.55 20 9V7C20 5.35 18.65 4 17 4H14V6H17C17.55 6 18 6.45 18 7V9C18 10.3 18.84 11.42 20 11.83V12.17C18.84 12.58 18 13.69 18 15V17C18 17.55 17.55 18 17 18H14V20H17C18.65 20 20 18.65 20 17V15C20 14.45 20.45 14 21 14H22V10H21Z"
        fill={color}
      />
      <path
        d="M13.3457 7.10568L8.16305 11.745C7.84289 12.035 8.02298 12.5699 8.4532 12.6099L12.5003 12.9999L10.074 16.3794C9.96397 16.5344 9.97897 16.7493 10.114 16.8843C10.2641 17.0343 10.4992 17.0393 10.6543 16.8943L15.8369 12.255C16.1571 11.965 15.977 11.4301 15.5468 11.3901L11.4997 11.0001L13.926 7.62061C14.036 7.46563 14.021 7.25066 13.886 7.11568C13.7359 6.9657 13.5008 6.9607 13.3457 7.10568Z"
        fill={color}
      />
    </svg>
  );
}

export default NRExploitCodeIcon;
