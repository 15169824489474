import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import makeStyles from '@mui/styles/makeStyles';
import CellComponentStyles from '../Cell.styles';
import clsx from 'clsx';
import customTheme from '../../customTheme';
const useStyles = makeStyles(CellComponentStyles);

export const HeaderCell = props => {
  const classes = useStyles();
  return (
    <TableHeaderRow.Cell
      data-testid="grid-header-cell"
      {...props}
      style={{ padding: customTheme.spacing(1.75, 2) }}
      className={clsx(classes.focusedBorder, classes.headerCellStyle)}
    >
      {props.children}
    </TableHeaderRow.Cell>
  );
};
