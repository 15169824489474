import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';
import NRBreadcrumbsStyles from './NRBreadcrumbs.styles';
import makeStyles from '@mui/styles/makeStyles';
import NRSummaryActions from '../NRSummaryActions/NRSummaryActions';
import { useCurrentPath } from '../UseCurrentPath/UseCurrentPath';
import Typography from '@mui/material/Typography';
import React from 'react';
import NRShowOriginalStateSelector from '../NRShowOriginalStateSelector/NRShowOriginalStateSelector';
import NRShowAssetStatus from '../NRShowAssetStatus/NRShowAssetStatus';
import Box from '@mui/material/Box';
const useStyles = makeStyles(NRBreadcrumbsStyles);

function NRBreadcrumbs() {
  const classes = useStyles();
  const breadcrumbs = useBreadcrumbs(routes);
  const currentPath = useCurrentPath();
  const SHOW_ACTIONS_PATH = '/assets/:componentId';

  return (
    <div className={classes.breadcrumbContainer}>
      <Box className={classes.root}>
        <Box>
          {breadcrumbs.length === 1 && (
            <Typography variant="h2" component="span">
              {breadcrumbs[0].breadcrumb}
            </Typography>
          )}
          {breadcrumbs.slice(1).map(({ match, breadcrumb }, index, slicedBreadCrumbs) => {
            const {
              pathname,
              route: { redirectTo }
            } = match;

            return (
              <React.Fragment key={pathname}>
                {index === 0 && (
                  <Typography variant="h2" component="span">
                    {index < slicedBreadCrumbs.length - 1 ? (
                      <NavLink to={redirectTo || pathname} className={classes.link}>
                        {breadcrumb}
                      </NavLink>
                    ) : (
                      <>{breadcrumb}</>
                    )}
                  </Typography>
                )}
                {index > 0 && (
                  <Typography variant="body2" component="span">
                    <span className={classes.separator}>/</span>
                    {index === slicedBreadCrumbs.length - 1 && <>{breadcrumb}</>}
                    {index < slicedBreadCrumbs.length - 1 && (
                      <NavLink to={redirectTo || pathname} className={classes.link}>
                        {breadcrumb}
                      </NavLink>
                    )}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
        </Box>
        <Box>{currentPath === SHOW_ACTIONS_PATH && <NRShowAssetStatus />}</Box>
      </Box>
      {currentPath === SHOW_ACTIONS_PATH && <NRShowOriginalStateSelector />}
      {currentPath === SHOW_ACTIONS_PATH && <NRSummaryActions />}
    </div>
  );
}
export default NRBreadcrumbs;
