import { useEffect, useState } from 'react';
import { isEmpty } from '../utils/utils';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import CustomError from '../components/CustomError/CustomError';

function useNRMutation(qlMutation, getDataFrom, successMessage, errorMessage, refetch) {
  const { enqueueSnackbar } = useSnackbar();
  const [makeRequest, { data, loading, error }] = useMutation(qlMutation, refetch);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setResult(getDataFrom && data ? data[getDataFrom] : data);
    if (data && successMessage) {
      enqueueSnackbar(successMessage, { variant: 'success' });
    }
  }, [data]);

  useEffect(() => {
    if (error && !isEmpty(error)) {
      enqueueSnackbar(<CustomError message={errorMessage || error?.message} />, { variant: 'error' });
      console.log(error);
    }
  }, [error]);

  const makeRequestWithCatch = async params => {
    try {
      await makeRequest(params);
    } catch (error) {
      console.log(error);
    }
  };

  return [makeRequestWithCatch, result, loading, error];
}

export default useNRMutation;
