import { CONSTANTS } from '../constants/constants';
const { LOW, MEDIUM, HIGH, CRITICAL } = CONSTANTS.SEVERITIES;

export default function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${alpha})`;
  }
  throw new Error('Bad Hex');
}

export const getSeverityColor = (text, theme) => {
  switch (text?.toLowerCase()) {
    case LOW:
      return theme.palette.weightedColors[2][500];
    case MEDIUM:
      return theme.palette.weightedColors[3][300];
    case HIGH:
      return theme.palette.weightedColors[4][300];
    case CRITICAL:
      return theme.palette.weightedColors[5][600];
    default:
      return theme.palette.neutral[200];
  }
};
