import { makeStyles } from '@mui/styles';
import NRSuperIndexAndValueStyles from './NRSuperIndexAndValue.styles';
import clsx from 'clsx';
import UpperCaseGreyText from '../UpperCaseGreyText/UpperCaseGreyText';
const useStyles = makeStyles(NRSuperIndexAndValueStyles);

function NRSuperIndexAndValue({ superIndex, value, extraClass }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.mainContainer, extraClass)}>
      <UpperCaseGreyText text={superIndex} />
      <div className={classes.value}>{value}</div>
    </div>
  );
}
export default NRSuperIndexAndValue;
