export default theme => ({
  boxContainer: {
    width: '100%'
  },
  typography: {
    mt: 2,
    mb: 1
  },
  hideBox: {
    display: 'none'
  },
  inDrawerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});
