function RTOSIcon({ height = '24', width = '24' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49328 11.3582V4.82923H9.1422C9.64925 4.82923 10.082 4.91743 10.4404 5.09383C10.8011 5.26811 11.0753 5.51571 11.2633 5.83663C11.4534 6.15543 11.5485 6.53055 11.5485 6.96199C11.5485 7.39556 11.4524 7.76855 11.26 8.08098C11.0677 8.39127 10.789 8.62931 10.424 8.79509C10.404 8.80423 10.3838 8.81312 10.3633 8.82176L11.7878 11.3582H10.2208L8.94901 9.04375H7.91281V11.3582H6.49328ZM8.87665 7.93433H7.91281V5.95778H8.87009C9.14329 5.95778 9.3695 5.9971 9.54872 6.07573C9.73012 6.15225 9.86453 6.26489 9.95195 6.41366C10.0416 6.56243 10.0864 6.74521 10.0864 6.96199C10.0864 7.17665 10.0416 7.35624 9.95195 7.50076C9.86453 7.64529 9.73121 7.75368 9.55199 7.82594C9.37278 7.8982 9.14766 7.93433 8.87665 7.93433Z"
        fill="#28D634"
      />
      <path d="M12.296 5.96734V4.82923H17.8102V5.96734H15.7547V11.3582H14.3515V5.96734H12.296Z" fill="#28D634" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6076 17.6949C11.8699 17.209 12.0011 16.6197 12.0011 15.927C12.0011 15.2343 11.8699 14.645 11.6076 14.159C11.3473 13.6731 10.992 13.303 10.5417 13.0486C10.0934 12.7922 9.58937 12.664 9.02956 12.664C8.46562 12.664 7.95951 12.7922 7.51125 13.0486C7.06299 13.303 6.70872 13.6731 6.44843 14.159C6.18815 14.645 6.05801 15.2343 6.05801 15.927C6.05801 16.6176 6.18815 17.2059 6.44843 17.6918C6.70872 18.1777 7.06299 18.5489 7.51125 18.8053C7.95951 19.0617 8.46562 19.1899 9.02956 19.1899C9.58937 19.1899 10.0934 19.0627 10.5417 18.8084C10.992 18.552 11.3473 18.1808 11.6076 17.6949ZM10.4394 14.7918C10.5737 15.0999 10.6408 15.4783 10.6408 15.927C10.6408 16.3757 10.5737 16.7541 10.4394 17.0622C10.3072 17.3703 10.1203 17.6039 9.87858 17.7632C9.63689 17.9224 9.35388 18.002 9.02956 18.002C8.70524 18.002 8.42224 17.9224 8.18055 17.7632C7.93886 17.6039 7.75088 17.3703 7.6166 17.0622C7.4844 16.7541 7.41829 16.3757 7.41829 15.927C7.41829 15.4783 7.4844 15.0999 7.6166 14.7918C7.75088 14.4837 7.93886 14.25 8.18055 14.0908C8.42224 13.9316 8.70524 13.852 9.02956 13.852C9.35388 13.852 9.63689 13.9316 9.87858 14.0908C10.1203 14.25 10.3072 14.4837 10.4394 14.7918Z"
        fill="#28D634"
      />
      <path
        d="M16.1184 13.9946C16.3063 14.1332 16.4127 14.3275 16.4375 14.5778H17.7234C17.7172 14.1993 17.616 13.8664 17.4198 13.579C17.2235 13.2916 16.9488 13.0672 16.5955 12.906C16.2444 12.7447 15.8333 12.664 15.3623 12.664C14.8996 12.664 14.4864 12.7447 14.1229 12.906C13.7593 13.0672 13.4732 13.2916 13.2646 13.579C13.058 13.8664 12.9557 14.2024 12.9578 14.5871C12.9557 15.0564 13.1096 15.4297 13.4195 15.7068C13.7294 15.9838 14.1518 16.1875 14.6868 16.3178L15.3778 16.4915C15.6092 16.5473 15.8044 16.6093 15.9634 16.6776C16.1246 16.7458 16.2464 16.8285 16.3291 16.9257C16.4138 17.0229 16.4561 17.1428 16.4561 17.2855C16.4561 17.4385 16.4096 17.574 16.3167 17.6918C16.2237 17.8097 16.0925 17.9017 15.9232 17.9679C15.7558 18.034 15.5586 18.0671 15.3313 18.0671C15.1 18.0671 14.8924 18.032 14.7085 17.9617C14.5267 17.8893 14.3811 17.7828 14.2716 17.6422C14.1642 17.4995 14.1032 17.3217 14.0888 17.1087H12.7905C12.8008 17.5657 12.9113 17.9493 13.122 18.2594C13.3348 18.5675 13.6302 18.8001 14.0082 18.9573C14.3883 19.1144 14.8345 19.193 15.3468 19.193C15.8632 19.193 16.3043 19.1155 16.6699 18.9604C17.0376 18.8032 17.3186 18.583 17.5127 18.2997C17.709 18.0144 17.8081 17.6773 17.8102 17.2886C17.8081 17.0239 17.7596 16.7892 17.6646 16.5845C17.5716 16.3798 17.4404 16.202 17.271 16.051C17.1017 15.9001 16.9013 15.7729 16.6699 15.6695C16.4386 15.5661 16.1845 15.4834 15.9077 15.4214L15.3375 15.2849C15.1991 15.2539 15.069 15.2167 14.9471 15.1733C14.8252 15.1278 14.7178 15.075 14.6248 15.0151C14.5319 14.953 14.4586 14.8796 14.4048 14.7949C14.3532 14.7101 14.3294 14.6108 14.3336 14.4971C14.3336 14.3606 14.3728 14.2386 14.4513 14.1311C14.5319 14.0236 14.6476 13.9398 14.7984 13.8799C14.9492 13.8178 15.134 13.7868 15.353 13.7868C15.6753 13.7868 15.9304 13.8561 16.1184 13.9946Z"
        fill="#28D634"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.40883C2 2.99131 3.27245 2 4.63461 2H19.3654C20.7276 2 22 2.99131 22 4.40883V19.593C22 21.0116 20.7264 22 19.3654 22H4.63461C3.27361 22 2 21.0116 2 19.593V4.40883ZM4.63461 3.50118C3.91574 3.50118 3.50118 3.99471 3.50118 4.40883V19.593C3.50118 20.0061 3.91457 20.4988 4.63461 20.4988H19.3654C20.0854 20.4988 20.4988 20.0061 20.4988 19.593V4.40883C20.4988 3.99471 20.0843 3.50118 19.3654 3.50118H4.63461Z"
        fill="#28D634"
      />
    </svg>
  );
}

export default RTOSIcon;
