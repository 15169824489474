import { createContext, useEffect, useState } from 'react';
import { getDataFromComposedAssetId } from '../utils/utils';

export const ComponentContext = createContext({
  component: null,
  setComponent: () => {},
  asset: null,
  setAsset: () => {},
  latestAsset: null,
  setLatestAsset: () => {},
  isLatestAsset: null
});

function NRComponentContextWrapper({ children }) {
  const [component, setComponent] = useState(null);
  const [asset, setAsset] = useState(null);
  const [latestAsset, setLatestAsset] = useState(null);
  const [isLatestAsset, setIsLatestAsset] = useState(null);

  useEffect(() => {
    if (!!asset?.componentId && !!latestAsset?.snapshotId) {
      const { snapshotId } = getDataFromComposedAssetId(asset.componentId);
      setIsLatestAsset(latestAsset?.snapshotId === snapshotId);
    }
  }, [latestAsset]);

  const defaultValues = {
    component,
    setComponent,
    asset,
    setAsset,
    latestAsset,
    setLatestAsset,
    isLatestAsset
  };

  return <ComponentContext.Provider value={defaultValues}>{children}</ComponentContext.Provider>;
}

export default NRComponentContextWrapper;
