import React from 'react';
import { Tooltip } from '@mui/material';
import { ArrowDownward, ArrowUpward, InfoOutlined } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import SortLabelStyles from './SortLabel.styles';
import clsx from 'clsx';
const useStyles = makeStyles(SortLabelStyles);

const SortingIcon = ({ direction }) =>
  direction === 'asc' ? <ArrowUpward style={{ fontSize: '18px' }} /> : <ArrowDownward style={{ fontSize: '18px' }} />;

const SortLabel = ({
  onSort,
  children: {
    props: {
      children: { value, tooltip }
    }
  },
  column: { sortDisabled },
  direction
}) => {
  const classes = useStyles();
  return (
    <>
      {sortDisabled && (
        <span className={classes.headerTitle}>
          {tooltip && (
            <Tooltip title={tooltip || 'Here goes the Tooltip text'}>
              <InfoOutlined className={classes.infoIcon} />
            </Tooltip>
          )}
          <b>{value}</b>
        </span>
      )}
      {!sortDisabled && (
        <span onClick={onSort} className={clsx(classes.headerTitle, classes.sortEnabled)}>
          {tooltip && (
            <Tooltip title={tooltip || 'Here goes the Tooltip text'}>
              <InfoOutlined className={classes.infoIcon} />
            </Tooltip>
          )}
          <b>{value}</b>
          {direction && <SortingIcon direction={direction} />}
        </span>
      )}
    </>
  );
};

export default SortLabel;
