import { useContext, useEffect, useState } from 'react';
import CustomError from '../CustomError/CustomError';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import moment from 'moment/moment';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import makeStyles from '@mui/styles/makeStyles';
import NRTaskProgressStyles from './NRTaskProgress.styles';
import { AssetsContext } from '../../DataShare/NRAssetsDataShare';
import { CONSTANTS } from '../../constants/constants';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
const { CREATED, ERROR, IN_PROGRESS, SUCCESS, ABORTED, PROCESSING } = CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS;
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import NRActionModal from '../NRActionModal/NRActionModal';
import { GET_ASSET_UPLOAD } from '../../graphql/queries/asset';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
const { TITLE_S, CANCEL_S, CONTINUE_S, MESSAGE_S } = CONSTANTS.TRANSACTION_ASSET.CONFIRMATION_MODAL;

const useStyles = makeStyles(NRTaskProgressStyles);

function StatusIcon({ status }) {
  const classes = useStyles();

  switch (status) {
    case CREATED:
      return <FileUploadOutlinedIcon />;
    case IN_PROGRESS:
      return <FileUploadOutlinedIcon />;
    case ERROR:
      return <CancelOutlinedIcon className={classes.errorIconStyles} />;
    case ABORTED:
      return <CancelOutlinedIcon className={classes.errorIconStyles} />;
    case SUCCESS:
      return <CheckCircleOutlineOutlinedIcon className={classes.successIconStyles} />;
    case PROCESSING:
      return <AccessTimeOutlinedIcon />;
    default:
      return <FileDownloadOutlinedIcon />;
  }
}

function NRTaskProgress({ task, tasks }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const startedTime = new Date();
  const [localUpload, setLocalUpload] = useState(task);
  const { setTasks, updateStatus } = useContext(AssetsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (task.status !== PROCESSING) {
      if (!localUpload?.localStatus) {
        uploadFile(localUpload);
      } else if (localUpload?.localStatus === 200) {
        updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.PROCESSING);
      } else if (localUpload?.localStatus === 500) {
        updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.ERROR);
        enqueueSnackbar(<CustomError message={`${localUpload.fileToSend?.name} ${CONSTANTS.TRANSACTION_ASSET.UPLOAD_ERROR_MESSAGE}`} />, {
          variant: 'error'
        });
      }
    }
  }, [localUpload]);

  const SuccessHandler = () => {
    const status = localUpload?.xhr?.status;
    if (status === 200) {
      setLocalUpload({ ...localUpload, localStatus: 200 });
    }
  };

  const handleError = () => {
    setLocalUpload({ ...localUpload, localStatus: 500 });
  };

  const ProgressHandler = e => {
    let percent = (e.loaded / e.total) * 100;
    setProgress(percent);
    const timeElapsed = new Date() - startedTime;
    const uploadSpeed = e.loaded / (timeElapsed / 1000);
    const timeRemaining = (e.total - e.loaded) / (uploadSpeed || 1);
    setRemainingTime(timeRemaining);
  };

  const updateStatusLocal = status => {
    updateStatus(status, localUpload.uploadUrl);
  };

  const addRequestRef = xhr => {
    const newTasks = [...tasks];
    const currentTaskIndex = tasks.findIndex(e => e.uploadUrl === localUpload.uploadUrl);
    newTasks[currentTaskIndex].xhr = xhr;
    setTasks(newTasks);
  };

  const uploadFile = ({ uploadUrl, fileToSend, status }) => {
    if (!!uploadUrl && !!fileToSend && status === CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.CREATED) {
      const xhr = new XMLHttpRequest();
      addRequestRef(xhr);
      xhr.open('PUT', uploadUrl, true);
      xhr.upload.addEventListener('progress', ProgressHandler, false);
      xhr.addEventListener('load', SuccessHandler, false);
      xhr.addEventListener('error', handleError, false);
      updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.IN_PROGRESS);
      xhr.send(fileToSend);
    }
  };

  const abort = () => {
    if (!!localUpload.xhr) {
      localUpload.xhr.abort();
    }
  };

  const goToAssets = () => {
    navigate('/assets');
  };

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const continueUpload = () => {
    setOpenModal(false);
  };

  const cancelUpload = () => {
    abort();
    updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.ABORTED);
    setOpenModal(false);
  };

  return (
    <>
      <NRActionModal
        title={TITLE_S}
        actionLabel={CANCEL_S}
        cancelLabel={CONTINUE_S}
        content={MESSAGE_S}
        open={openModal}
        handleClose={handleClose}
        handleCancelAction={continueUpload}
        handleConfirmAction={cancelUpload}
        actionButtonProps={{ variant: 'contained', color: 'secondary' }}
        cancelButtonProps={{ variant: 'outlined', color: 'secondary' }}
        width={410}
      />
      <div className={classes.masterDiv}>
        <div className={classes.container}>
          <div className={classes.uploadDataContainer}>
            <div className={classes.statusIcon}>
              <StatusIcon status={task?.status} />
            </div>
            <div
              className={clsx({
                [classes.assetDataContainer]: task?.status === IN_PROGRESS,
                [classes.assetDataContainerNoAction]: task?.status !== IN_PROGRESS
              })}
            >
              <div className={classes.nameContainer}>
                <div className={classes.name}>{task?.formValues?.name}</div>
              </div>
              {task?.status === IN_PROGRESS && (
                <p className={classes.remainingTime}>Uploading {moment.duration(remainingTime / 60, 'minutes').humanize()} left...</p>
              )}
              {task?.status === SUCCESS && (
                <p className={classes.remainingTime}>
                  Uploaded to{' '}
                  <span className={classes.assetLink} onClick={goToAssets}>
                    Assets
                  </span>
                </p>
              )}
              {task?.status === ERROR && <p className={classes.remainingTime}>Failed</p>}
              {task?.status === ABORTED && <p className={classes.remainingTime}>Canceled</p>}
              {task?.status === PROCESSING && <p className={classes.remainingTime}>Processing</p>}
            </div>
            {task?.status === IN_PROGRESS && (
              <div className={classes.uploadActionContainer}>
                <div className={classes.buttonContainer}>
                  <IconButton aria-label="Close" onClick={() => setOpenModal(true)} size="small">
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
        {task?.status === IN_PROGRESS && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{ colorPrimary: classes.colorPrimaryProgressBar, barColorPrimary: classes.barColorPrimaryProgressBar }}
            />
          </Box>
        )}
        {task?.status === PROCESSING && <NRProcessingTask updateStatusLocal={updateStatusLocal} localUpload={localUpload} />}
      </div>
    </>
  );
}

export default NRTaskProgress;

function NRProcessingTask({ updateStatusLocal, localUpload }) {
  const classes = useStyles();
  const [timeController, setTimeController] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setRecentlyUploadedAsset } = useContext(AssetsContext);

  const [assetUpload, isLoading, called, getAssetUpload] = useNRLazyQuery({
    query: GET_ASSET_UPLOAD,
    options: { fetchPolicy: 'no-cache', context: { version: 'v3' } },
    qlObjKey: 'assetUpload'
  });

  useEffect(() => {
    if (!!assetUpload) {
      if (!assetUpload?.uploaded) {
        if (!timeController) {
          const interval = setInterval(() => {
            getAssetUpload({ variables: { args: { uploadId: localUpload?.uploadId } } });
          }, 3000);
          setTimeController(interval);
        }
      } else {
        if (timeController) {
          clearInterval(timeController);
        }
        updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.SUCCESS);
        enqueueSnackbar(`${localUpload?.fileToSend?.name} ${CONSTANTS.TRANSACTION_ASSET.UPLOAD_SUCCESS_MESSAGE}`, { variant: 'success' });
        setRecentlyUploadedAsset(assetUpload?.assetId);
      }
    }
  }, [assetUpload]);

  useEffect(() => {
    getAssetUpload({ variables: { args: { uploadId: localUpload?.uploadId } } });
    return () => {
      clearInterval(timeController);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        variant="indeterminate"
        classes={{ colorPrimary: classes.colorPrimaryProgressBar, barColorPrimary: classes.barColorPrimaryProgressBar }}
      />
    </Box>
  );
}
