import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Box from '@mui/material/Box';
import { useContext } from 'react';
import { RemediationContext } from '../../../NRRemediation/NRRemediationContext';
import NRRemediationStatusButton from '../../../NRRemediationStatusButton/NRRemediationStatusButton';
import { RemediationDetailsFormatter } from '../RemediationDetailsProvider/RemediationDetailsProvider';

export function RemediationStatusFormatter({ value, column, row, small }) {
  const { setOpenRemediationModal, setCVEs } = useContext(RemediationContext);

  function handleOnClick() {
    setCVEs([row]);
    setOpenRemediationModal(true);
  }
  return (
    <Box display={'flex'}>
      <NRRemediationStatusButton small={small} status={value} handleOnClick={handleOnClick} isInteractive={false} />
      <RemediationDetailsFormatter row={row} />
    </Box>
  );
}

export const RemediationStatusProvider = props => <DataTypeProvider formatterComponent={RemediationStatusFormatter} {...props} />;
