import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.blue.link,
    cursor: 'pointer'
  }
}));

function NRLink({ tooltipTitle, text, url }) {
  const classes = useStyles();

  return (
    <Tooltip title={!!tooltipTitle ? tooltipTitle : ''}>
      <Link
        onClick={event => {
          event.preventDefault();
          window.open(url, '_blank', 'noopener');
        }}
        className={classes.link}
      >
        {!!text ? text : url}
        <CallMadeOutlinedIcon style={{ maxWidth: 16, maxHeight: 16, marginLeft: 4, marginBottom: -3 }} />
      </Link>
    </Tooltip>
  );
}
export default NRLink;
