import makeStyles from '@mui/styles/makeStyles';
import NRRemediationStepsStyles from './NRRemediationSteps.styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NRCircleWarningIcon from '../../NRIcons/NRCircleWarningIcon';
import NRSwitch from '../NRControlledSwitch/NRSwitch';
import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles(NRRemediationStepsStyles);

function NRRemediationStepThree() {
  const classes = useStyles();
  const { control } = useFormContext();
  return (
    <Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant={'body2'} className={classes.textSecondary}>
          You can export these vulnerabilities and the components they are related to in SBOM VEX format from here, or Save and export from the SBOM
          tab at anytime.
        </Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <Typography variant={'body2'} className={clsx(classes.optionalExport, classes.textSecondary)}>
          Optional Export
        </Typography>
        <Box className={classes.selectedVex}>
          <Box className={classes.innerSelectedVex}>
            <Typography variant={'body2'} sx={{ mr: 1 }}>
              Open SBOM export options after saving
            </Typography>
          </Box>
          <FormControl>
            <Controller
              control={control}
              id={'remediation-downloadSBOM'}
              name={'downloadSBOM'}
              required={false}
              render={({ onChange, value }) => <NRSwitch onChange={v => onChange(v?.target?.checked)} value={value} />}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}

export default NRRemediationStepThree;
