export default theme => ({
  cardPosition: {
    position: 'absolute',
    top: '50%',
    width: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  action: {
    margin: 'unset'
  },
  card: {
    padding: 0
  },
  cardHeader: {
    padding: theme.spacing(2, 3)
  },
  cardSection: {
    padding: theme.spacing(3)
  },
  dateInformationContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateInformation: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1)
  },
  cardActions: {
    padding: theme.spacing(1.75, 3),
    justifyContent: 'flex-end'
  },
  spinnerContainer: {
    display: 'flex'
  }
});
