function VxIcon({ height = '24', width = '24' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5951 19.9885C14.5951 19.9823 15.0216 19.2665 15.543 18.3981C16.0644 17.5296 16.5016 16.8008 16.5146 16.7785C16.5325 16.7479 18.4125 19.8094 18.4734 19.9684C18.4809 19.9878 17.7352 20 16.5403 20C15.4704 20 14.5951 19.9949 14.5951 19.9886V19.9885ZM11.7878 19.7252C11.7581 19.6819 9.59846 16.2156 6.98859 12.0224C4.37871 7.82899 2.18858 4.31097 2.12166 4.2043L2 4.01057L8.76268 4.02775L11.9898 9.21018C13.7646 12.0605 15.2102 14.416 15.2021 14.4446C15.1905 14.4855 11.894 19.7489 11.8527 19.7926C11.8468 19.7988 11.8177 19.7685 11.788 19.7253L11.7878 19.7252ZM14.8492 10.2062C13.9607 8.76344 13.2374 7.57073 13.242 7.5558C13.2466 7.54084 13.7367 6.74514 14.3311 5.78754L15.4118 4.04648L16.8276 4.04553C17.6063 4.04501 19.0887 4.03456 20.1217 4.02229L22 4L19.2508 8.41513C17.7387 10.8435 16.4932 12.8301 16.4831 12.8299C16.4729 12.8297 15.7376 11.649 14.8491 10.2062L14.8492 10.2062Z"
        fill="#D12628"
      />
    </svg>
  );
}

export default VxIcon;
