import { alpha } from '@mui/material';

export default theme => ({
  severityAndMetricsContainer: {
    padding: theme.spacing(1.5, 0, 1.5)
  },
  severityAndMetricsFlexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  upperCase: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  title: {
    ...theme.typography.subtitle1,
    margin: theme.spacing(0, 0, 1, 0)
  },
  toggleButton: {
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  },
  buttonGroup: {
    marginBottom: theme.spacing(2)
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  baseScore: {
    textTransform: 'none',
    paddingLeft: theme.spacing(0.25)
  },
  vectorTooltipTitle: {
    marginBottom: theme.spacing(1)
  },
  vectorTooltipMainContainer: {
    minWidth: 250
  },
  vectorTooltipLineContainer: {
    display: 'flex'
  },
  vectorTooltipLineTitleContainer: {
    width: '60%'
  },
  vectorTooltipLineValueContainer: {
    width: '40%',
    textTransform: 'capitalize'
  },
  infoIcon: {
    margin: theme.spacing(0, 0, 0, 0.5),
    fontSize: theme.spacing(2.5),
    color: theme.palette.text.iconInfo
  },
  subSectionContainer: {
    marginTop: theme.spacing(3)
  },
  subSectionTitleContainer: {
    display: 'flex'
  }
});
