import { drawerWidth } from '../NRMenu.styles';

export default theme => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: drawerWidth + 8
  },
  button: {
    textTransform: 'unset',
    ...theme.typography.subtitle2,
    padding: theme.spacing(1)
  },
  buttonDisabled: {
    // !important needs to be added since the other style has more 'weight' because involves stacked classes.
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: 'unset !important'
  },
  endIcon: {
    color: theme.palette.text.icon,
    '&>*:nth-of-type(1)': {
      fontSize: theme.spacing(3)
    }
  },
  titleStyle: {
    ...theme.typography.body1
  },
  subHeaderStyle: {
    ...theme.typography.body2,
    textTransform: 'capitalize'
  },
  menuItemRoot: {
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2)
  },
  selectedIcon: {
    marginLeft: theme.spacing(1)
  },
  optionsContainer: {
    maxHeight: 455,
    overflowY: 'auto'
  },
  menuHeader: {
    position: 'sticky',
    marginTop: -8,
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    ...theme.palette.elevation
  },
  menuDivider: { position: 'sticky', top: 75.88, marginBottom: theme.spacing(0.5) },
  menuPaper: {
    minWidth: 290
  }
});
