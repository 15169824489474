import Tooltip from '@mui/material/Tooltip';

function NRValueTooltipIcon({ value, tooltip, icon, tooltipComponent, style, placement = 'top' }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...style }}>
        <span>{value}</span>
        {(!!tooltip || !!tooltipComponent) && (
          <Tooltip title={tooltipComponent || tooltip} placement={placement}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{icon}</div>
          </Tooltip>
        )}
        {!tooltip && !!icon && <div style={{ display: 'flex', alignItems: 'center' }}>{icon}</div>}
      </div>
    </>
  );
}

export default NRValueTooltipIcon;
