export default theme => ({
  noData: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 470,
    display: 'flex'
  },
  noDep: {
    paddingLeft: theme.spacing(0.5),
    display: 'flex'
  },
  drawerTitle: {
    padding: theme.spacing(0, 0.5)
  }
});
