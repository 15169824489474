export const initPendo = idToken => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
  const rolesNamespace = auth0Namespace + 'roles';
  window.pendo.initialize({
    visitor: {
      id: `${env}-${idToken.sub}`, // Required if user is logged in
      email: idToken.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: idToken.name, // Recommended if using Pendo Feedback
      role: !!idToken[rolesNamespace]?.length && idToken[rolesNamespace][0] // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: `${env}-${idToken[`${auth0Namespace}org`]}` // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    }
  });
};
