function NRBotnetIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 7C21 5.9 20.1 5 19 5V3H17V5H7V3H5V5C3.9 5 3 5.9 3 7V17C3 18.1 3.9 19 5 19H8V21H10V19H14V21H16V19H19C20.1 19 21 18.1 21 17V7ZM19 17H5V7H19V17Z"
        fill={color}
      />
      <path
        d="M8.72361 14.5528C8.893 14.214 9.23926 14 9.61803 14H14.382C14.7607 14 15.107 14.214 15.2764 14.5528C15.6088 15.2177 15.1253 16 14.382 16H9.61803C8.87465 16 8.39116 15.2177 8.72361 14.5528Z"
        fill={color}
      />
      <path
        d="M7 10.1612C7 9.54982 7.54374 9.08156 8.14834 9.17225L9.68449 9.40267C10.0784 9.46177 10.3997 9.74904 10.5024 10.1339L10.6646 10.7423C10.8339 11.3772 10.3554 12 9.69839 12H8C7.44772 12 7 11.5523 7 11V10.1612Z"
        fill={color}
      />
      <path
        d="M17 10.1612C17 9.54982 16.4563 9.08156 15.8517 9.17225L14.3155 9.40267C13.9216 9.46177 13.6003 9.74904 13.4976 10.1339L13.3354 10.7423C13.1661 11.3772 13.6446 12 14.3016 12H16C16.5523 12 17 11.5523 17 11V10.1612Z"
        fill={color}
      />
    </svg>
  );
}

export default NRBotnetIcon;
