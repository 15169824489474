import { alpha } from '@mui/material/styles';

export default theme => ({
  mainPaper: {
    overflow: 'auto'
  },
  mainPaperFullHeight: {
    height: '100%',
    '& .Layout-root': {
      height: 'calc(100% - 65px)'
    }
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: theme.spacing(2, 2),
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8)
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.spacing(2.5)
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2)
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  editingRow: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)'
  },
  editingCell: {
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main} !important`
    }
  },
  noData: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  columnSettingsIconAlone: {
    position: 'absolute',
    top: -48,
    right: 70,
    marginRight: theme.spacing(1),
    color: theme.palette.text.icon
  },
  filterHidingIconAlone: {
    position: 'absolute',
    top: -48,
    right: 30
  },
  columnSettingsIconSelection: {
    position: 'absolute',
    top: -88
  },
  grid: {
    color: 'blue',
    backgroundColor: 'pink',
    '.Pager-page': {
      display: 'none'
    }
  },
  visibilityFilters: {
    padding: theme.spacing(0.5),
    border: 'unset',
    marginLeft: theme.spacing(2),
    color: theme.palette.text.icon
  },
  hasFilters: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.secondary.contrastText} !important`
  }
});
