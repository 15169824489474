function NRRefreshIcon({ height = '20', width = '20', color = '#3699FF' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7085 5.29134C13.5001 4.08301 11.8418 3.33301 10.0001 3.33301C6.3168 3.33301 3.3418 6.31634 3.3418 9.99967C3.3418 13.683 6.3168 16.6663 10.0001 16.6663C13.1085 16.6663 15.7001 14.5413 16.4418 11.6663H14.7085C14.0251 13.608 12.1751 14.9997 10.0001 14.9997C7.2418 14.9997 5.00013 12.758 5.00013 9.99967C5.00013 7.24134 7.2418 4.99967 10.0001 4.99967C11.3835 4.99967 12.6168 5.57467 13.5168 6.48301L10.8335 9.16634H16.6668V3.33301L14.7085 5.29134Z"
        fill={color}
      />
    </svg>
  );
}

export default NRRefreshIcon;
