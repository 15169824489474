import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { ActionsFormatter } from '../NRCustomProviders/ActionsProvider/ActionsProvider';

const EditColumnCustomCellComponent = ({ children, ...restProps }) => {
  return (
    <TableEditColumn.Cell {...restProps} style={{ padding: ' 0 16px' }}>
      {/* Shows the extra-actions when is not in edit mode or when the table is not editable */}
      {(!!children[0] || children.every(item => !item)) && <ActionsFormatter gridActions={children} row={restProps?.row} />}
      {!children[0] && children}
    </TableEditColumn.Cell>
  );
};
export default EditColumnCustomCellComponent;
