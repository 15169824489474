function WindowsIcon({ height = '24', width = '24' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 2H2V11.5H11.5V2Z" fill="#0077D5" />
      <path d="M11.5 12.5H2V22H11.5V12.5Z" fill="#0077D5" />
      <path d="M12.5 2H22V11.5H12.5V2Z" fill="#0077D5" />
      <path d="M22 12.5H12.5V22H22V12.5Z" fill="#0077D5" />
    </svg>
  );
}

export default WindowsIcon;
