import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirectUrl = process.env.REACT_APP_AUTH0_REDIRECT_URL;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={false}
      audience={audience}
      cacheLocation={'localstorage'}
      redirectUri={redirectUrl + '/'}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
