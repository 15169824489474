import useNRQuery from '../../../graphql/useNRQuery';
import NRVulnerabilityDescription from './NRVulnerabilityDescription/NRVulnerabilityDescription';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { createContext, useEffect, useState } from 'react';
import { VULNERABILITY } from '../../../graphql/queries/vulnerability';
import NRReferences from './NRReferences/NRReferences';
import NRKnownExploits from './NRKnownExploits/NRKnownExploits';
import NRVulnerabilityDetailsStyles from './NRVulnerabilityDetails.styles';
import NRSeverityAndMetrics from './NRSeverityAndMetrics/NRSeverityAndMetrics';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { CONSTANTS } from '../../../constants/constants';
import Alert from '@mui/material/Alert';
import NRCISAKevAlertDetails from './NRCISAKevDetails/NRCISAKevDetails';

const { TABS, ALERT } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER;

const useStyles = makeStyles(NRVulnerabilityDetailsStyles);

export const VulnDrawerTabsContext = createContext({
  currentTab: null,
  setCurrentTab: () => {},
  tabOrder: null,
  scrollSpyTabsValue: null,
  setScrollSpyTabsValue: () => {}
});

function NRVulnerabilityDetails({ row }) {
  const { cve } = row;
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('DESCRIPTION');
  const [tabOrder] = useState(['DESCRIPTION', 'REFERENCES', 'CVSS_METRICS', 'KNOWN_EXPLOITS']);
  const [scrollSpyTabsValue, setScrollSpyTabsValue] = useState([true, false, false, false]);
  const [knownExploitsCount, setKnownExploitsCount] = useState(null);
  const [hasKnownExploitedVulnerabilities, setHasKnownExploitedVulnerabilities] = useState(false);
  const [scrollableElement, setScrollableElement] = useState(null);
  const [scrollThreshold, setScrollThreshold] = useState([0, 0, 0, 0]);

  const [cveDetails, cveDetailsLoading] = useNRQuery({
    query: VULNERABILITY,
    options: { variables: { args: { id: cve } }, context: { version: 'v3' } },
    qlObjKey: 'vulnerability',
    transformData: data => {
      return {
        ...data,
        exploit: {
          ...data.exploit,
          references: {
            ...data.exploit.references,
            knownExploitedVulnerabilities: data?.exploit?.references?.knownExploitedVulnerabilities
              ? Array.isArray(data?.exploit?.references?.knownExploitedVulnerabilities)
                ? data?.exploit?.references?.knownExploitedVulnerabilities
                : [data?.exploit?.references?.knownExploitedVulnerabilities]
              : null
          }
        }
      };
    }
  });

  const vulnerabilityDescriptionScrollIntoView = () => (scrollableElement.scrollTop = getScrollIndex(0) + 1); // one pixel added in order to trigger
  const referencesScrollIntoView = () => (scrollableElement.scrollTop = getScrollIndex(1) + 1);
  const severityAndMetricsScrollIntoView = () => (scrollableElement.scrollTop = getScrollIndex(2) + 1);
  const knownExploitsScrollIntoView = () => (scrollableElement.scrollTop = getScrollIndex(3) + 1);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 'DESCRIPTION':
        vulnerabilityDescriptionScrollIntoView();
        break;
      case 'REFERENCES':
        referencesScrollIntoView();
        break;
      case 'CVSS_METRICS':
        severityAndMetricsScrollIntoView();
        break;
      case 'KNOWN_EXPLOITS':
        knownExploitsScrollIntoView();
        break;
      default:
        break;
    }
    setCurrentTab(newValue);
  };

  useEffect(() => {
    !!cveDetails &&
      setKnownExploitsCount(
        (cveDetails.exploit?.references?.exploits?.length || 0) +
          (cveDetails.exploit?.references?.threatActors?.length || 0) +
          (cveDetails.exploit?.references?.botnets?.length || 0) +
          (cveDetails.exploit?.references?.knownAttacks?.length || 0)
      );
    !!cveDetails && setHasKnownExploitedVulnerabilities(cveDetails.exploit?.inKnownExploitedVulnerabilities);
  }, [cveDetails]);

  useEffect(() => {
    let scrollableElem = document.querySelector('#vuln-details-main-container');
    !!scrollableElem && setScrollableElement(scrollableElem);
  }, []);

  useEffect(() => {
    setScrollThreshold([getScrollIndex(0), getScrollIndex(1), getScrollIndex(2), getScrollIndex(3)]);
  }, [scrollableElement?.scrollHeight]); // work around to make it aware of main container height changes.

  const handleSetScrollSpyTabsValue = (index, value) => {
    setScrollSpyTabsValue(prev => {
      let a = [...prev];
      a[index] = value;
      return a;
    });
  };

  useEffect(() => {
    let scrolledIndex = scrollSpyTabsValue.findLastIndex(elem => elem === true);
    setCurrentTab(tabOrder[scrolledIndex !== -1 ? scrolledIndex : 0]);
  }, [scrollSpyTabsValue]);

  const VulnDrawerTabsDefaultValues = {
    currentTab: currentTab,
    setCurrentTab: setCurrentTab,
    tabOrder: tabOrder,
    scrollSpyTabsValue,
    setScrollSpyTabsValue: handleSetScrollSpyTabsValue
  };

  const knownExploitsAlertComponentHeight = 72;
  const cisaKevDetailsComponentHeight = 600;

  function getScrollIndex(index) {
    let knownExploitsAlertHeight = !!knownExploitsCount
      ? knownExploitsAlertComponentHeight
      : 0 + hasKnownExploitedVulnerabilities
      ? cisaKevDetailsComponentHeight
      : 0;

    let scrollableLength = scrollableElement?.scrollHeight - scrollableElement?.clientHeight;

    let NRVulnerabilityDescriptionContainer = document.querySelector('#NRVulnerabilityDescription');
    let NRReferencesContainer = document.querySelector('#NRReferences');
    let NRKnownExploitsContainer = document.querySelector('#NRKnownExploits');

    switch (index) {
      case 0:
        return 0;
      case 1:
        let prevComponentHeight = knownExploitsAlertHeight + NRVulnerabilityDescriptionContainer?.clientHeight - scrollableElement?.clientHeight / 3; // relate the prev components height to one third of the scrollable component height
        return prevComponentHeight < scrollableLength / 3 ? prevComponentHeight : scrollableLength / 3;
      case 2:
        let prevComponentsHeight =
          knownExploitsAlertHeight +
          NRVulnerabilityDescriptionContainer?.clientHeight +
          NRReferencesContainer?.clientHeight -
          scrollableElement?.clientHeight / 2; // relate the prev components height to the middle of the scrollable component height
        return prevComponentsHeight < scrollableLength / 2 ? prevComponentsHeight : scrollableLength / 2;
      case 3:
        let lastComponentScroll = knownExploitsAlertHeight + scrollableLength - NRKnownExploitsContainer?.clientHeight / 3;
        return lastComponentScroll > (scrollableLength / 4) * 3 ? lastComponentScroll : (scrollableLength / 4) * 3;
      default:
        break;
    }
  }

  return (
    <div>
      <Tabs value={currentTab} onChange={handleChange} textColor="inherit" variant="fullWidth" aria-label="drawer tabs">
        <Tab label={TABS.DESCRIPTION} key={TABS.DESCRIPTION} value={'DESCRIPTION'} classes={{ root: classes.tab }} />
        <Tab label={TABS.REFERENCES} key={TABS.REFERENCES} value={'REFERENCES'} classes={{ root: classes.tab }} />
        <Tab label={TABS.CVSS_METRICS} key={TABS.CVSS_METRICS} value={'CVSS_METRICS'} classes={{ root: classes.tab }} />
        <Tab label={TABS.KNOWN_EXPLOITS} key={TABS.KNOWN_EXPLOITS} value={'KNOWN_EXPLOITS'} classes={{ root: classes.tab }} />
      </Tabs>
      <div id={'vuln-details-main-container'} className={classes.mainContainer}>
        <VulnDrawerTabsContext.Provider value={VulnDrawerTabsDefaultValues}>
          {!cveDetailsLoading && !!cveDetails && (
            <>
              {!!knownExploitsCount && (
                <Alert severity="error" classes={{ root: classes.alert }}>
                  <span>
                    {ALERT.MESSAGE}
                    <span className={classes.alertKnownExploits} onClick={() => knownExploitsScrollIntoView()}>
                      {TABS.KNOWN_EXPLOITS}
                    </span>
                    {` (${knownExploitsCount})`}
                  </span>
                </Alert>
              )}
              {!!hasKnownExploitedVulnerabilities && (
                <NRCISAKevAlertDetails data={cveDetails.exploit?.references?.knownExploitedVulnerabilities[0]} />
              )}
              <NRVulnerabilityDescription
                vulnerabilityData={row}
                cveDetails={cveDetails}
                isLoading={cveDetailsLoading}
                threshold={scrollThreshold[0]}
                scrollTarget={scrollableElement}
                index={0}
                scrollableId={'NRVulnerabilityDescription'}
              />
              <NRReferences
                cveDetails={cveDetails}
                isLoading={cveDetailsLoading}
                threshold={scrollThreshold[1]}
                scrollTarget={scrollableElement}
                index={1}
                scrollableId={'NRReferences'}
              />
              <NRSeverityAndMetrics
                cveDetails={cveDetails}
                isLoading={cveDetailsLoading}
                threshold={scrollThreshold[2]}
                scrollTarget={scrollableElement}
                index={2}
                scrollableId={'NRSeverityAndMetrics'}
              />
              <NRKnownExploits
                cveDetails={cveDetails}
                isLoading={cveDetailsLoading}
                threshold={scrollThreshold[3]}
                scrollTarget={scrollableElement}
                index={3}
                scrollableId={'NRKnownExploits'}
              />
            </>
          )}
        </VulnDrawerTabsContext.Provider>
      </div>
      {cveDetailsLoading && !cveDetails && (
        <div className={classes.circularProgress}>
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
}
export default NRVulnerabilityDetails;
