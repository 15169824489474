export default theme => ({
  text: {
    ...theme.typography.label,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    cursor: 'default',
    whiteSpace: 'nowrap',
    overflow: 'clip',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.5)
  }
});
