function NRColumnSettingsIcons({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 4L6 20L8 20L8 4L6 4ZM11 4L11 20L13 20L13 4L11 4ZM18 11L16 11L16 20L18 20L18 11Z" fill={color} />
      <path
        d="M20.9091 5.69143L22 4.74286L21.1202 3.25714L19.7243 3.70286C19.5601 3.57714 19.3724 3.47429 19.173 3.39429L18.8798 2H17.1202L16.827 3.39429C16.6276 3.47429 16.4399 3.57714 16.2639 3.70286L14.8798 3.25714L14 4.74286L15.0909 5.69143C15.0674 5.88571 15.0674 6.09143 15.0909 6.28571L14 7.25714L14.8798 8.74286L16.2874 8.30857C16.4399 8.42286 16.6158 8.51429 16.7918 8.59429L17.1202 10H18.8798L19.1965 8.60571C19.3842 8.52571 19.5484 8.43429 19.7126 8.32L21.1085 8.75429L21.9883 7.26857L20.8974 6.29714C20.9326 6.08 20.9208 5.88571 20.9091 5.69143ZM18 7.42857C17.1906 7.42857 16.5337 6.78857 16.5337 6C16.5337 5.21143 17.1906 4.57143 18 4.57143C18.8094 4.57143 19.4663 5.21143 19.4663 6C19.4663 6.78857 18.8094 7.42857 18 7.42857Z"
        fill={color}
      />
    </svg>
  );
}

export default NRColumnSettingsIcons;
