import { gql } from '@apollo/client';

export const PLACEHOLDER_MUTATION = gql`
  mutation {
    whatever
  }
`;

export const PLACEHOLDER_QUERY = gql`
  query {
    whatever
  }
`;
