export default theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  indexContainer: {
    maxWidth: '10%',
    paddingRight: theme.spacing(1)
  },
  keyValueContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0)
  },
  linkDate: {
    marginLeft: theme.spacing(1)
  }
});
