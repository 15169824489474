import { makeStyles } from '@mui/styles';
import NRKeyValueDataDisplay from '../../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants/constants';
import BotnetInfoStyles from './BotnetInfo.styles';
const { MONTH_DAY_YEAR_SHORT } = CONSTANTS.DATE_FORMAT;
const { NAME, DATE_ADDED } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;
const useStyles = makeStyles(BotnetInfoStyles);

function BotnetInfo({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      {!!data &&
        data.map((dataItem, index) => (
          <div key={`botnet-info-${index}`} className={classes.itemContainer}>
            <div className={classes.indexContainer}>{index + 1}</div>
            <div className={classes.keyValueContainer}>
              <NRKeyValueDataDisplay showDash keyWidth={'auto'} minWidth={32} valueMaxWidth={508} dataKey={NAME} value={dataItem?.botnetName} />
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={DATE_ADDED}
                value={moment(dataItem.dateAdded).format(MONTH_DAY_YEAR_SHORT)}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
export default BotnetInfo;
