import { FixedSizeList as List } from 'react-window';

function NRVirtualizedScroll({ list, Component, itemHeight = 100 }) {
  return (
    <List height={Math.min(list?.length * itemHeight, 300)} itemCount={list?.length} itemSize={itemHeight} width={580}>
      {Component}
    </List>
  );
}

export default NRVirtualizedScroll;
