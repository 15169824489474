import { makeStyles } from '@mui/styles';
import NRKeyValueDataDisplay from '../../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants/constants';
import NRLink from '../../../../NRLink/NRLink';
import RansomwareInfoStyles from './RansomwareInfo.styles';
const { MONTH_DAY_YEAR_SHORT } = CONSTANTS.DATE_FORMAT;
const { FAMILIES, FIRST_REPORTED, MOST_RECENT_REPORTED, REFERENCE } =
  CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;
const useStyles = makeStyles(RansomwareInfoStyles);

function RansomwareInfo({ data }) {
  const { ransomwareDatetime, recentRansomwareDatetime, ransomwareData } = data;
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      {!!ransomwareData &&
        ransomwareData.map((dataItem, index) => (
          <div key={`ransomware-${index}`} className={classes.itemContainer}>
            <div className={classes.indexContainer}>{index + 1}</div>
            <div className={classes.keyValueContainer}>
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={FAMILIES}
                value={dataItem.families.join(', ')}
              />
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={FIRST_REPORTED}
                value={moment(ransomwareDatetime).format(MONTH_DAY_YEAR_SHORT)}
              />
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={MOST_RECENT_REPORTED}
                value={moment(recentRansomwareDatetime).format(MONTH_DAY_YEAR_SHORT)}
              />
              {dataItem.references.map(reference => (
                <NRKeyValueDataDisplay
                  uniqueKey={`references-${reference.url}`}
                  showDash
                  keyWidth={'auto'}
                  minWidth={32}
                  valueMaxWidth={508}
                  dataKey={REFERENCE}
                  wrapLine
                  value={
                    <>
                      <NRLink url={reference?.url} />
                      <span className={classes.linkDate}>{`(${moment(reference.date).format(MONTH_DAY_YEAR_SHORT)})`}</span>
                    </>
                  }
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}
export default RansomwareInfo;
