import { CONSTANTS } from '../../constants/constants';

export const SBOM_CONSTANTS = {
  ALLOWED_VALUES: {
    MESSAGE: 'must be equal to one of the allowed values',
    MESSAGE_TO_SHOW: 'Must be equal to one of the '
  },
  BE: {
    VERSION: {
      BASE: 'VERSION_V',
      DEFAULT: 'VERSION_UNSPECIFIED'
    },
    FORMAT: {
      JSON: 'FORMAT_JSON',
      XML: 'FORMAT_XML',
      SPDX: 'FORMAT_TAG'
    }
  },
  BINARY: 'BINARY',
  BOM_FORMAT: 'CycloneDX',
  CHECKSUM: {
    ALGORITHM: {
      MD5: 'MD5',
      SHA1: 'SHA-1',
      SHA256: 'SHA-256'
    }
  },
  CONTENT_TYPE: {
    TEXT_PLAIN: 'text/plain'
  },
  CREATORS: {
    ORGANIZATION: 'Organization',
    PERSON: 'Person',
    TOOL: 'Tool'
  },
  DATA_LICENSE: 'CC0-1.0',
  DESCRIBES: 'DESCRIBES',
  DOWNLOAD: {
    ALREADY_IN_PROGRESS_MESSAGE: 'SBOM generation already in progress',
    SUCCESS_MESSAGE: 'generated successfully, download initiated'
  },
  ENCODING: 'base64', //means types that shouldn't show everything in the reports page.
  EXTENSIONS: {
    VALID_EXTENSIONS: ['json', 'xml', 'spdx', 'bom', 'sbom'],
    JSON: 'json',
    XML: 'xml',
    SPDX: 'spdx',
    BOM: 'bom',
    SBOM: 'sbom',
    CYCLONE_DX: 'cdx'
  },
  FORM_TYPE: {
    SBOM: 'SBOM',
    FIRMWARE: 'FIRMWARE'
  },
  CYCLONE_DX_VERIFICATION_JSON: 'CycloneDX',
  CYCLONE_DX_VERIFICATION_XML: '<bom xmlns="http://cyclonedx.org/',
  ERROR_VALIDATION_FROM_USER: 'This document is not an XML or JSON document. If the SBOM is in SPDX Tag format set the extension to .spdx',
  SPDX_VERIFICATION_TAG: '"SPDXVersion: SPDX',
  FILE_NAME: 'SBOM',
  METADATA: {
    TOOLS: {
      NAME: 'NetRise Turbine',
      VENDOR: 'NetRise'
    },
    COMPONENT: {
      TYPE: {
        FIRMWARE: 'firmware'
      }
    }
  },
  NOASSERTION: 'NOASSERTION',
  PART_TYPES: {
    APPLICATION: 'application',
    OPERATIVE_SYSTEM: 'operating system'
  },
  SERIAL_NUMBER_START: 'urn:uuid:',
  SPDX_REF: 'SPDXRef',
  SPDX_REF_DOCUMENT: 'SPDXRef-DOCUMENT',
  SPDX_REF_PACKAGE: 'SPDXRef-Package',
  SPDX_VERSION: 'SPDX-2.2',
  SPEC_VERSION: '1.3',
  TYPES: {
    CYCLONE_DX: 'Cyclone DX',
    SPDX: 'SPDX'
  },
  XMLNS: 'http://cyclonedx.org/schema/bom/1.3',
  SBOM_SPEC: {
    CYCLONEDX_V1_4: 'VERSION_V1_4',
    SPDX_V2_3: 'VERSION_V2_3'
  },
  SBOM_FORMAT: {
    JSON: 'FORMAT_JSON',
    XML: 'FORMAT_XML',
    TAG: 'FORMAT_TAG'
  },
  FILE_FORMAT: {
    FORMAT_JSON: 'JSON',
    FORMAT_XML: 'XML',
    FORMAT_TAG: 'TAG'
  },
  STANDARDS_SPEC_MAP: {
    CYCLONE_DX: 'CYCLONEDX_V1_4',
    SPDX: 'SPDX_V2_3'
  },
  TYPE_COLUMN_MAP: {
    APPLICATION: 'Application',
    FRAMEWORK: 'Framework',
    LIBRARY: 'Library',
    KERNEL: 'Kernel',
    KERNEL_MODULE: 'Kernel Module',
    PACKAGE: 'Package',
    OPERATING_SYSTEM: 'Operating System',
    FIRMWARE: 'Firmware',
    SBOM: 'SBOM'
  },
  TYPE_COLUMN_FILTER_VALUES: [
    {
      title: 'Application',
      value: 'APPLICATION'
    },
    {
      title: 'Framework',
      value: 'FRAMEWORK'
    },
    {
      title: 'Library',
      value: 'LIBRARY'
    },
    {
      title: 'Kernel Module',
      value: 'KERNEL_MODULE'
    },
    {
      title: 'Package',
      value: 'PACKAGE'
    },
    {
      title: 'Operating System',
      value: 'OPERATING_SYSTEM'
    }
  ],
  EXTENSION_TO_VALIDATE: {
    CDX_JSON: '.cdx.json',
    CYCLONE_DX_JSON: '.cyclonedx.json',
    SPDX_JSON: '.spdx.json'
  },
  VALIDATION: {
    XML: {
      ADDITIONAL_PROPERTY: 'Additional properties not allowed'
    }
  }
};

export const SBOM_TYPES = [
  CONSTANTS.TABLE.ASSETS.TYPES.SPDX_JSON,
  CONSTANTS.TABLE.ASSETS.TYPES.SPDX_TAG,
  CONSTANTS.TABLE.ASSETS.TYPES.CYCLONE_DX_JSON,
  CONSTANTS.TABLE.ASSETS.TYPES.CYCLONE_DX_XML,
  CONSTANTS.TABLE.ASSETS.TYPES.SBOM
];
