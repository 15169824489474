import { gql } from '@apollo/client';

export const QL_UPDATE_ASSET = gql`
  mutation updateAsset($args: UpdateAssetInput) {
    asset {
      update(args: $args) {
        status
        product
        version
        vendor
        name
        componentId
        type
      }
    }
  }
`;

//v3
export const GET_ASSET = gql`
  query asset($args: AssetInput) {
    asset(args: $args) {
      status
      startTime
      endTime
      updatedAt
      createdAt
      name
      vendor
      product
      version
      assetCpe
      componentId
      type
      sha256
      hasRemediation
    }
  }
`;

export const GET_LATEST_SNAPSHOT = gql`
  query latestSnapshot($args: AssetInput) {
    latestSnapshot(args: $args) {
      status
      snapshotId
    }
  }
`;

export const GET_ASSET_UPLOAD = gql`
  query assetUpload($args: AssetUploadInput) {
    assetUpload(args: $args) {
      uploadId
      assetId
      uploaded
    }
  }
`;

export const GET_ASSETS_NAMES = gql`
  query assets($args: AssetsInput) {
    assets(args: $args) {
      assets {
        name
        componentId
      }
    }
  }
`;

export const GET_ASSET_NAME = gql`
  query assetName($args: AssetInput) {
    asset(args: $args) {
      name
    }
  }
`;

export const GET_ASSETS = gql`
  query assets($args: AssetsInput) {
    assets(args: $args) {
      assets {
        name
        componentId
        vendor
        product
        version
        createdAt
        updatedAt
        type
        sha256
        risk {
          score
          category
        }
        status
      }
      pageInfo {
        nextPageToken
        prevPageToken
        totalSize
      }
    }
  }
`;

export const GET_ASSETS_STATUSES = gql`
  query assetsStatuses($args: AssetsInput) {
    assets(args: $args) {
      assets {
        componentId
        updatedAt
        status
      }
    }
  }
`;

export const GET_COMPONENTS_COUNT = gql`
  query listComponent($args: ListComponentInput) {
    listComponent(args: $args) {
      pageInfo {
        totalSize
      }
    }
  }
`;

//v2
export const GET_COMPONENT_NAME = gql`
  query componentName($componentId: ID!) {
    component(componentId: $componentId) {
      component {
        name
      }
    }
  }
`;

export const GET_LINUX_DISTRIBUTIONS = gql`
  query getLinuxDistributions {
    getLinuxDistributions {
      value
      label
      basedOn
    }
  }
`;

export const LIST_FILES = gql`
  query listFiles($componentId: String!, $filesystemId: ID, $fileInput: FilesInput) {
    asset(args: { assetId: $componentId }) {
      filesystems(args: { filesystemId: $filesystemId }) {
        files(args: $fileInput) {
          filesList {
            file {
              digest {
                md5
                sha1
                sha256
              }
              path
              createdAt
              updatedAt
              size
              mimeType
              permissions
              hasChildren
            }
            fileId
          }
          pageInfo {
            nextPageToken
            totalSize
          }
        }
      }
    }
  }
`;

export const QL_SUBMIT_ASSET = gql`
  mutation submitAsset($fileName: String!, $args: SubmitAssetInput) {
    asset {
      submit(fileName: $fileName, args: $args) {
        uploadUrl
        uploadId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_SBOM = gql`
  query sbom($args: SBOMDownloadInput) {
    download {
      sbom(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_OPERATION = gql`
  query operation($args: OperationInput) {
    operation(args: $args) {
      operationId
      done
      error
      signedUrl {
        url
        signedHeaders {
          key
          value
        }
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_FILE = gql`
  query file($args: FileDownloadInput) {
    download {
      file(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_FILE_LIST = gql`
  query fileList($args: FileListDownloadInput!) {
    download {
      fileList(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_EXTRACTED_COMPONENT = gql`
  query extractedFirmware($args: ExtractedFirmwareDownloadInput) {
    download {
      extractedFirmware(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_COMPONENT = gql`
  query firmware($args: FirmwareDownloadInput) {
    download {
      firmware(args: $args) {
        operationId
      }
    }
  }
`;

export const SIFT_SEARCH_V2 = gql`
  query sift($args: SiftInput!) {
    sift {
      files(args: $args) {
        hasMore
        files {
          sha256
          path
          matches {
            file
            ordinal
            similarity
            text
          }
          assets {
            componentId
            name
            product
            packages
          }
        }
      }
    }
  }
`;

export const SIFT_METRICS = gql`
  query siftCount {
    sift {
      count {
        assetCount
        fileCount
        pythonFileCount
        scriptFileCount
        textFileCount
      }
    }
  }
`;
