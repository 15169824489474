export default theme => ({
  mainContainer: {
    height: '100%',
    maxHeight: 'calc(100vh - 176px)',
    overflowY: 'auto',
    padding: theme.spacing(3, 3, 6)
  },
  circularProgress: {
    minHeight: 'calc(100vh - 176px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    marginTop: theme.spacing(3)
  },
  alert: {
    height: theme.spacing(5),
    alignItems: 'center'
  },
  alertKnownExploits: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  tab: {
    ...theme.typography.label,
    borderRight: `solid 1px ${theme.palette.divider}`,
    borderBottom: `solid 1px ${theme.palette.divider}`
  }
});
