import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import NRLink from '../../../NRLink/NRLink';
import NRVulnerabilityDescriptionStyles from './NRVulnerabilityDescription.styles';
import { CONSTANTS } from '../../../../constants/constants';
import { CVE_DETAILS_URL } from '../../../../utils/ApiConstants';
import NRKeyValueDataDisplay from '../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import { useContext, useEffect } from 'react';
import { useScrollTrigger } from '@mui/material';
import { VulnDrawerTabsContext } from '../NRVulnerabilityDetails';
import NRSuperIndexAndValue from '../../../NRSuperIndexAndValue/NRSuperIndexAndValue';
import NRSeverityComponent from '../../../NRSeverityComponent';
import { RemediationStatusFormatter } from '../../../NRReactGridCustomComponents/NRCustomProviders/RemediationStatusProvider/RemediationStatusProvider';

const { LINK_TOOLTIP, CVE_SOURCE, DATE, DESCRIPTION, MODIFIED_DATE, PUBLISHED_DATE } =
  CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.DESCRIPTION_SECTION;
const { MONTH_DAY_YEAR_SHORT } = CONSTANTS.DATE_FORMAT;

const useStyles = makeStyles(NRVulnerabilityDescriptionStyles);
const NRVulnerabilityDescription = ({ vulnerabilityData, cveDetails, isLoading, threshold, scrollTarget, index, scrollableId }) => {
  const { setScrollSpyTabsValue } = useContext(VulnDrawerTabsContext);
  const classes = useStyles();
  const cveDetailURL = `${CVE_DETAILS_URL}${cveDetails.id}`;
  const { name, version, severity } = vulnerabilityData;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: !!scrollTarget ? scrollTarget : undefined
  });

  useEffect(() => {
    setScrollSpyTabsValue(index, trigger);
  }, [trigger]);
  return (
    <>
      {(!!name || !!version || !!severity || !!vulnerabilityData?.currentRemediation) && (
        <div className={classes.componentContainer}>
          {!!name && <NRSuperIndexAndValue superIndex={'COMPONENT'} value={name} extraClass={classes.data20} />}
          {!!version && <NRSuperIndexAndValue superIndex={'VERSION'} value={version} extraClass={classes.data20} />}
          {!!severity && (
            <NRSuperIndexAndValue superIndex={'SEVERITY'} value={<NRSeverityComponent value={severity} />} extraClass={classes.data20} />
          )}
          {!!vulnerabilityData?.currentRemediation && (
            <NRSuperIndexAndValue
              superIndex={'STATUS'}
              value={<RemediationStatusFormatter small={true} value={vulnerabilityData?.currentRemediation?.status} row={vulnerabilityData} />}
              extraClass={classes.data40}
            />
          )}
        </div>
      )}
      {!isLoading && (
        <div id={scrollableId} className={classes.descriptionContainer}>
          <div className={classes.title}>{DESCRIPTION}</div>
          <p className={classes.description}>{cveDetails.description}</p>
          <div className={classes.dateContainer}>
            <div className={classes.title}>{DATE}</div>
            <NRKeyValueDataDisplay dataKey={PUBLISHED_DATE} value={moment(cveDetails.createdDatetime).format(MONTH_DAY_YEAR_SHORT)} />
            <NRKeyValueDataDisplay dataKey={MODIFIED_DATE} value={moment(cveDetails.processedDatetime).format(MONTH_DAY_YEAR_SHORT)} />
          </div>
          <div className={classes.title}>{CVE_SOURCE}</div>
          <NRLink tooltipTitle={LINK_TOOLTIP} url={cveDetailURL} />
          <Divider className={classes.divider} />
        </div>
      )}
    </>
  );
};
export default NRVulnerabilityDescription;
