import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const styles = theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100vh',
    'background-color': theme.palette.background.paper,
    top: 0,
    left: 0,
    width: '100%',
    'z-index': 10000
  }
});
const useStyles = makeStyles(styles);
function NRLoadingPage() {
  const classes = useStyles();

  return (
    <div data-test-id="loading-page" className={classes.root}>
      <CircularProgress size={100} />
    </div>
  );
}

export default NRLoadingPage;
