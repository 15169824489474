export const CONSUMER_SERVICE = {
  CVE: 'cve',
  CVE_LABEL: 'CVE',
  SBOM: 'sbom',
  SBOM_LABEL: 'SBOM',
  MACHINE_LEARNING: 'machine-learning',
  MACHINE_LEARNING_LABEL: 'Machine Learning',
  PARSER: 'parse',
  PARSER_LABEL: 'Parse',
  EXTRACT: 'extract',
  EXTRACT_LABEL: 'Extract'
};

export const ConsumerServices = [
  {
    value: CONSUMER_SERVICE.CVE,
    label: CONSUMER_SERVICE.CVE_LABEL
  },
  {
    value: CONSUMER_SERVICE.SBOM,
    label: CONSUMER_SERVICE.SBOM_LABEL
  },
  {
    value: CONSUMER_SERVICE.MACHINE_LEARNING,
    label: CONSUMER_SERVICE.MACHINE_LEARNING_LABEL
  },
  {
    value: CONSUMER_SERVICE.PARSER,
    label: CONSUMER_SERVICE.PARSER_LABEL
  },
  {
    value: CONSUMER_SERVICE.EXTRACT,
    label: CONSUMER_SERVICE.EXTRACT_LABEL
  }
];
