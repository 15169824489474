function NRKevIcon({ height = '20', width = '20', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6766 2.5C13.3608 2.5 13.8747 2.96476 14.1549 3.33843L14.1625 3.34866L14.7325 4.15945H15.2293L15.2494 4.15869C15.2752 4.15783 15.3108 4.15692 15.3548 4.15647C15.4427 4.15555 15.5654 4.15641 15.7124 4.16314C16.0032 4.17644 16.4053 4.21337 16.8287 4.31192C17.6022 4.49195 18.8234 4.97626 19.0923 6.30175C19.2103 6.8526 19.1721 7.451 19.082 7.99243C18.9899 8.54607 18.8331 9.09977 18.6707 9.58281C17.9409 10.9157 17.0303 11.2497 16.6663 11.25L16.1997 10.3475C15.8335 9.71585 15.3295 9.62337 14.9609 9.77083L14.9487 9.77574L14.9362 9.78026C14.6662 9.87847 14.438 10.0694 14.2655 10.2651C14.2481 10.2848 14.2317 10.3041 14.2162 10.323C14.0607 10.4785 14.1663 10.7896 14.1663 10.7896L14.407 11.7778L15.9541 15.9444L17.5425 11.6667H19.1662L16.8955 17.485H15.0032L12.7914 11.8177C12.6288 11.4398 12.4421 10.9944 12.3071 10.6001C12.2256 10.3737 12.2903 10.1057 12.3329 10C12.4651 9.75399 12.6392 9.56836 12.8225 9.37299C12.8861 9.30517 12.9508 9.23617 13.0152 9.16307C13.2939 8.84684 13.738 8.44578 14.3543 8.21848C15.3557 7.82358 16.4069 8.10212 17.1523 8.86413C17.2721 8.48717 17.3756 8.09355 17.4379 7.71892C17.5124 7.27149 17.5181 6.9074 17.462 6.6483L17.4606 6.6418L17.4593 6.63527C17.4007 6.34246 17.1098 6.08855 16.4509 5.9352C16.1574 5.86689 15.8629 5.83844 15.6363 5.82806C15.5246 5.82296 15.4334 5.82241 15.3721 5.82304C15.3415 5.82336 15.3187 5.82397 15.3047 5.82443L15.2906 5.82496L15.2895 5.82501L15.2688 5.82612H13.8669L12.8149 4.32971C12.756 4.25295 12.6989 4.20254 12.6584 4.17618C12.6521 4.17209 12.6469 4.16898 12.6427 4.16667H4.58289L7.0829 6.66667L4.99957 9.16667H8.0829L5.50314 12.9925L4.47663 14.5902L6.41553 17.485H4.71419L3.21096 15.1922H2.40582V17.485H0.832906V11.6667H2.40582V14.0054H3.20954L4.65568 11.6667H4.65761L5.16624 10.8333H1.66624L4.58289 6.66667L0.801758 2.5H12.6766Z"
        fill={color}
      />
      <path
        d="M8.27128 11.7297C8.46697 11.6877 8.65994 11.6667 8.85009 11.6667C9.32634 11.6667 9.74576 11.6718 10.1083 11.682C10.4764 11.6922 10.8066 11.7049 11.0991 11.7202C11.3917 11.7305 11.6592 11.7458 11.9016 11.7663L11.977 11.7727V12.8764H9.26871C9.08145 12.8764 8.94865 12.913 8.86011 12.9758L8.85725 12.9778C8.77734 13.0358 8.73271 13.125 8.73271 13.2613V13.9995L11.5203 14.0606V15.114L8.73271 15.1751V15.8751C8.73271 15.9964 8.75573 16.0825 8.79341 16.1409C8.83962 16.1976 8.89781 16.2383 8.96969 16.2636C9.0567 16.2866 9.14679 16.2982 9.24017 16.2982H11.977V17.4014L11.9022 17.4082C11.6216 17.4339 11.3095 17.4543 10.966 17.4696C10.285 17.4888 8.82283 17.5185 8.42179 17.4845C8.21669 17.4631 8.01828 17.4099 7.82686 17.325L7.82587 17.3246C7.63506 17.237 7.47524 17.1056 7.34688 16.9319L7.345 16.9292C7.21858 16.7431 7.15979 16.496 7.15979 16.1961V13.0396C7.15979 12.7801 7.20923 12.5566 7.31296 12.3733C7.41461 12.1936 7.55108 12.0508 7.72213 11.9463C7.88901 11.8444 8.07225 11.7723 8.27128 11.7297Z"
        fill={color}
      />
      <path
        d="M7.49955 5.83333C9.56913 5.83333 11.2584 5.77801 13.3329 5.83333L11.6757 7.91666C10.1704 7.05637 9.13829 6.42836 7.49955 5.83333Z"
        fill={color}
      />
    </svg>
  );
}

export default NRKevIcon;
