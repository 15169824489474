export const HELPER_CONTENT = {
  JOBS:
    '# Jobs\n' +
    '\n' +
    'The NetRise Turbine platform operates around the concept of Jobs, where a Job is essentially the process that extracts and analyzes a firmware image. The direct output of a Job is a Report which details the risks and vulnerabilities associated with the firmware image.\n' +
    '\n' +
    'On the Jobs page, a list of all jobs is displayed, where each job falls under one of the following statuses:\n' +
    '\n' +
    '- **Created:** a newly created job, waiting to be executed\n' +
    '- **Started:** a job that is currently executing\n' +
    '- **Completed:** a job that was executed and completed successfully.\n' +
    '- **Failed:** a job that was executed and failed at some point during the execution.\n' +
    '\n' +
    'From this page, users can easily see the status of all the Jobs in their environment, view more detailed information about a specific Job, and view the Report that was generated by a Job.\n' +
    '\n' +
    'Inspecting the status of a Job (by clicking the View button - which looks like an eye) gives more information about the Job including the time it was started, which “Task” the Job is currently on, how long the Job took to complete, and any errors that were encountered while the Job was running.\n' +
    '\n' +
    'A Job will either result in a “Completed” or “Failed” state. A Job is considered completed if *all required* tasks completed successfully. Conversely, a Job is considered failed if *any required* task within the Job Definition failed.\n' +
    '\n' +
    "Most commonly, completed Jobs will result in a generated Report with the results of the analysis on a firmware image. If this is the case, when the Job completes, a Report icon will appear under the 'information' column, which will bring the user directly to the Report for that Job. Alternatively, Reports can be accessed from the Reports page or from the link in the Firmware page once a Job completes.\n" +
    '\n',
  REPORTS:
    '# Reports\n' +
    '\n' +
    'The Reports page contains a link to the Report from each successfully completed Job.\n' +
    '\n' +
    'The Report History table contains high level information about the Job that was run, including a timestamp of when the report was generated, notes about the Job that was run, the name and version of the firmware that was analyzed, and lastly the unique Job GUID, which contains a link to the Report for that firmware analysis.\n',
  REPORT:
    '## Report\n' +
    '\n' +
    'Reports represent the findings from an analysis of an asset. Different tabs across the top of the report highlight various key elements of the analysis and the results thereof.\n' +
    '\n' +
    '## Summary\n' +
    '\n' +
    'The summary displays high-level information about the asset that was analyzed, including the asset information (name, version, operating system, kernel version, etc.), and some of the more pertinent findings; Vulnerability information, any identified malware, component prevalence, licenses, risk-related information, and more.\n' +
    '\n' +
    '## Cryptography\n' +
    '\n' +
    'Displays any identified certificates, and any technical details related to those certificates, such as the issuer (with geographic information), validity period, signature algorithm, and more.\n' +
    '\n' +
    '## Credentials\n' +
    '\n' +
    'Displays any known or potential credentials that were identified within the asset. Contains information such as the username, password (if identified), user shell, user home directory, and the file in which the credential data was found.\n' +
    '\n' +
    '## Binaries\n' +
    '\n' +
    'Displays identified binaries within the asset, specifically detailing whether or not certain binary protections were identified within each file. For more information on binary protections and what each of them entails, refer to Appendix A in the full documentation.\n' +
    '\n' +
    '## SBOM (Software Bill of Materials)\n' +
    '\n' +
    'Displays all identified components within the asset, grouped by vendor. Any components that were successfully identified and confirmed to be valid (based on the vendor name, component name, and version) will be present in this table. Clicking the down arrow on a vendor will expand that vendor to reveal each individual component, along with the version, license (if available), and number of CVEs identified for that component (by severity).\n' +
    '\n' +
    '## Vulnerabilities\n' +
    '\n' +
    'Displays all identified CVEs within the asset. For each CVE, the table shows the file name and/or component that is affected, the version of the component, the CVE ID, and the severity. CVEs can be expanded to reveal further information and a link to the CVE record on the MITRE website.\n' +
    '\n' +
    '## File System\n' +
    '\n' +
    'The file system tab contains the entire file system that was extracted from the asset (if applicable). The left side of the display allows the user to explore the folder structure of the file system, while the right side displays the files within the selected folder, including the file name, permissions, size, and modified date. Additionally, users can add (or remove) tags from files, simply by typing in the &#39;add tag&#39; cell next to each file name.\n',
  SEARCH:
    '# Search\n' +
    '\n' +
    'The Search feature allows the user to query all ingested firmware images for any data points that have been parsed during a Job.\n' +
    '\n' +
    'To run a query, simply type the desired search term in the search box and hit enter (or click the “search” button). All ingested data will be (near) instantly searched, and results will populate in the right side of the display.\n' +
    '\n' +
    'Search Results can be filtered by using the options on the left side of the display. Filters include: the parser that yielded the data point, the file path where the data was identified, the firmware (by ID) to which the data point belongs to, as well as the Job GUID that the data point resulted from.\n' +
    '\n' +
    'In the Search Results, clicking the Job GUID link will bring the user to the Report where the data point was found. Clicking the link on the File Path field will bring the user directly to the File System tab of the Report where the result was found.\n' +
    '\n' +
    'For more information on the Parsers and Data Types available, see the full User Guide on the [Knowledge Base](https://kb.netrise.io/where-can-i-find-the-official-documentation-for-turbine)',
  JOB_DEFINITIONS:
    '## Job Definitions\n' +
    '\n' +
    'Job Definitions are the mechanism that provide the user the ability to define custom analyses to be conducted against firmware images. Job Definitions consist of a sequence of Tasks that will be executed, in order, once a Job is initiated that is linked to that Job Definition.\n' +
    '\n' +
    'Here, users can view, modify, or delete existing Job Definitions, or create new Job Definitions. To create a new Job Definition, simply click the &#39;Create&#39; button at the top-right of the page, which brings the user to the Create Job Definition page. Here, the user must give the Job definition a name and a description, and most importantly select the Tasks which are to be executed as part of that Job Definition (in the order they are displayed on the page). Tasks within a Job Definition can be reordered simply by dragging them to the desired position. After this is done, clicking Finish will create the Job Definition, which will now be viewable on the Job Definitions page, and be available for selection when creating a Job.',
  ORGANIZATION:
    '## Organization\n' +
    '\n' +
    'The organization page is where management of users within the platform takes place. New users can be invited to the platform by typing their email in the &quot;Invite to Organization&quot; form and clicking send. Users will receive an invite email from [noreply@netrise.io](mailto:noreply@netrise.io) with instructions on how to access the platform and create their account. When a new user is created, by default they will have the &#39;Member&#39; role, but this (and a few other properties) can be changed by clicking the down arrow on the right side of the entry for that user. The following options are available:\n' +
    '\n' +
    '- **Disable Account:** Will disable the account, denying the ability for that user to login until re-enabled\n' +
    '- **Enable Account:** Re-enable a user account that has been disabled\n' +
    '- **Demote User Role:** demote the user to the role directly below the current role\n' +
    '- **Promote User Role:** promote the user to the role directly above the current role.\n' +
    '\n' +
    '### Users and Roles\n' +
    '\n' +
    'The Turbine platform uses Role Based Access Control (RBAC) to manage users and those users&#39; access to various resources within the platform. The following roles are available:\n' +
    '\n' +
    '- **Owner** : Has full control over the organization within Turbine. The only role that can create additional user accounts within the platform. Includes all other permissions that Operators and Members have.\n' +
    '- **Operator** : Has the same permissions as an owner but does not have the ability to create additional user accounts.\n' +
    '- **Member** : A user with read-only access to the data in the platform.',
  ASSETS:
    '# Assets\n' +
    'The Assets table displays information about all the files uploaded to your Organization. It provides a link to each report as well as a high level summary in each row expansion. The table is searchable and can be filtered by each field and has the following columns displayed be default:\n' +
    '- Name: The name given to the Asset when it was uploaded to the NetRise Platform\n' +
    '- Manufacturer: The organization of person who develops the software or device\n' +
    '- Model: The product name or hardware model associated with the uploaded Asset\n' +
    '- Version: The version of the software or device\n' +
    '- Last Modified: The timestamp of when the Asset’s Report data was last updated\n' +
    '- Analysis Status: Indicates whether the Report for an Asset is ‘Complete’, ‘In Progress’, or ‘Failed’\n' +
    '- Overall Risk: Displays the overall Risk Score associated with the Asset\n' +
    '- Actions: A menu with the following options:\n' +
    '  * Edit Image Details: allows a user to edit the Name, Manufacturer, Model, and Version fields\n' +
    '  * View Analysis Log: displays more detailed log messages and timestamps associated with the analysis\n' +
    '  * Download Image: allows a user to download the originally uploaded Asset\n' +
    '  * Download Extracted Image: allows a user to download the file system extracted from an Asset (only available for ‘Complete’ Reports)\n' +
    '\nThe table has the following optional columns\n' +
    '- Architecture: the Architecture that the NetRise Platform believes is associated with the Asset\n' +
    '  * This is automatically populated during analysis.\n' +
    '- Kernel Version: the Kernel Version (if applicable) that the NetRise Platform detects in the Asset\n' +
    '  * This is automatically populated during analysis.\n' +
    '- OS: the Operating System (if applicable) that the NetRise Platform detects in the Asset\n' +
    '  * This is automatically populated during analysis.\n' +
    '- Bitness: the Bitness (32-bit or 64-bit, if applicable) that the NetRise Platform detects in the Asset\n' +
    '  * This is automatically populated during analysis.\n' +
    '- Uploaded On: the timestamp of when the Asset was uploaded to the NetRise Platform\n',
  UPLOAD_FIRMWARE:
    '# Upload Firmware\n' +
    '\n' +
    'The Upload Firmware form is the mechanism for uploading a firmware image to the Turbine platform. The upload is completed in four steps, each with a variety of field names, some of which are required:\n' +
    '\n' +
    '1. **Identify your firmware image**\n' +
    '\n' +
    'Select a file for upload and give the image a name (required). Names do not have to be unique. If a duplicate firmware image is uploaded (by file hash, not by the submitted name), a new firmware entry will still be created, but the firmware image itself will not be uploaded, as the image already exists in the platform.\n' +
    '\n' +
    'While not required, it is recommended to come up with a standard naming convention for firmware images and use that naming convention whenever possible to maintain consistency in the platform. An example of this might be using a combination of the physical location and asset identification number from an asset management tool.\n' +
    '\n' +
    '2. **Add Information about your firmware**\n' +
    '\n' +
    'This step exposes three fields, all of which are optional. Users should only provide this information if it is known to be accurate, otherwise it is recommended to leave these fields blank.\n' +
    '\n' +
    '- **Manufacturer:** the organization or entity who created, developed, or manufactured the device and firmware image.\n' +
    '- **Model:** the model number or name of the device.\n' +
    '- **Version:** the version number of the firmware image.\n' +
    '3. **Choose the proper architecture and OS for the firmware image**\n' +
    '\n' +
    'Here, a user can optionally provide an operating system and a CPU architecture from a list of pre-defined options. Additionally, the bitness of the firmware image (32-bit or 64-bit) can be selected. Users should only provide this information if it is known to be accurate, otherwise it is recommended to leave these fields blank.\n' +
    '\n' +
    '4. **Review**\n' +
    '\n' +
    'Simply review the provided information and click Finish to upload the firmware image to the platform.\n' +
    '\n' +
    'Once the “Finish” button is pressed, the selected firmware image will be uploaded and the user will be redirected to the Jobs page where the firmware image processing will begin automatically.\n',
  DASHBOARD:
    '# Overview\n' +
    '\n' +
    'The Overview page outlines high level information about the assets that have been ingested into the NetRise platform. Available widgets display statistics about key findings within reports, such as component makeup, temporal CVE findings, and more. Widgets can be interacted with in a variety of ways including zoom level, data selection, and filtering. Generally, filtering one widget or chart will reflect the same filter on others where applicable. Additionally, any widget can be exported as an image or as a CSV of the underlying data by clicking the menu button (three gray lines) in the top right of the widget and choosing &#39;Download&#39;.\n'
};
