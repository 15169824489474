import { makeStyles } from '@mui/styles';
import NRKeyValueDataDisplay from '../../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import { CONSTANTS } from '../../../../../constants/constants';
import KnownAttacksInfoStyles from './KnownAttacksInfo.styles';
import NRFixedVirtualizedList from '../../../../NRVirtualizedLists/NRFixedVirtualizedList';
const { NAME } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;
const useStyles = makeStyles(KnownAttacksInfoStyles);

function KnownAttacksInfo({ data }) {
  const classes = useStyles();

  function Component({ index, style }) {
    const classes = useStyles();
    const knownAttackName = data[index];
    return (
      <div className={classes.itemContainer} key={`known-attacks-${index}`} style={style}>
        <div className={classes.keyValueContainer}>
          <NRKeyValueDataDisplay showDash keyWidth={'auto'} minWidth={32} valueMaxWidth={508} dataKey={NAME} value={knownAttackName} />
        </div>
      </div>
    );
  }

  return <div className={classes.mainContainer}>{!!data && <NRFixedVirtualizedList list={data} Component={Component} itemHeight={34} />}</div>;
}
export default KnownAttacksInfo;
