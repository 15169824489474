function NRActorIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0624 2.00195C12.0208 1.99935 11.9792 1.99935 11.9376 2.00195C10.5794 2.08683 8.53181 2.29065 6.80624 2.63145C5.95172 2.80022 5.12105 3.01294 4.48311 3.28468C4.16807 3.41887 3.83994 3.59216 3.57495 3.82459C3.31231 4.05497 3 4.44704 3 5V13.5C3 13.5238 3.00085 13.5475 3.00254 13.5712C3.18968 16.1913 5.22121 21.664 11.8039 22.9806C11.9333 23.0065 12.0667 23.0065 12.1961 22.9806C18.7788 21.664 20.8103 16.1913 20.9975 13.5712C20.9992 13.5475 21 13.5238 21 13.5V5C21 4.44704 20.6877 4.05497 20.425 3.82459C20.1601 3.59216 19.8319 3.41887 19.5169 3.28468C18.879 3.01294 18.0483 2.80022 17.1938 2.63145C15.4682 2.29065 13.4206 2.08683 12.0624 2.00195ZM5 13.4627V5.2558C5.06087 5.22015 5.14765 5.17549 5.26689 5.1247C5.71228 4.93498 6.38162 4.75395 7.19376 4.59355C8.77617 4.28102 10.6924 4.08605 12 4.00201C13.3076 4.08605 15.2238 4.28102 16.8062 4.59355C17.6184 4.75395 18.2877 4.93498 18.7331 5.1247C18.8523 5.17549 18.9391 5.22015 19 5.2558V13.4627C18.8509 15.3631 17.4399 19.2655 13 20.7082V20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20V20.7082C6.56009 19.2655 5.14906 15.3631 5 13.4627Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7487 8.83676C14.4404 8.48809 13.9197 8.39217 13.5008 8.63353C13.0222 8.90924 12.8578 9.52069 13.1335 9.99922C13.3074 10.301 13.6972 10.7912 14.3337 10.951C15.0948 11.142 15.7683 10.7742 16.2513 10.1632C16.5596 10.5119 17.0803 10.6078 17.4992 10.3665C17.9778 10.0908 18.1422 9.47931 17.8665 9.00077C17.6926 8.69901 17.3028 8.2088 16.6663 8.04902C15.9052 7.85797 15.2317 8.22581 14.7487 8.83676Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25133 8.83676C9.55956 8.48809 10.0803 8.39217 10.4992 8.63353C10.9778 8.90924 11.1422 9.52069 10.8665 9.99922C10.6926 10.301 10.3028 10.7912 9.66629 10.951C8.90523 11.142 8.23169 10.7742 7.74867 10.1632C7.44044 10.5119 6.91968 10.6078 6.50077 10.3665C6.02224 10.0908 5.85781 9.47931 6.13353 9.00077C6.30739 8.69901 6.69717 8.2088 7.33371 8.04902C8.09477 7.85797 8.76831 8.22581 9.25133 8.83676Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45961 14.2929C7.85015 13.9024 8.48331 13.9024 8.87383 14.2929L9.58089 15H10.5858L11.2929 14.2929C11.4804 14.1054 11.7348 14 12 14C12.2652 14 12.5196 14.1054 12.7071 14.2929L13.4142 15H14.5858L15.2929 14.2929C15.6834 13.9024 16.3166 13.9024 16.7071 14.2929C17.0976 14.6834 17.0976 15.3166 16.7071 15.7071L15.7071 16.7071C15.5196 16.8947 15.2652 17 15 17H13C12.7348 17 12.4804 16.8947 12.2929 16.7071L12 16.4142L11.7071 16.7071C11.5196 16.8947 11.2652 17 11 17H9.16667C8.90144 17 8.64708 16.8947 8.45955 16.7071L7.45959 15.7071C7.06907 15.3166 7.06908 14.6834 7.45961 14.2929Z"
        fill={color}
      />
    </svg>
  );
}

export default NRActorIcon;
