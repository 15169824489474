function NRPendoMarkIcon({ height = '36', width = '36', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" id={'pendo-netrise'}>
      <path
        d="M17 24H19V22H17V24ZM18 8C12.48 8 8 12.48 8 18C8 23.52 12.48 28 18 28C23.52 28 28 23.52 28 18C28 12.48 23.52 8 18 8ZM18 26C13.59 26 10 22.41 10 18C10 13.59 13.59 10 18 10C22.41 10 26 13.59 26 18C26 22.41 22.41 26 18 26ZM18 12C15.79 12 14 13.79 14 16H16C16 14.9 16.9 14 18 14C19.1 14 20 14.9 20 16C20 18 17 17.75 17 21H19C19 18.75 22 18.5 22 16C22 13.79 20.21 12 18 12Z"
        fill={color}
      />
    </svg>
  );
}
export default NRPendoMarkIcon;
