function NRAssetIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5292 2.67997C11.4425 2.16627 12.5575 2.16627 13.4708 2.67997L19.4708 6.05497C20.4154 6.58632 21 7.58587 21 8.66969V15.3303C21 16.4141 20.4154 17.4137 19.4708 17.945L13.4708 21.32C12.5575 21.8337 11.4425 21.8337 10.5292 21.32L4.52922 17.945C3.58459 17.4137 3 16.4141 3 15.3303V8.66969C3 7.58587 3.58459 6.58632 4.52922 6.05497L10.5292 2.67997ZM12.4903 4.42312C12.1858 4.25188 11.8142 4.25188 11.5097 4.42312L6.09055 7.47141L12 10.8482L15.0039 9.13175C15.4834 8.85775 16.0942 9.02434 16.3682 9.50386C16.6423 9.98338 16.4757 10.5942 15.9961 10.8682L13 12.5803V19.2901L18.4903 16.2019C18.8051 16.0248 19 15.6916 19 15.3303V8.66969C19 8.30842 18.8051 7.97524 18.4903 7.79812L12.4903 4.42312ZM11 19.2901V12.5803L5 9.15174V15.3303C5 15.6916 5.19486 16.0248 5.50974 16.2019L11 19.2901Z"
        fill={color}
      />
    </svg>
  );
}
export default NRAssetIcon;
