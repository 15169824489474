import { CONSTANTS } from '../constants/constants';
const { NOT_AUTHENTICATED, SESSION_EXPIRED } = CONSTANTS.AUTH;

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const JWT_LOCAL_STORAGE = 'netriseJWT';

export function getToken() {
  return localStorage.getItem(JWT_LOCAL_STORAGE);
}

export function isValidSession() {
  const token = parseJwt(getToken());
  return token && token.exp * 1000 >= Date.now();
}

export function redirectReason() {
  if (!getToken()) {
    return NOT_AUTHENTICATED;
  } else if (!isValidSession()) {
    return SESSION_EXPIRED;
  }
}

export function handleLogin(res, cb) {
  localStorage.setItem(JWT_LOCAL_STORAGE, res.data.token);
  cb();
}

export async function login(mail, pass, cb) {
  await sleep(2000);
  if (mail !== email || pass !== password) {
    console.log('Invalid credentials: ', mail, pass);
    throw 'Login error';
  }
  localStorage.setItem(JWT_LOCAL_STORAGE, token);
  cb();
}

export function localLogout(cb) {
  localStorage.removeItem(JWT_LOCAL_STORAGE);
  cb();
}

export function isLocallyAuthenticated() {
  return isValidSession();
}

export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}
