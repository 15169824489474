function LinuxIcon({ height = '24', width = '24' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_310_1625)">
        <g filter="url(#filter0_f_310_1625)">
          <path
            d="M20.2354 18.8967C20.2354 20.0718 16.5357 21.0293 11.9708 21.0293C7.40594 21.0293 3.70624 20.078 3.70624 18.8967C3.70624 17.7216 7.40594 16.764 11.9708 16.764C16.5357 16.764 20.229 17.7216 20.2354 18.8967Z"
            fill="black"
            fillOpacity="0.2"
          />
        </g>
        <path
          d="M7.44466 16.6024C6.64401 15.4957 6.48904 11.8895 8.35499 9.66978C9.27834 8.60669 9.51726 7.86678 9.58827 6.86571C9.63988 5.72787 8.75535 2.33934 12.087 2.08442C15.4639 1.82327 15.2766 5.03149 15.2572 6.72894C15.2444 8.15894 16.3485 8.97347 17.1103 10.0927C18.5114 12.1445 18.3952 15.676 16.8456 17.591C14.8827 19.9848 13.204 18.9464 12.087 19.0335C9.99503 19.1517 9.92402 20.2211 7.44466 16.6024Z"
          fill="black"
        />
        <path
          d="M15.593 10.304C16.1741 10.82 17.6785 13.1267 15.296 14.5692C14.4567 15.0729 16.045 16.9879 16.8069 16.0615C18.1564 14.3952 17.2976 11.734 16.4841 10.7827C15.9417 10.1113 15.1023 9.85011 15.5931 10.304L15.593 10.304Z"
          fill="url(#paint0_linear_310_1625)"
        />
        <path
          d="M15.4316 9.99315C16.3872 10.7393 18.1241 13.3506 15.5866 15.0542C14.7537 15.5827 16.3226 17.2553 17.2072 16.4034C20.2741 13.4314 17.1298 10.018 16.045 8.84285C15.0765 7.817 14.2178 9.07915 15.4316 9.99315Z"
          fill="black"
          stroke="black"
          strokeWidth="0.0992266"
        />
        <path
          d="M14.0563 3.70712C14.0241 4.55896 13.0491 5.29894 11.8804 5.36733C10.7118 5.43571 9.79497 4.7891 9.82718 3.93726C9.85952 3.08542 10.8345 2.3455 12.0031 2.27712C13.1717 2.21496 14.0886 2.85535 14.0563 3.70712Z"
          fill="url(#paint1_linear_310_1625)"
        />
        <path
          d="M11.2606 5.73406C11.2929 6.28744 11.0217 6.75374 10.6472 6.77236C10.2727 6.79105 9.94339 6.36205 9.91111 5.80867C9.87884 5.25529 10.15 4.78898 10.5245 4.77036C10.899 4.75168 11.2283 5.18068 11.2606 5.73406Z"
          fill="url(#paint2_linear_310_1625)"
        />
        <path
          d="M11.9385 5.7776C11.9515 6.41799 12.3841 6.92783 12.9006 6.91544C13.4171 6.90298 13.8239 6.37445 13.811 5.72784C13.7981 5.08745 13.3655 4.57761 12.8489 4.59007C12.3324 4.60246 11.9192 5.13715 11.9385 5.7776Z"
          fill="url(#paint3_linear_310_1625)"
        />
        <path
          d="M12.3647 5.9206C12.3582 6.27499 12.539 6.56099 12.7779 6.56099C13.0103 6.56099 13.2105 6.27499 13.217 5.92683C13.2234 5.57244 13.0426 5.28638 12.8037 5.28638C12.5648 5.28638 12.3711 5.57244 12.3647 5.9206Z"
          fill="black"
        />
        <path
          d="M10.8732 5.92684C10.9055 6.21907 10.7893 6.46777 10.6214 6.49261C10.4535 6.5113 10.2921 6.29369 10.2598 6.00146C10.2275 5.70923 10.3438 5.46047 10.5116 5.43562C10.6795 5.417 10.8409 5.63461 10.8732 5.92684Z"
          fill="black"
        />
        <path
          d="M12.4615 5.72165C12.4421 5.92065 12.539 6.08227 12.6746 6.09472C12.8101 6.10718 12.9393 5.95172 12.9586 5.75896C12.978 5.56003 12.8811 5.39834 12.7456 5.38589C12.61 5.37349 12.4809 5.52895 12.4615 5.72172V5.72165Z"
          fill="url(#paint4_linear_310_1625)"
        />
        <path
          d="M10.7311 5.77138C10.7505 5.93307 10.6795 6.07607 10.5762 6.08846C10.4729 6.10091 10.376 5.97653 10.3566 5.80869C10.3373 5.647 10.4083 5.504 10.5116 5.49161C10.615 5.47915 10.7118 5.60969 10.7311 5.77138Z"
          fill="url(#paint5_linear_310_1625)"
        />
        <path
          d="M8.69723 11.8771C9.15567 10.876 10.1306 9.11648 10.15 7.7548C10.15 6.67296 13.514 6.41181 13.7852 7.49372C14.0563 8.57556 14.7407 10.1921 15.1798 10.9756C15.6189 11.7527 16.8908 14.2335 15.5349 16.3972C14.3146 18.3123 10.6149 19.8294 8.63915 16.1361C7.96767 14.8553 8.09029 13.2698 8.69723 11.8771Z"
          fill="url(#paint6_linear_310_1625)"
        />
        <path
          d="M8.28399 11.131C7.89017 11.846 7.07658 13.7237 9.0653 14.7496C11.2025 15.8439 11.196 18.0885 8.6262 17.019C6.276 16.0553 7.3155 12.1507 7.97412 11.218C8.40022 10.5652 9.04589 9.76313 8.28399 11.131Z"
          fill="url(#paint7_linear_310_1625)"
        />
        <path
          d="M8.61972 10.3227C7.99345 11.3051 6.49551 13.618 8.50357 14.8242C11.2024 16.4221 10.4405 18.0138 7.97411 16.5775C4.50039 14.5755 7.55435 10.5341 8.9426 8.99214C10.531 7.26362 9.24607 9.30922 8.61972 10.3226V10.3227Z"
          fill="black"
          stroke="black"
          strokeWidth="0.126953"
        />
        <path
          d="M14.702 12.2563C14.702 13.3506 13.6108 14.7683 11.7383 14.7559C9.80784 14.7682 8.98785 13.3506 8.98785 12.2563C8.98785 11.1621 10.2663 10.2729 11.8417 10.2729C13.4236 10.2792 14.702 11.1621 14.702 12.2563Z"
          fill="url(#paint8_linear_310_1625)"
        />
        <path
          d="M13.6819 8.81798C13.6625 9.94959 12.8941 10.217 11.9256 10.217C10.9571 10.217 10.2533 10.0491 10.1694 8.81798C10.1694 8.04699 10.9571 7.59937 11.9256 7.59937C12.8941 7.59314 13.6818 8.04083 13.6818 8.81798H13.6819Z"
          fill="url(#paint9_linear_310_1625)"
        />
        <path
          d="M7.82559 10.559C8.45841 9.63257 9.79491 8.20873 8.07745 10.7579C6.6828 12.8595 7.56094 14.2087 8.01291 14.5817C9.31713 15.7009 9.26545 16.4532 8.23889 15.8625C6.03712 14.6004 6.49556 12.4739 7.82559 10.559Z"
          fill="url(#paint10_linear_310_1625)"
        />
        <path
          d="M16.9877 10.9383C16.4389 9.85022 14.6955 7.08961 17.0716 10.2978C19.2346 13.2014 17.7173 15.2222 17.4461 15.4211C17.175 15.6201 16.2646 16.0243 16.5292 15.3217C16.8005 14.619 18.1435 13.2885 16.9877 10.9382L16.9877 10.9383Z"
          fill="url(#paint11_linear_310_1625)"
        />
        <path
          d="M7.34776 19.5185C5.90143 18.7786 3.80303 19.6615 4.56493 17.6595C4.7199 17.2055 4.33895 16.5216 4.58427 16.0802C4.87487 15.5392 5.50114 15.6574 5.87563 15.2968C6.24364 14.9237 6.4761 14.2771 7.16699 14.3766C7.85141 14.476 8.30985 15.2843 8.78762 16.2792C9.1427 16.9879 10.3953 17.989 10.3114 18.7848C10.2081 20.0034 8.76822 20.2335 7.34776 19.5185Z"
          fill="url(#paint12_linear_310_1625)"
          stroke="#E68C3F"
          strokeWidth="0.634766"
        />
        <path
          d="M17.8787 14.818C17.6592 15.2159 16.7423 15.8563 16.1289 15.6885C15.5091 15.5268 15.2249 14.619 15.3476 13.9289C15.4639 13.158 16.1289 13.1143 16.9683 13.4999C17.8722 13.9289 18.1434 14.2895 17.8787 14.818Z"
          fill="url(#paint13_linear_310_1625)"
        />
        <path
          d="M17.3751 19.1764C18.434 17.9392 20.7907 18.1941 19.2024 16.4967C18.8666 16.1299 18.9699 15.3465 18.5567 15.0108C18.0724 14.6004 17.5365 14.9361 17.0458 14.7248C16.5551 14.4947 16.0386 14.0532 15.4381 14.3641C14.8376 14.6812 14.773 15.5019 14.7149 16.5838C14.6633 17.361 13.9272 18.6604 14.3211 19.3754C14.8893 20.4822 16.3679 20.333 17.3751 19.1765V19.1764Z"
          fill="url(#paint14_linear_310_1625)"
          stroke="#E68C3F"
          strokeWidth="0.634867"
        />
        <path
          d="M16.9166 17.5226C18.5567 15.1911 17.3364 15.2097 16.9231 15.0294C16.5099 14.8429 16.0773 14.476 15.5931 14.7309C15.1088 14.9921 15.083 15.6636 15.07 16.5465C15.0507 17.1807 14.5019 18.2439 14.8312 18.8345C15.2315 19.5247 16.1999 18.5237 16.9166 17.5226Z"
          fill="url(#paint15_linear_310_1625)"
        />
        <path
          d="M7.01208 17.8584C4.55852 16.3288 5.70778 15.8066 6.07586 15.5641C6.52136 15.2532 6.52783 14.6563 7.07662 14.7123C7.62548 14.7682 7.94829 15.4211 8.3163 16.2294C8.5875 16.8076 9.53018 17.5786 9.45916 18.2439C9.36881 19.0273 8.07098 18.5112 7.01208 17.8584Z"
          fill="url(#paint16_linear_310_1625)"
        />
        <path
          d="M17.7367 14.7993C17.543 15.1227 16.7553 15.6325 16.2323 15.4957C15.6964 15.3651 15.451 14.6377 15.5608 14.0905C15.6576 13.4687 16.2323 13.4377 16.9554 13.7486C17.7238 14.0843 17.9627 14.3703 17.7367 14.7993Z"
          fill="black"
        />
        <path
          d="M17.2072 14.619C17.0845 14.8428 16.568 15.1972 16.2064 15.104C15.8448 15.0107 15.664 14.5071 15.7221 14.1278C15.7738 13.6988 16.1612 13.6739 16.6519 13.8853C17.1813 14.1216 17.3492 14.3206 17.2072 14.619Z"
          fill="url(#paint17_linear_310_1625)"
        />
        <path
          d="M10.531 6.50506C10.7892 6.27499 11.4155 5.57244 12.6036 6.30606C12.8231 6.44284 13.0039 6.45529 13.4236 6.62937C14.2694 6.96521 13.8691 7.77344 12.9651 8.04698C12.5777 8.16513 12.2291 8.61905 11.5317 8.57551C10.9313 8.54437 10.7763 8.1652 10.4083 7.95998C9.75615 7.60559 9.65933 7.12683 10.0144 6.8719C10.3696 6.61698 10.5052 6.52368 10.531 6.50506Z"
          fill="url(#paint18_linear_310_1625)"
          stroke="#E68C3F"
          strokeWidth="0.380859"
        />
        <path
          d="M13.4365 7.15796C13.0814 7.17658 12.3129 7.9165 11.5059 7.9165C10.6988 7.9165 10.2145 7.19527 10.0919 7.19527"
          stroke="#E68C3F"
          strokeWidth="0.253906"
        />
        <path
          d="M10.9313 6.44299C11.0605 6.33106 11.4672 6.02638 12.0031 6.33722C12.1194 6.39945 12.2356 6.46783 12.4034 6.56107C12.7456 6.75383 12.5778 7.03367 12.1645 7.20768C11.9773 7.2823 11.6674 7.44399 11.435 7.43153C11.1767 7.40668 11.0023 7.24499 10.8345 7.1393C10.5181 6.94037 10.5375 6.77245 10.686 6.64191C10.7957 6.54238 10.9184 6.44915 10.9314 6.44299H10.9313Z"
          fill="url(#paint19_linear_310_1625)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_310_1625"
          x="2.38593"
          y="15.4437"
          width="19.1697"
          height="6.90588"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.660156" result="effect1_foregroundBlur_310_1625" />
        </filter>
        <linearGradient id="paint0_linear_310_1625" x1="16.2494" y1="17.2633" x2="16.6426" y2="12.6841" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint1_linear_310_1625" x1="12.1282" y1="2.34746" x2="11.9406" y2="5.09014" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_310_1625" x1="10.611" y1="6.53754" x2="10.4955" y2="4.96966" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint3_linear_310_1625" x1="12.8732" y1="6.57819" x2="12.8732" y2="4.91106" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint4_linear_310_1625" x1="12.7297" y1="5.40722" x2="12.6504" y2="6.0472" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint5_linear_310_1625" x1="10.5271" y1="5.52355" x2="10.6124" y2="6.04229" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint6_linear_310_1625" x1="12.1635" y1="17.218" x2="12.1635" y2="11.5121" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint7_linear_310_1625" x1="9.01189" y1="17.2988" x2="7.60132" y2="12.3052" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint8_linear_310_1625" x1="11.8358" y1="10.866" x2="11.8499" y2="14.5172" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint9_linear_310_1625" x1="11.9231" y1="8.5764" x2="11.9387" y2="10.0282" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint10_linear_310_1625" x1="7.85162" y1="9.47249" x2="7.85162" y2="14.8001" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint11_linear_310_1625" x1="17.0319" y1="8.95217" x2="17.0301" y2="13.7659" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint12_linear_310_1625" x1="7.5643" y1="17.0842" x2="6.79923" y2="19.8422" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA63F" />
          <stop offset="1" stopColor="#FFFF00" />
        </linearGradient>
        <linearGradient id="paint13_linear_310_1625" x1="16.7105" y1="16.7712" x2="16.6018" y2="11.6763" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFEED7" />
          <stop offset="1" stopColor="#BDBFC2" />
        </linearGradient>
        <linearGradient id="paint14_linear_310_1625" x1="15.8971" y1="16.2551" x2="18.02" y2="19.3783" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA63F" />
          <stop offset="1" stopColor="#FFFF00" />
        </linearGradient>
        <linearGradient id="paint15_linear_310_1625" x1="16.5266" y1="13.8829" x2="15.8165" y2="17.4926" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint16_linear_310_1625" x1="7.38491" y1="14.7701" x2="7.52485" y2="18.8086" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint17_linear_310_1625" x1="16.3838" y1="13.8175" x2="16.548" y2="15.0276" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint18_linear_310_1625" x1="11.839" y1="6.43785" x2="11.8527" y2="8.56259" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA63F" />
          <stop offset="1" stopColor="#FFFF00" />
        </linearGradient>
        <linearGradient id="paint19_linear_310_1625" x1="11.5882" y1="6.23699" x2="11.5845" y2="7.36047" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.65" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_310_1625">
          <rect width="18" height="20" fill="white" transform="translate(3 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinuxIcon;
