import { createContext, useState } from 'react';
import { CONSTANTS } from '../../constants/constants';
const { SUMMARY } = CONSTANTS.REPORT.TABS;

export const ReportTabsContext = createContext({
  isContextInitialized: null,
  organization: null,
  setOrganization: () => {},
  component: null,
  setComponent: () => {},
  cve: null,
  setCve: () => {},
  severityFilter: null,
  setSeverityFilter: () => {},
  currentTab: null,
  setCurrentTab: () => {},
  shouldClearAllFilters: null,
  setShouldClearAllFilters: () => {},
  filePath: null,
  setFilePath: () => {},
  cryptographyFilter: null,
  setCryptographyFilter: () => {},
  credentialsFilter: null,
  setCredentialsFilter: () => {},
  detectedHashesFilter: null,
  setDetectedHashesFilter: () => {},
  cryptographyTab: null,
  setCryptographyTab: () => {},
  credentialsTab: null,
  setCredentialsTab: () => {},
  binaryProtectionFilter: null,
  setBinaryProtectionFilter: () => {},
  exploitFilter: null,
  setExploitFilter: () => {},
  statusFilter: null,
  setStatusFilter: () => {},
  categoryFilter: null,
  setCategoryFilter: () => {},
  versionFilter: null,
  setVersionFilter: () => {},
  maturityFilter: null,
  setMaturityFilter: () => {},
  inKnownExploitedVulnerabilities: null,
  setInKnownExploitedVulnerabilities: () => {}
});

function NRReportTabsContextWrapper({ children }) {
  const [isContextInitialized] = useState(true);
  const [organization, setOrganization] = useState();
  const [component, setComponent] = useState();
  const [cve, setCve] = useState();
  const [severityFilter, setSeverityFilter] = useState([]);
  const [selectedFilePath, setSelectedFilePath] = useState();
  const [shouldClearAllFilters, setShouldClearAllFilters] = useState();
  const [cryptographyTab, setCryptographyTab] = useState();
  const [credentialsTab, setCredentialsTab] = useState();
  const [cryptographyFilter, setCryptographyFilter] = useState();
  const [credentialsFilter, setCredentialsFilter] = useState();
  const [detectedHashesFilter, setDetectedHashesFilter] = useState();
  const [binaryProtectionFilter, setBinaryProtectionFilter] = useState();
  const [exploitFilter, setExploitFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [versionFilter, setVersionFilter] = useState();
  const [maturityFilter, setMaturityFilter] = useState();
  const [inKnownExploitedVulnerabilities, setInKnownExploitedVulnerabilities] = useState(false);
  const [currentTab, setCurrentTab] = useState(SUMMARY);

  const defaultValues = {
    isContextInitialized,
    organization,
    setOrganization,
    component,
    setComponent,
    cve,
    setCve,
    severityFilter,
    setSeverityFilter,
    currentTab,
    setCurrentTab,
    shouldClearAllFilters,
    setShouldClearAllFilters,
    filePath: selectedFilePath,
    setFilePath: setSelectedFilePath,
    cryptographyFilter,
    setCryptographyFilter,
    cryptographyTab,
    setCryptographyTab,
    credentialsFilter,
    setCredentialsFilter,
    detectedHashesFilter,
    setDetectedHashesFilter,
    credentialsTab,
    setCredentialsTab,
    binaryProtectionFilter,
    setBinaryProtectionFilter,
    exploitFilter,
    setExploitFilter,
    statusFilter,
    setStatusFilter,
    categoryFilter,
    setCategoryFilter,
    versionFilter,
    setVersionFilter,
    maturityFilter,
    setMaturityFilter,
    inKnownExploitedVulnerabilities,
    setInKnownExploitedVulnerabilities
  };

  return <ReportTabsContext.Provider value={defaultValues}>{children}</ReportTabsContext.Provider>;
}

export default NRReportTabsContextWrapper;
