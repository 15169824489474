import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { REMEDIATION_CONSTANTS } from '../NRRemediation/NRRemediation.constants';

function NRRemediationStatusButton({ status, handleOnClick, isInteractive = true, small = false }) {
  const theme = useTheme();
  const { FALSE_POSITIVE, RESOLVED_WITH_PEDIGREE, UNSPECIFIED, NOT_AFFECTED, AFFECTED, FIXED, UNDER_INVESTIGATION } =
    REMEDIATION_CONSTANTS.STATUS.VALUES;

  function getIconTextAndColor(text) {
    switch (text) {
      case UNSPECIFIED:
        return [
          FiberManualRecordOutlinedIcon,
          REMEDIATION_CONSTANTS.STATUS.LABELS[text],
          theme.palette.statusPalette[2],
          theme.palette.text.secondary
        ];
      case NOT_AFFECTED:
      case FIXED:
      case FALSE_POSITIVE:
        return [FiberManualRecordIcon, REMEDIATION_CONSTANTS.STATUS.LABELS[text], theme.palette.success.main, theme.palette.success.dark];
      case RESOLVED_WITH_PEDIGREE:
        return [FiberManualRecordIcon, 'Resolved Pedigree', theme.palette.success.main, theme.palette.success.dark];
      case AFFECTED:
        return [FiberManualRecordIcon, REMEDIATION_CONSTANTS.STATUS.LABELS[text], theme.palette.error.main, theme.palette.error.dark];
      case UNDER_INVESTIGATION:
        return [FiberManualRecordIcon, REMEDIATION_CONSTANTS.STATUS.LABELS[text], theme.palette.info.main, theme.palette.info.dark];
      default:
        return [
          FiberManualRecordOutlinedIcon,
          REMEDIATION_CONSTANTS.STATUS.LABELS.UNSPECIFIED,
          theme.palette.statusPalette[2],
          theme.palette.text.secondary
        ];
    }
  }

  let [Icon, text, color, altColor] = getIconTextAndColor(status);

  const classes = makeStyles(theme => ({
    remediationButton: {
      color: altColor || color,
      backgroundColor: alpha(color, 0.15),
      height: small ? theme.spacing(3) : theme.spacing(3.5),
      ...(!isInteractive && { pointerEvents: 'none' }),
      '&:hover': {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity)
      }
    },
    labelContainer: {
      ...(!isInteractive && { width: theme.spacing(15) }),
      display: 'flex',
      alignItems: 'center'
    },
    buttonTypography: {
      fontSize: theme.spacing(1.5),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.spacing(1.75)
    },
    circleIcon: {
      color: color
    }
  }))();

  return (
    <Button className={classes.remediationButton} onClick={() => handleOnClick(status)}>
      <Box className={classes.labelContainer}>
        <Icon className={classes.circleIcon} sx={{ pr: theme.spacing(0.5), width: '16px', height: '16px' }} />
        <Typography className={classes.buttonTypography} children={text} />
      </Box>
    </Button>
  );
}

export default NRRemediationStatusButton;
