import { makeStyles } from '@mui/styles';
import NRKeyValueDataDisplay from '../../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import { CONSTANTS } from '../../../../../constants/constants';
import NRLink from '../../../../NRLink/NRLink';
import ThreatActorsInfoStyles from './ThreatActorsInfo.styles';
const { NAME, REFERENCE, ALIASES } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;
const useStyles = makeStyles(ThreatActorsInfoStyles);

function ThreatActorsInfo({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      {!!data &&
        data.map((dataItem, index) => (
          <div key={`threat-actors-${index}`} className={classes.itemContainer}>
            <div className={classes.indexContainer}>{index + 1}</div>
            <div className={classes.keyValueContainer}>
              <NRKeyValueDataDisplay showDash keyWidth={'auto'} minWidth={32} valueMaxWidth={508} dataKey={NAME} value={dataItem?.name} />
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={ALIASES}
                value={dataItem?.aliases?.join(', ')}
              />
              <NRKeyValueDataDisplay
                showDash
                keyWidth={'auto'}
                minWidth={32}
                valueMaxWidth={508}
                dataKey={REFERENCE}
                value={<NRLink url={dataItem?.url} />}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
export default ThreatActorsInfo;
