import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import NRSeverityAndMetricsStyles from './NRSeverityAndMetrics.styles';
import { isEmpty, showAsPercentile, splitCamelCase, splitCamelCaseWithInitials } from '../../../../utils/utils';
import { CONSTANTS } from '../../../../constants/constants';
import NRLoader from '../../../NRLoader/NRLoader';
import Divider from '@mui/material/Divider';
import { useScrollTrigger } from '@mui/material';
import { VulnDrawerTabsContext } from '../NRVulnerabilityDetails';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import NRShowMore from '../../../NRShowMore/NRShowMore';
import NRLink from '../../../NRLink/NRLink';
import NRKeyValueDataDisplay from '../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
const { CVSS_V2, CVSS_V3, KEYS, TOOLTIP_KEYS, TITLE, TOOLTIP, VECTOR, EPSS_SCORE, EPSS_PERCENTILE, EPSS_TOOLTIP_INFO, CWE, CWE_TOOLTIP_INFO } =
  CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.SEVERITY_AND_METRICS_SECTION;
const { V3, V2 } = CONSTANTS.TABLE.VULNERABILITIES_TABLE;

let tooltipEntries = [
  'attackVector',
  'attackComplexity',
  'privilegesRequired',
  'userInteraction',
  'scope',
  'accessVector',
  'accessComplexity',
  'authentication',
  'confidentialityImpact',
  'integrityImpact',
  'availabilityImpact'
];

let orderedColumns = {
  [V3]: [
    'baseScore', // + BaseSeverity
    'vectorString',
    'impactScore',
    'exploitabilityScore'
  ],
  [V2]: [
    'baseScore', // + BaseSeverity
    'vectorString',
    'impactScore',
    'exploitabilityScore'
  ]
};

const useStyles = makeStyles(NRSeverityAndMetricsStyles);

const NRSeverityAndMetrics = ({ cveDetails, cveDetailsLoading, threshold, scrollTarget, index, scrollableId }) => {
  const { setScrollSpyTabsValue } = useContext(VulnDrawerTabsContext);
  const classes = useStyles();
  const [cvssVersion, setCvssVersion] = useState(V3);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: !!scrollTarget ? scrollTarget : undefined
  });

  useEffect(() => {
    setScrollSpyTabsValue(index, trigger);
  }, [trigger]);

  const handleCvssVersion = (event, newVersion) => {
    !!newVersion && setCvssVersion(newVersion);
  };

  useEffect(() => {
    !isEmpty(cveDetails) && !cveDetails[V3] && setCvssVersion(V2);
  }, [cveDetails]);

  function getTooltipTitle(cveDetails, cvssVersion, key, baseSeverity) {
    switch (key) {
      case 'baseScore':
        return cveDetails[cvssVersion]['base'][key] + baseSeverity;

      case 'vectorString':
        return (
          <div className={classes.vectorTooltipMainContainer}>
            <div className={classes.vectorTooltipTitle}>{VECTOR}</div>
            {tooltipEntries.map((name, index) => {
              return (
                <React.Fragment key={`vector-string-tooltip-${index}`}>
                  {!!cveDetails[cvssVersion]['base'][name] && (
                    <div className={classes.vectorTooltipLineContainer}>
                      <div className={classes.vectorTooltipLineTitleContainer}>{TOOLTIP_KEYS[name] || splitCamelCaseWithInitials(name)}</div>
                      <div className={classes.vectorTooltipLineValueContainer}>{cveDetails[cvssVersion]['base'][name].toLowerCase()}</div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      default:
        return cveDetails[cvssVersion]['base'][key];
    }
  }

  return (
    <div id={scrollableId} className={classes.severityAndMetricsContainer}>
      {!cveDetailsLoading && !isEmpty(cveDetails) && (
        <>
          <div>
            <div className={classes.title}>{TITLE}</div>
            <ToggleButtonGroup
              classes={{ root: classes.buttonGroup }}
              value={cvssVersion}
              exclusive
              onChange={handleCvssVersion}
              aria-label="cvss version"
            >
              <ToggleButton size="small" disabled={!cveDetails[V3]} classes={{ root: classes.toggleButton }} value={V3} aria-label="Cvss3">
                <Tooltip title={!!cveDetails[V3] ? '' : CVSS_V3 + TOOLTIP}>
                  <div>{CVSS_V3}</div>
                </Tooltip>
              </ToggleButton>
              <ToggleButton size="small" disabled={!cveDetails[V2]} classes={{ root: classes.toggleButton }} value={V2} aria-label="Cvss2">
                <Tooltip title={!!cveDetails[V2] ? '' : CVSS_V2 + TOOLTIP}>
                  <div>{CVSS_V2}</div>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <div className={classes.severityAndMetricsFlexContainer}>
              <div>
                {!!cveDetails[cvssVersion] &&
                  orderedColumns[cvssVersion].map((key, index) => {
                    let baseSeverity = ` ${
                      !!cveDetails[cvssVersion]['base']['baseSeverity']
                        ? cveDetails[cvssVersion]['base']['baseSeverity']
                        : !!cveDetails[cvssVersion]['base']['severity']
                        ? cveDetails[cvssVersion]['base']['severity']
                        : ''
                    }`;
                    return (
                      <Fragment key={`severity-and-metrics-key-container-${index}-${key}`}>
                        <NRKeyValueDataDisplay
                          uniqueKey={`severity-and-metrics-key-value-${index}-${key}`}
                          dataKey={`${!!KEYS[key] ? KEYS[key] : splitCamelCase(key)}`}
                          value={
                            <>
                              {cveDetails[cvssVersion]['base'][key]}
                              {key === 'baseScore' && <span className={classes.baseScore}>{!!baseSeverity ? baseSeverity : ''}</span>}
                            </>
                          }
                          tooltipValue={getTooltipTitle(cveDetails, cvssVersion, key, baseSeverity)}
                          valueMaxWidth={'60%'}
                        />
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.subSectionContainer}>
            <div className={classes.subSectionTitleContainer}>
              <div className={classes.title}>{EPSS_SCORE}</div>
              <Tooltip placement={'right'} title={EPSS_TOOLTIP_INFO}>
                <InfoOutlined className={classes.infoIcon} />
              </Tooltip>
            </div>
            <div>
              <NRKeyValueDataDisplay dataKey={EPSS_SCORE} value={showAsPercentile(cveDetails['exploit']['epss']['score'])} />
              <NRKeyValueDataDisplay dataKey={EPSS_PERCENTILE} value={showAsPercentile(cveDetails['exploit']['epss']['percentile'])} />
            </div>
          </div>
          <div className={classes.subSectionContainer}>
            <div className={classes.subSectionTitleContainer}>
              <div className={classes.title}>{CWE}</div>
              <Tooltip placement={'right'} title={CWE_TOOLTIP_INFO}>
                <InfoOutlined className={classes.infoIcon} />
              </Tooltip>
            </div>
            {!!cveDetails?.problemTypes?.length && (
              <NRShowMore
                maxHeight={300}
                items={cveDetails?.problemTypes?.map((problemType, i) => (
                  <NRLink
                    key={`nr-problem-type-link-${i}-${problemType.name}`}
                    text={!!problemType.name ? `${problemType.name} (${problemType?.value})` : problemType?.value}
                    url={problemType?.url}
                  />
                ))}
              />
            )}
          </div>
          <Divider style={{ marginTop: '24px' }} />
        </>
      )}
      {cveDetailsLoading && (
        <div className={classes.loaderContainer}>
          <NRLoader />
        </div>
      )}
    </div>
  );
};
export default NRSeverityAndMetrics;
