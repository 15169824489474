import { makeStyles } from '@mui/styles';
import NRKeyValueDataDisplay from '../../../../NRKeyValueDataDisplay/NRKeyValueDataDisplay';
import moment from 'moment';
import { CONSTANTS } from '../../../../../constants/constants';
import NRLink from '../../../../NRLink/NRLink';
import KnownExploitInfoStyles from './KnownExploitInfo.styles';
import NRVirtualizedScroll from './KnownExploitInfoCard';
const { MONTH_DAY_YEAR_SHORT } = CONSTANTS.DATE_FORMAT;
const { NAME, DATE_ADDED, REFERENCE } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;
const useStyles = makeStyles(KnownExploitInfoStyles);

function KnownExploitInfo({ data }) {
  const classes = useStyles();

  function Component({ index, style }) {
    const classes = useStyles();
    const { name, date_added, url } = data[index];
    return (
      <div className={classes.itemContainer} key={`known-exploit-${index}`} style={style}>
        <div className={classes.keyValueContainer}>
          <NRKeyValueDataDisplay showTooltip showDash keyWidth={'auto'} minWidth={32} valueMaxWidth={508} dataKey={NAME} value={name} />
          <NRKeyValueDataDisplay
            showDash
            keyWidth={'auto'}
            minWidth={32}
            valueMaxWidth={508}
            dataKey={DATE_ADDED}
            value={moment(date_added).format(MONTH_DAY_YEAR_SHORT)}
          />
          <NRKeyValueDataDisplay showDash keyWidth={'auto'} minWidth={32} valueMaxWidth={508} dataKey={REFERENCE} value={<NRLink url={url} />} />
        </div>
      </div>
    );
  }

  return <div className={classes.mainContainer}>{!!data && <NRVirtualizedScroll list={data} Component={Component} itemHeight={100} />}</div>;
}
export default KnownExploitInfo;
