import Download from 'react-feather/dist/icons/download';
import { GET_SIGNED_URL_DOWNLOAD_FILE } from '../../../graphql/queries/asset';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import CustomError from '../../CustomError/CustomError';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { AssetsContext } from '../../../DataShare/NRAssetsDataShare';
import { CONSTANTS } from '../../../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { client } from '../../../ApolloProviderWithAuth0';
import { isEmpty } from 'lodash';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function FileDownloadComponent({
  componentId,
  filePath,
  fileList,
  fileName,
  cursorPointer,
  disabled,
  tooltipTitle,
  color,
  showButtonComponent = false,
  afterDownloadAction
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { tasks, setTasks } = useContext(AssetsContext);
  const [operationIdIsLoading, setOperationIdIsLoading] = useState();

  const localFileName = !!fileName ? fileName : filePath?.split('/')?.at(-1);

  const handleOnDownload = async fileList => {
    if (disabled) return;
    if (fileList.length) {
      setOperationIdIsLoading(true);
      for (let i = 0; i < fileList.length; i++) {
        try {
          const response = await client.query({
            query: GET_SIGNED_URL_DOWNLOAD_FILE,
            qlObjKey: 'download',
            variables: { args: { componentId, filePath: fileList[i].path } },
            context: { version: 'v3' },
            transformData: data => {
              return data?.file?.operationId;
            }
          });

          if (!isEmpty(response)) {
            let { operationId } = response?.data?.download?.file;
            if (tasks.find(e => e.operationId === operationId && e.status === CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.IN_PROGRESS)) {
              enqueueSnackbar(<CustomError message={CONSTANTS.DOWNLOAD.ALREADY_IN_PROGRESS_MESSAGE} />, {
                variant: 'warning',
                preventDuplicate: true
              });
              return;
            }
            setTasks(prev => [
              {
                operationId: operationId,
                fileName: fileList[i]?.name || fileList[i]?.path.split('/').at(-1),
                status: CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.IN_PROGRESS, //check UPLOAD keyword
                type: CONSTANTS.TASKS.TYPE.DOWNLOAD,
                id: uuidv4()
              },
              ...prev
            ]);
            if (i === fileList.length - 1) {
              setOperationIdIsLoading(false);
              afterDownloadAction && afterDownloadAction();
            }
          }
        } catch (error) {
          if (error && !isEmpty(error)) {
            enqueueSnackbar(<CustomError message={error?.message} />, { variant: 'error' });
          }
          setOperationIdIsLoading(false);
          afterDownloadAction && afterDownloadAction();
        }
      }
    }
  };

  return (
    <>
      {showButtonComponent ? (
        <Tooltip title={tooltipTitle}>
          <IconButton
            aria-label="Download items"
            component="span"
            color="inherit"
            size="small"
            onClick={() => {
              handleOnDownload(!!fileList?.length ? fileList : [{ path: filePath, name: localFileName }]);
            }}
          >
            <Box display="flex">
              {!operationIdIsLoading && (
                <Download
                  style={{
                    cursor: cursorPointer ? 'pointer' : 'default',
                    color: disabled ? theme.palette.text.disabled : !!color ? color : theme.palette.text.icon
                  }}
                  size={20}
                />
              )}
              {!!operationIdIsLoading && <CircularProgress color={'primary'} size={20} />}
              <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
                Download
              </Typography>
            </Box>
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {!!operationIdIsLoading && <CircularProgress color={'primary'} size={20} />}
          {!operationIdIsLoading && (
            <Tooltip title={!!tooltipTitle && !disabled ? tooltipTitle : ''}>
              <Download
                style={{
                  cursor: cursorPointer ? 'pointer' : 'default',
                  color: disabled ? theme.palette.text.disabled : !!color ? color : theme.palette.text.icon
                }}
                size={20}
                onClick={() => handleOnDownload(!!fileList?.length ? fileList : [{ path: filePath, name: localFileName }])}
              />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}

export default FileDownloadComponent;
