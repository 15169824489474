export default theme => ({
  root: {
    color: 'rgba(0, 0, 0, .54)'
  },
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  primaryTrack: {
    backgroundColor: '#C10F1F'
  },
  default: {
    track: { opacity: 0.5 }
  },
  grid: {
    marginLeft: theme.spacing(2)
  },
  labelRoot: {
    whiteSpace: 'nowrap'
  }
});
