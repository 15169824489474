export default () => ({
  headerTitle: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  infoIcon: {
    marginRight: 10,
    fontSize: '1rem'
  },
  sortEnabled: {
    cursor: 'pointer'
  }
});
