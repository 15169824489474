export default theme => ({
  breadcrumbContainer: {
    padding: theme.spacing(1, 1, 0),
    display: 'flex'
  },
  root: {
    marginBottom: theme.NRResponsiveDimensions.breadcrumb.marginBottom,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  link: {
    textDecoration: 'none',
    color: 'unset'
  },
  separator: {
    padding: 5
  },
  lastBreadcrumb: {
    cursor: 'pointer'
  }
});
