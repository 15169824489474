import ReportBreadcrumb from './components/Breadcrums/ReportBreadcrumb/ReportBreadcrumb';
import { HELPER_CONTENT } from './utils/HelperContent.constants';
import { lazy, Suspense } from 'react';
import NRLoadingPage from './pages/NRLoadingPage/NRLoadingPage';

const LazySearch = lazy(() => import('./pages/NRSearch/NRSearchContainer'));
const LazyChangePassword = lazy(() => import('./pages/NRChangePasswordForm/NRChangePasswordForm'));
const LazyAssetTable = lazy(() => import('./pages/NRAssetTable/NRAssetTable'));
const LazyReport = lazy(() => import('./pages/NRReport/NRReport'));
const LazyUserProfile = lazy(() => import('./pages/NRUserProfile/NRUserProfile'));
const LazyUserManagement = lazy(() => import('./pages/NRUserManagement/NRUserManagement'));
const LazyDashboard = lazy(() => import('./pages/NRDashboard/NRDashboard'));
const LazyNotFound = lazy(() => import('./pages/NRNotFound/NRNotFound'));

function ElementWrapper({ element }) {
  return <Suspense fallback={<NRLoadingPage />}>{element}</Suspense>;
}

export default [
  {
    path: '/',
    element: <ElementWrapper element={<LazyDashboard />} />,
    breadcrumb: 'Overview',
    exact: true,
    menu: 'Overview',
    displayHelpDrawer: true,
    helpDrawerContent: HELPER_CONTENT.DASHBOARD
  },
  { path: '/change-password', element: <ElementWrapper element={<LazyChangePassword />} />, breadcrumb: 'Change Password', exact: true },
  {
    path: '/assets',
    element: <ElementWrapper element={<LazyAssetTable />} />,
    breadcrumb: 'Assets',
    exact: true,
    menu: 'Assets',
    helpDrawerContent: HELPER_CONTENT.ASSETS,
    displayHelpDrawer: true
  },
  {
    path: '/assets/:componentId',
    element: <ElementWrapper element={<LazyReport />} />,
    breadcrumb: ReportBreadcrumb,
    matchOptions: { exact: true },
    menu: 'Assets',
    helpDrawerContent: HELPER_CONTENT.REPORT,
    displayHelpDrawer: true
  },
  {
    path: '/profile',
    element: <ElementWrapper element={<LazyUserProfile />} />,
    breadcrumb: 'Profile',
    exact: true,
    helpDrawerContent: HELPER_CONTENT.ORGANIZATION,
    displayHelpDrawer: true
  },
  {
    path: '/settings',
    menu: 'Settings',
    element: <ElementWrapper element={<LazyUserManagement />} />,
    breadcrumb: 'Settings',
    exact: true,
    helpDrawerContent: HELPER_CONTENT.ORGANIZATION,
    displayHelpDrawer: true
  },
  {
    path: '/search',
    element: <ElementWrapper element={<LazySearch />} />,
    breadcrumb: 'Search',
    exact: true,
    menu: 'Search',
    helpDrawerContent: HELPER_CONTENT.SEARCH,
    displayHelpDrawer: true
  },
  {
    path: '*',
    element: <ElementWrapper element={<LazyNotFound />} />,
    breadcrumb: 'Error',
    exact: false,
    menu: 'Error',
    displayHelpDrawer: false,
    key: 'route-not-found'
  }
];
