export default theme => ({
  mainContainer: {
    height: '100%',
    padding: theme.spacing(3),
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      boxShadow: 'unset'
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
      boxShadow: 'unset',
      borderRadius: 10
    },
    msOverflowStyle: 'none' /* IE 11 */,
    scrollbarWidth: 'none' /* Firefox 64 */,
    '&:hover::-webkit-scrollbar-thumb': {
      display: 'unset'
    },
    '&:hover': {
      msOverflowStyle: 'unset' /* IE 11 */,
      scrollbarWidth: 'unset' /* Firefox 64 */
    }
  },
  drawerPaper: {
    overflowY: 'visible',
    minWidth: '350px',
    maxWidth: '35vw'
  },
  helpContent: {
    '&>.markdown-body': {
      paddingBottom: theme.spacing(4),
      color: 'inherit'
    },
    '&>.markdown-body > h1': {
      ...theme.typography.h2
    },
    '&>.markdown-body > h2': {
      ...theme.typography.h3
    },
    '&>.markdown-body > p': {
      ...theme.typography.body1
    },
    '&>.markdown-body a': {
      color: theme.palette.blue.link
    }
  }
});
