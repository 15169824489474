import NRActionModal from '../../NRActionModal/NRActionModal';
import { useContext } from 'react';
import { RemediationContext } from '../NRRemediationContext';
import NRRemediationContent from '../NRRemediationContent/NRRemediationContent';

function NRRemediationModal() {
  const { openRemediationModal, setOpenRemediationModal, CVEs } = useContext(RemediationContext);
  const handleClose = () => {
    setOpenRemediationModal(false);
  };

  return (
    <NRActionModal
      title={`Remediate Issues - ${CVEs.length}`}
      actionLabel={'confirmActionLabel'}
      cancelLabel={'cancelActionLabel'}
      content={<NRRemediationContent />}
      open={openRemediationModal}
      handleClose={handleClose}
      width={500}
    />
  );
}

export default NRRemediationModal;
