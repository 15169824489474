export default theme => ({
  button: {
    padding: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    cursor: 'pointer',
    minWidth: 'unset',
    color: theme.palette.text.icon,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      color: theme.palette.text.secondary
    }
  },
  redHover: {
    '&:hover': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main
    }
  },
  commit: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main)
  },
  cancel: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main)
  }
});
