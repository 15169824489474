import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import NRRemediationStepsStyles from './NRRemediationSteps.styles';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import NRControlledSelect from '../NRControlledSelect/NRControlledSelect';
import { REMEDIATION_CONSTANTS } from './NRRemediation.constants';
import TextField from '@mui/material/TextField';
import RemediationBox from './RemediationBox';
const useStyles = makeStyles(NRRemediationStepsStyles);

export const SHOW_JUSTIFICATION_STATUS = [REMEDIATION_CONSTANTS.STATUS.VALUES.NOT_AFFECTED];
export const SHOW_RESPONSE_STATUS = [REMEDIATION_CONSTANTS.STATUS.VALUES.AFFECTED];

function NRRemediationStepTwo() {
  const classes = useStyles();
  const { control, watch } = useFormContext();
  const form = watch();

  return (
    <Box className={classes.mainBoxStep2}>
      <Box>
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS[form.status]}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES[form.status]}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS[form.status]}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT[form.status]}
          avoidSelection={true}
        />
      </Box>
      <Box className={classes.optionalContainer}>
        <Typography variant={'body2'} sx={{ marginBottom: 2 }}>
          Optional
        </Typography>
        {SHOW_JUSTIFICATION_STATUS.includes(form.status) && (
          <NRControlledSelect
            className={classes.vexJustificationSelect}
            size="small"
            id="remediation-justification"
            name="justification"
            key="justification"
            label={'Justification'}
            required={false}
            displayEmpty={false}
            showEmptyValue={false}
            options={Object.keys(REMEDIATION_CONSTANTS.VEX_JUSTIFICATION).map(key => ({
              value: key,
              label: REMEDIATION_CONSTANTS.VEX_JUSTIFICATION[key]
            }))}
            control={control}
          />
        )}
        {SHOW_RESPONSE_STATUS.includes(form.status) && (
          <NRControlledSelect
            className={classes.vexJustificationSelect}
            size="small"
            id="remediation-response"
            name="response"
            key="response"
            label={'Response'}
            required={false}
            displayEmpty={false}
            showEmptyValue={false}
            options={Object.keys(REMEDIATION_CONSTANTS.VEX_RESPONSES).map(key => ({
              value: key,
              label: REMEDIATION_CONSTANTS.VEX_RESPONSES[key]
            }))}
            control={control}
          />
        )}
        <FormControl fullWidth={true} sx={{ overflowX: 'auto' }}>
          <Controller
            control={control}
            id={'remediation-description'}
            name={'description'}
            required={false}
            render={({ onChange, value }) => (
              <TextField
                multiline={true}
                minRows={10}
                maxRows={10}
                id="table-filters"
                placeholder={'Remediation detail...'}
                value={value}
                color={'secondary'}
                onChange={onChange}
                variant="outlined"
                size="small"
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default NRRemediationStepTwo;
