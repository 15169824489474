import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import NRSummaryActionsStyles from './NRSummaryActions.styles';
import { useContext, useState } from 'react';
import { ComponentContext } from '../../ContextWrappers/NRComponentContextWrapper';
import NRAssetsActions from '../../pages/NRAssetsActions/NRAssetsActions';
const useStyles = makeStyles(NRSummaryActionsStyles);

function NRSummaryActions() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { component } = useContext(ComponentContext);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        id="actions-button"
        aria-controls={open ? 'actions-button-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="secondary"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <NRAssetsActions component={component} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div>
  );
}

export default NRSummaryActions;
