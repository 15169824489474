import makeStyles from '@mui/styles/makeStyles';
import UpperCaseGreyTextStyles from './UpperCaseGreyText.styles';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(UpperCaseGreyTextStyles);

function UpperCaseGreyText({ text }) {
  const classes = useStyles();
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  return (
    <Tooltip title={text} disableHoverListener={!isOverflowed}>
      <span ref={textElementRef} className={classes.text}>
        {text}
      </span>
    </Tooltip>
  );
}

export default UpperCaseGreyText;
