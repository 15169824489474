export default theme => ({
  title: {
    ...theme.typography.body2
  },
  root: {
    padding: 0
  },
  action: {
    marginRight: 0
  },
  mainContainer: {
    width: '100%'
  },
  openOnTitle: {
    cursor: 'pointer'
  },
  expand: {
    color: theme.palette.text.icon,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardContentRoot: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  titleContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0)
  },
  titleExtra: {
    marginLeft: theme.spacing(0.5)
  },
  titleExtraClickable: {
    color: theme.palette.blue.link
  },
  icon: {
    color: theme.palette.text.iconInfo
  }
});
