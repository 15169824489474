import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import CommandComponentStyles from './CommandComponent.styles';

const useStyles = makeStyles(CommandComponentStyles);

const commandComponents = {
  edit: EditIcon,
  commit: SaveIcon,
  cancel: ClearIcon,
  delete: DeleteIcon
};

export const Command = ({ id, onExecute, text }) => {
  const classes = useStyles();
  const CommandIcon = commandComponents[id];
  return (
    <Tooltip title={text} key={`${text}-key`}>
      <Button
        classes={{
          root: clsx(classes.button, { [classes.redHover]: !!classes[id], [classes.commit]: id === 'commit', [classes.cancel]: id === 'cancel' })
        }}
        aria-label={text}
        onClick={onExecute}
      >
        <CommandIcon fontSize="small" />
      </Button>
    </Tooltip>
  );
};
