import { Controller } from 'react-hook-form';
import NRSelect from './NRSelect';

function NRControlledSelect({
  className,
  size,
  id,
  name,
  label,
  variant = 'outlined',
  helperText,
  control,
  rules,
  options,
  required = false,
  error,
  disabled = false,
  emptyValueLabel,
  showEmptyValue,
  displayEmpty
}) {
  return (
    <Controller
      control={control}
      id={id}
      name={name}
      data-testid={'controlledTestIdSelect'}
      rules={rules}
      render={({ onChange, value }) => (
        <NRSelect
          className={className}
          size={size}
          helperText={helperText}
          required={!!rules?.required || !!required}
          error={error}
          variant={variant}
          id={`label-${id}`}
          label={label}
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
          emptyValueLabel={emptyValueLabel}
          showEmptyValue={showEmptyValue}
          displayEmpty={displayEmpty}
        />
      )}
    />
  );
}

export default NRControlledSelect;
