export default theme => ({
  popoverMain: {
    color: theme.palette.backgroundAlt.color,
    backgroundColor: theme.palette.tooltip.background,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius / 2,
    minWidth: 400
  },
  title: {
    marginBottom: theme.spacing(2),
    ...theme.typography.subtitle2
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    ...theme.typography.label,
    color: theme.palette.backgroundAlt.colorSecondary
  },
  topSection: {
    marginBottom: theme.spacing(2)
  },
  topSubSection: {
    display: 'flex'
  },
  createdBy: {
    flexGrow: 1
  },
  endDate: {
    flexGrow: 1
  },
  subSection: {
    marginBottom: theme.spacing(1)
  },
  icon: {
    width: '0.875rem',
    height: '0.875rem',
    marginLeft: 6,
    marginBottom: -2,
    cursor: 'pointer'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    minWidth: '25vw',
    height: '20vh',
    alignItems: 'center'
  }
});
