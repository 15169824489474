export default theme => ({
  avatar: {
    backgroundColor: theme.palette.text.avatarAlt.background,
    color: theme.palette.text.avatarAlt.color
  },
  badge: {
    cursor: 'pointer',
    zIndex: 0
  },
  menu: {
    minWidth: 250
  },
  divider: {
    margin: `${theme.spacing(1)} 0`
  },
  menuLogoutIcon: {
    marginLeft: 'auto'
  },
  link: {
    textDecoration: 'none',
    color: 'unset'
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerProfile: {
    ...theme.typography.body1
  },
  userEmail: {
    color: theme.palette.text.secondary
  }
});
