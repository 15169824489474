import NRMenuItemStyles from './NRMenuItem.styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(NRMenuItemStyles);

function NRMenuItem({ route, text, icon, selected, children }) {
  const classes = useStyles();
  const ThisMenuItem = (
    <ListItemButton classes={{ root: classes.root }} key={text} selected={selected === text}>
      <div className={clsx(classes.container)}>
        <ListItemIcon
          className={clsx({ [classes.selected]: selected === text })}
          classes={{ root: clsx(classes.icon, { [classes.iconColor]: selected !== text }) }}
        >
          {icon}
        </ListItemIcon>
        <span className={classes.itemTitle}>{text}</span>
      </div>
      {children}
    </ListItemButton>
  );

  return (
    <>
      {!!route ? (
        <Link to={route} className={classes.link}>
          {ThisMenuItem}
        </Link>
      ) : (
        <>{ThisMenuItem}</>
      )}
    </>
  );
}

NRMenuItem.propTypes = {
  setMenuOpen: PropTypes.func,
  text: PropTypes.string
};

export default NRMenuItem;
