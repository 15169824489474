export default theme => ({
  infoIcon: {
    marginLeft: 5,
    fontSize: '1rem',
    color: theme.palette.text.icon
  },
  link: {
    '& > a:-webkit-any-link': {
      color: 'unset'
    },
    color: theme.palette.blue.link,
    textDecoration: 'unset',
    cursor: 'pointer'
  }
});
