import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NRRemediationStepsStyles from './NRRemediationSteps.styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import NRCircleWarningIcon from '../../NRIcons/NRCircleWarningIcon';
import { useTheme } from '@mui/material/styles';
import { REMEDIATION_CONSTANTS } from './NRRemediation.constants';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import NRValueTooltipIcon from '../NRReactGridCustomComponents/NRCustomProviders/NRValueTooltipIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemediationBox from './RemediationBox';

const useStyles = makeStyles(NRRemediationStepsStyles);

function NRRemediationStepOne() {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box>
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.UNSPECIFIED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.UNSPECIFIED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.UNSPECIFIED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.UNSPECIFIED}
        />
      </Box>
      <Box className={classes.vexStatuses}>
        <Typography sx={{ paddingRight: 1 }} variant={'body2'}>
          VEX Statuses
        </Typography>
        <NRValueTooltipIcon icon={<InfoOutlinedIcon className={classes.vexTooltipIcon} />} tooltip={REMEDIATION_CONSTANTS.VEX_TOOLTIP} />
      </Box>
      <Box>
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.AFFECTED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.AFFECTED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.AFFECTED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.AFFECTED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.FALSE_POSITIVE}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.FALSE_POSITIVE}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.FALSE_POSITIVE}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.FALSE_POSITIVE}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.UNDER_INVESTIGATION}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.UNDER_INVESTIGATION}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.UNDER_INVESTIGATION}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.UNDER_INVESTIGATION}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.NOT_AFFECTED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.NOT_AFFECTED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.NOT_AFFECTED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.NOT_AFFECTED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.FIXED}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.FIXED}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.FIXED}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.FIXED}
        />
        <RemediationBox
          title={REMEDIATION_CONSTANTS.STATUS.LABELS.RESOLVED_WITH_PEDIGREE}
          optValue={REMEDIATION_CONSTANTS.STATUS.VALUES.RESOLVED_WITH_PEDIGREE}
          subtitle={REMEDIATION_CONSTANTS.STATUS.DESCRIPTIONS.RESOLVED_WITH_PEDIGREE}
          showAlert={REMEDIATION_CONSTANTS.STATUS.SHOW_ALERT.RESOLVED_WITH_PEDIGREE}
        />
      </Box>
    </Box>
  );
}

export default NRRemediationStepOne;
