export default theme => ({
  link: {
    textDecoration: 'none',
    color: 'unset'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '72px',
    justifyContent: 'center'
  },
  selected: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.contrastText,
    '& > .MuiListItemIcon-root': {
      color: theme.palette.secondary.main
    }
  },
  root: {
    '&.Mui-selected': {
      backgroundColor: 'unset'
    },
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)'
    },
    padding: 'unset',
    justifyContent: 'center'
  },
  iconColor: {
    color: theme.palette.text.iconAlt
  },
  icon: {
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5)
  },
  itemTitle: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2)
  }
});
