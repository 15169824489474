import { makeStyles } from '@mui/styles';
import KevInfoStyles from './KEVInfo.styles';
import { CISAKevDetails } from '../../NRCISAKevDetails/NRCISAKevDetails';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles(KevInfoStyles);

function KevInfo({ data }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      {!!data &&
        data.map((each, index) => {
          const isPastDueDate = moment(each?.dateAdded).isBefore(new Date());
          return (
            <div className={classes.itemContainer}>
              <div className={classes.indexContainer}>{index + 1}</div>
              <div className={classes.keyValueContainer}>
                <CISAKevDetails kevData={each} dueDateColor={isPastDueDate ? theme.palette.error.main : null} />
              </div>
            </div>
          );
        })}
    </div>
  );
}
export default KevInfo;
