import CssBaseline from '@mui/material/CssBaseline';
import NRMenuStyles from './NRMenu.styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import NRPendoMarkIcon from '../../components/../NRIcons/NRPendoMarkIcon';
import IconButton from '@mui/material/IconButton';
import NROrgSelector from './NROrgSelector/NROrgSelector';
import NRSideNavbar from './NRSideNavbar/NRSideNavbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const useStyles = makeStyles(NRMenuStyles);

function NRMenu({ children }) {
  const trigger = useScrollTrigger({ threshold: 10, disableHysteresis: true });
  const classes = useStyles();
  return (
    <div className={classes.menu}>
      <CssBaseline enableColorScheme />
      <AppBar position="fixed" color="default" classes={{ root: !trigger ? classes.appBarRoot : {} }}>
        <Toolbar className={classes.appBar}>
          <div className={classes.appBarLayout}>
            <NROrgSelector />
            <div className={classes.pendoIcon}>
              <IconButton size="small" color="inherit">
                <NRPendoMarkIcon />
              </IconButton>
            </div>
            <UserAvatar className={classes.avatar} />
          </div>
        </Toolbar>
      </AppBar>
      <NRSideNavbar classes={classes} />
      <main className={classes.content}>{children}</main>
    </div>
  );
}

export default NRMenu;
