import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import NRNoDataStyles from './NRNoData.styles';
import { FiltersContext } from '../../../pages/NRReportTabs/NRReportFilterContextWrapper';
const useStyles = makeStyles(NRNoDataStyles);

function NRNoData({ noDataTitle }) {
  const { drawerTitle } = useContext(FiltersContext);
  const classes = useStyles();
  return (
    <div className={classes.noData}>
      <span className={classes.noDep}>No {noDataTitle?.toLowerCase()} for</span>
      <span className={classes.drawerTitle}>{drawerTitle}</span>
      <span className={classes.lastPart}>were found.</span>
    </div>
  );
}

export default NRNoData;
