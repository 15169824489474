export default theme => ({
  mainContainer: {
    height: 40,
    background: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 2)
  },
  title: {
    color: theme.palette.secondary.contrastText
  },
  actions: {
    color: theme.palette.secondary.contrastText
  }
});
