import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import NRTaskManagerStyles from './NRTaskManager.styles';
import { useContext, useEffect } from 'react';
import { AssetsContext } from '../../DataShare/NRAssetsDataShare';
import { useState } from 'react';
import NRTaskProgressContainer from '../NRTaskProgress/NRTaskProgressContainer';
import { CONSTANTS } from '../../constants/constants';
import NRActionModal from '../NRActionModal/NRActionModal';
import useExitPrompt from '../../hooks/UseExitPrompt';
import clsx from 'clsx';
const { IN_PROGRESS } = CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS;
const { CONFIRMATION_MODAL } = CONSTANTS.TRANSACTION_ASSET;

const useStyles = makeStyles(NRTaskManagerStyles);

function NRTaskManager({}) {
  const classes = useStyles();
  const [showTasks, setShowTasks] = useState(true);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [finishedCount, setFinishedCount] = useState(0);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const { tasks, setTasks } = useContext(AssetsContext);

  useEffect(() => {
    let inProgress = 0;
    let finished = 0;
    tasks.forEach(item => {
      if (item.status === IN_PROGRESS) {
        inProgress += 1;
      } else {
        finished += 1;
      }
    });

    setInProgressCount(inProgress);
    setFinishedCount(finished);
  }, [tasks]);

  useEffect(() => {
    if (inProgressCount) {
      setShowExitPrompt(true);
    } else {
      setShowExitPrompt(false);
    }
  }, [inProgressCount]);

  function titleFormatter(inProgressCount, finishedCount) {
    if (inProgressCount > 0 && finishedCount === 0) {
      return `${inProgressCount} item${inProgressCount === 1 ? '' : 's'} transferring`;
    } else if (finishedCount > 0 && inProgressCount === 0) {
      return `${finishedCount} item${finishedCount === 1 ? '' : 's'} finished`;
    } else if (inProgressCount > 0 && finishedCount > 0) {
      return `${finishedCount} of ${finishedCount + inProgressCount} items finished`;
    }
  }

  const [confirmationModalTitle, setConfirmationModalTitle] = useState();
  const [confirmActionLabel, setConfirmActionLabel] = useState();
  const [cancelActionLabel, setCancelActionLabel] = useState();
  const [actionModalContent, setActionModalContent] = useState();

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const continueUploads = () => {
    setOpenModal(false);
  };

  const cancelUploads = () => {
    tasks.forEach(transaction => {
      if (transaction.xhr) transaction.xhr.abort();
    });
    setOpenModal(false);
    setTasks([]);
  };

  useEffect(() => {
    let modifier = inProgressCount > 1 ? 'P' : 'S';

    setConfirmationModalTitle(CONFIRMATION_MODAL[`TITLE_${modifier}`]);
    setConfirmActionLabel(CONFIRMATION_MODAL[`CONTINUE_${modifier}`]);
    setCancelActionLabel(CONFIRMATION_MODAL[`CANCEL_${modifier}`]);
    setActionModalContent(CONFIRMATION_MODAL[`MESSAGE_${modifier}`]);
  }, [openModal]);

  const handleCloseTrayAction = () => {
    if (inProgressCount > 0) {
      setOpenModal(true);
    } else {
      setTasks([]);
    }
  };

  return (
    <>
      <NRActionModal
        title={confirmationModalTitle}
        actionLabel={cancelActionLabel}
        cancelLabel={confirmActionLabel}
        content={actionModalContent}
        open={openModal}
        handleClose={handleClose}
        handleCancelAction={continueUploads}
        handleConfirmAction={cancelUploads}
        actionButtonProps={{ variant: 'contained', color: 'secondary' }}
        cancelButtonProps={{ variant: 'outlined', color: 'secondary' }}
        width={410}
      />
      {!!tasks?.length && (
        <Card classes={{ root: classes.card }} elevation={4}>
          <CardHeader
            classes={{ root: classes.cardHeader, title: classes.title, action: classes.action }}
            title={<div>{titleFormatter(inProgressCount, finishedCount)}</div>}
            action={
              <>
                <IconButton onClick={() => setShowTasks(prev => !prev)} size="small">
                  <KeyboardArrowDownIcon className={clsx(classes.arrowIconStyle, { [classes.collapsedTrayStyle]: !showTasks })} />
                </IconButton>
                <IconButton onClick={() => handleCloseTrayAction()} size="small">
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
          <Collapse in={showTasks}>
            <CardContent classes={{ root: classes.cardContent }}>
              <NRTaskProgressContainer />
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
}
export default NRTaskManager;
