import { createContext, useEffect, useState } from 'react';
import NRRemediationModal from './NRRemediationModal/NRRemediationModal';

export const RemediationContext = createContext({
  openRemediationModal: null,
  setOpenRemediationModal: () => {},
  showOriginalState: null,
  setShowOriginalState: () => {},
  CVEs: null,
  setCVEs: () => {},
  vulnerabilities: null,
  setVulnerabilities: () => {},
  lastQueryVariables: null,
  setLastQueryVariables: () => {}
});

function NRRemediationContextWrapper({ children }) {
  const [openRemediationModal, setOpenRemediationModal] = useState(false);
  const [showOriginalState, setShowOriginalState] = useState(false);
  const [CVEs, setCVEs] = useState([]);
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [lastQueryVariables, setLastQueryVariables] = useState({});

  useEffect(() => {
    if (!openRemediationModal) {
      setCVEs([]);
    }
  }, [openRemediationModal]);

  const defaultValues = {
    openRemediationModal,
    setOpenRemediationModal,
    showOriginalState,
    setShowOriginalState,
    CVEs,
    setCVEs,
    vulnerabilities,
    setVulnerabilities,
    lastQueryVariables,
    setLastQueryVariables
  };

  return (
    <RemediationContext.Provider value={defaultValues}>
      <NRRemediationModal />
      {children}
    </RemediationContext.Provider>
  );
}

export default NRRemediationContextWrapper;
