export default theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1
  },
  totalCount: {
    cursor: 'pointer',
    color: theme.palette.blue.link
  }
});
