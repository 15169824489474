export default theme => ({
  container: {
    marginLeft: theme.spacing(2),
    alignSelf: 'center',
    marginTop: '-10px'
  },
  button: {
    textTransform: 'unset',
    fontWeight: 600
  }
});
