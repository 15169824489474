export default theme => ({
  title: {
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3)
  },
  card: {
    position: 'fixed',
    bottom: 0,
    right: 112,
    zIndex: 2000,
    width: 360,
    maxHeight: '50vh',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px'
  },
  cardHeader: {
    padding: theme.spacing(2.5, 3),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main)
  },
  action: {
    '& > button': {
      color: theme.palette.getContrastText(theme.palette.secondary.main)
    }
  },
  cardContent: {
    padding: theme.spacing(0),
    maxHeight: 'calc(50vh - 66px)',
    overflow: 'auto',
    '&:last-child': {
      paddingBottom: theme.spacing(1.5)
    }
  },
  arrowIconStyle: {
    transition: `transform ${theme.transitions.duration.standard}ms`
  },
  collapsedTrayStyle: {
    transform: 'rotate(180deg)'
  }
});
