import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { CONSTANTS } from '../../../constants/constants';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  title: { ...theme.typography.label, color: theme.palette.text.secondary }
}));

const HeaderCellComponent = ({ ...restProps }) => {
  const classes = useStyles();
  return (
    <TableEditColumn.Cell {...restProps} style={{ padding: ' 0 16px' }}>
      <span className={classes.title}>{CONSTANTS.TABLE.ACTIONS}</span>
    </TableEditColumn.Cell>
  );
};
export default HeaderCellComponent;
