import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import makeStyles from '@mui/styles/makeStyles';
import NRTaskProgressStyles from './NRTaskProgress.styles';
import { AssetsContext } from '../../DataShare/NRAssetsDataShare';
import { CONSTANTS } from '../../constants/constants';
const { CREATED, ERROR, IN_PROGRESS, SUCCESS, ABORTED } = CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS;
import clsx from 'clsx';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
import { GET_OPERATION } from '../../graphql/queries/asset';
import { Download } from 'react-feather';
import CustomError from '../CustomError/CustomError';
import { SBOM_CONSTANTS } from '../SBOM/SBOM.constants';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NRActionModal from '../NRActionModal/NRActionModal';
const { TITLE_S, CANCEL_S, CONTINUE_S, MESSAGE_S } = CONSTANTS.TRANSACTION_ASSET.CONFIRMATION_MODAL;

const useStyles = makeStyles(NRTaskProgressStyles);

function StatusIcon({ status, download = false }) {
  const classes = useStyles();

  switch (status) {
    case CREATED:
      return <FileUploadOutlinedIcon />;
    case IN_PROGRESS:
      return download ? <Download className={classes.downloadIcon} /> : <FileUploadOutlinedIcon />;
    case ERROR:
      return <CancelOutlinedIcon className={classes.errorIconStyles} />;
    case ABORTED:
      return <CancelOutlinedIcon className={classes.errorIconStyles} />;
    case SUCCESS:
      return <CheckCircleOutlineOutlinedIcon className={classes.successIconStyles} />;
    default:
      return <FileDownloadOutlinedIcon />;
  }
}

function NRTaskIndeterminateProgress({ task }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { updateStatus, setTasks, tasks } = useContext(AssetsContext);
  const [time, setTime] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [operation, isOperationLoading, operationCalled, getOperation] = useNRLazyQuery({
    query: GET_OPERATION,
    qlObjKey: 'operation',
    options: { fetchPolicy: 'network-only', context: { version: 'v3' } }
  });

  useEffect(() => {
    return () => {
      clearInterval(time);
    };
  }, []);

  useEffect(() => {
    if (task?.operationId) {
      getOperation({ variables: { args: { operationId: task?.operationId } } });
    }
  }, [task]);

  const addRequestRef = xhr => {
    const newTasks = [...tasks];
    const currentTaskIndex = tasks.findIndex(e => e.uploadUrl === task.uploadUrl);
    newTasks[currentTaskIndex].xhr = xhr;
    setTasks(newTasks);
  };

  const downloadFile = url => {
    const xhr = new XMLHttpRequest();
    addRequestRef(xhr);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 0) {
          updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.ABORTED);
          return;
        }
        if (xhr.status >= 400) {
          throw new Error(xhr.statusText);
        }
        if (xhr.status >= 200 && xhr.status <= 299) {
          const blob = xhr.response;
          updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.SUCCESS);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = task.fileName;
          link.click();
          enqueueSnackbar(`${task.fileName} ${SBOM_CONSTANTS.DOWNLOAD.SUCCESS_MESSAGE}`, {
            variant: 'success',
            preventDuplicate: true
          });
        }
      }
    };

    xhr.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setDownloadProgress(percentComplete);
      }
    };

    xhr.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setDownloadProgress(percentComplete);
      }
    };

    xhr.onerror = e => {
      enqueueSnackbar(<CustomError message={CONSTANTS.TABLE.SBOM_TABLE.ERRORS.DOWNLOAD_SBOM} />, { variant: 'error', preventDuplicate: true });
    };

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send('');
  };

  useEffect(() => {
    if (operationCalled && !!operation?.operationId) {
      if (operation?.error) {
        updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.ERROR);
        clearTimeout(time);
        enqueueSnackbar(<CustomError message={`${operation?.error}`} />, {
          variant: 'error'
        });
      } else {
        if (!!operation?.done) {
          if (operation?.signedUrl?.url && task.status !== CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.SUCCESS) {
            downloadFile(operation.signedUrl.url);
            clearTimeout(time);
          }
        } else if (!time) {
          const interval = setInterval(() => {
            getOperation({ variables: { args: { operationId: task?.operationId } } });
          }, 3000);
          setTime(interval);
        }
      }
    }
  }, [operation]);

  const updateStatusLocal = status => {
    updateStatus(status, null, task.operationId);
  };

  const abort = () => {
    if (!!task.xhr) {
      task.xhr.abort();
    }
    if (time) {
      clearTimeout(time);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const continueUpload = () => {
    setOpenModal(false);
  };

  const cancelUpload = () => {
    abort();
    updateStatusLocal(CONSTANTS.TRANSACTION_ASSET.LOCAL_STATUS.ABORTED);
    setOpenModal(false);
  };

  return (
    <>
      <NRActionModal
        title={TITLE_S}
        actionLabel={CANCEL_S}
        cancelLabel={CONTINUE_S}
        content={MESSAGE_S}
        open={openModal}
        handleClose={handleClose}
        handleCancelAction={continueUpload}
        handleConfirmAction={cancelUpload}
        actionButtonProps={{ variant: 'contained', color: 'secondary' }}
        cancelButtonProps={{ variant: 'outlined', color: 'secondary' }}
        width={410}
      />
      <div className={classes.masterDiv} id={`${task.id}-container`}>
        <div className={classes.container}>
          <div className={classes.uploadDataContainer}>
            <div className={classes.statusIcon}>
              <StatusIcon status={task?.status} download={true} />
            </div>
            <div
              className={clsx({
                [classes.assetDataContainer]: task?.status === IN_PROGRESS,
                [classes.assetDataContainerNoAction]: task?.status !== IN_PROGRESS
              })}
            >
              <div className={classes.nameContainer}>
                <div className={classes.name}>{task?.fileName}</div>
              </div>
              {task?.status === SUCCESS && <p className={classes.remainingTime}>Downloaded via browser</p>}
              {task?.status === IN_PROGRESS && !operation?.signedUrl?.url && <p className={classes.remainingTime}>Generating file for download...</p>}
              {task?.status === IN_PROGRESS && operation?.signedUrl?.url && (
                <p className={classes.remainingTime}>Downloading file {downloadProgress.toFixed(0)} %</p>
              )}
              {task?.status === ERROR && <p className={classes.remainingTime}>Failed</p>}
              {task?.status === ABORTED && <p className={classes.remainingTime}>Canceled</p>}
            </div>
            {task?.status === IN_PROGRESS && (
              <div className={classes.uploadActionContainer}>
                <div className={classes.buttonContainer}>
                  <IconButton aria-label="Close" onClick={() => setOpenModal(true)} size="small">
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
        {task?.status === IN_PROGRESS && !operation?.signedUrl?.url && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="indeterminate"
              classes={{ colorPrimary: classes.colorPrimaryProgressBar, barColorPrimary: classes.barColorPrimaryProgressBar }}
            />
          </Box>
        )}
        {task?.status === IN_PROGRESS && operation?.signedUrl?.url && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={downloadProgress}
              classes={{ colorPrimary: classes.colorPrimaryProgressBar, barColorPrimary: classes.barColorPrimaryProgressBar }}
            />
          </Box>
        )}
        {task?.status === IN_PROGRESS && operation?.signedUrl?.url && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={downloadProgress}
              classes={{ colorPrimary: classes.colorPrimaryProgressBar, barColorPrimary: classes.barColorPrimaryProgressBar }}
            />
          </Box>
        )}
      </div>
    </>
  );
}

export default NRTaskIndeterminateProgress;
