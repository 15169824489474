import MenuItem from '@mui/material/MenuItem';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import User from 'react-feather/dist/icons/user';
import makeStyles from '@mui/styles/makeStyles';
import UserAvatarStyles from './UserAvatar.styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import LogOut from 'react-feather/dist/icons/log-out';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApolloClient } from '@apollo/client';
import { CONSTANTS } from '../../constants/constants';

const useStyles = makeStyles(UserAvatarStyles);

function UserAvatar({ ...props }) {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const logoutUrl = process.env.REACT_APP_AUTH0_REDIRECT_URL;

  const classes = useStyles();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [userObj, setUserObj] = useState(user || null);
  const client = useApolloClient();

  const handleToggle = () => {
    setOpen(!open);
  };

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      setUserObj(user);
    }
  }, [user, isLoading, isAuthenticated]);

  function handleMenuItemClick(event, path) {
    navigate(path);
    handleClose(event);
  }

  return (
    <div {...props}>
      <Badge
        classes={{ root: classes.badge }}
        badgeContent={null}
        overlap="circular"
        color="primary"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Avatar
          ref={anchorRef}
          aria-label="current user account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.avatar}
          variant="rounded" 
        >
          <User />
        </Avatar>
      </Badge>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper classes={{ root: classes.menu }} elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="avatar-menu-list-grow">
                  <MenuItem onClick={e => handleMenuItemClick(e, '/profile')}>
                    <div className={classes.userContainer}>
                      <span className={classes.headerProfile}>{CONSTANTS.ORGANIZATION_TEXT.MY_PROFILE}</span>
                      <span className={classes.userEmail}>{userObj?.email}</span>
                    </div>
                  </MenuItem>
                  <Divider classes={{ root: classes.divider }} />
                  <MenuItem
                    onClick={() => {
                      client.clearStore();
                      !!user &&
                        logout({
                          returnTo: logoutUrl
                        });
                    }}
                  >
                    Log Out
                    <LogOut className={classes.menuLogoutIcon} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
export default UserAvatar;
