import NRHelpDrawerKnobStyles from './NRHelpDrawerKnob.styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(NRHelpDrawerKnobStyles);

function NRHelpDrawerKnob({ label = '?', top, onclick, isOpen }) {
  const classes = useStyles({ top });
  return (
    <Button color="inherit" classes={{colorInherit: classes.button}} onClick={() => onclick(!isOpen)} className={clsx(classes.base, { [classes.open]: isOpen, [classes.closed]: !isOpen })}>
      {label}
    </Button>
  );
}
export default NRHelpDrawerKnob;
