import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faList, faRadiation, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import ConsumerServiceStyles from './ConsumerServiceIcon.styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import AllOutIcon from '@mui/icons-material/AllOut';
import { CONSUMER_SERVICE } from './ConsumerServiceConstants';
const useStyles = makeStyles(ConsumerServiceStyles);

export default function ConsumerServiceIcon({ serviceName }) {
  const classes = useStyles();
  switch (serviceName) {
    case CONSUMER_SERVICE.CVE:
      return (
        <Tooltip title={CONSUMER_SERVICE.CVE_LABEL}>
          <span className={clsx(classes.roundedIcon, classes.iconRed, classes.margin)}>
            <FontAwesomeIcon icon={faRadiation} style={{ width: 16 }} />
          </span>
        </Tooltip>
      );
    case CONSUMER_SERVICE.EXTRACT:
      return (
        <Tooltip title={CONSUMER_SERVICE.EXTRACT_LABEL}>
          <span className={clsx(classes.roundedIcon, classes.iconRed, classes.margin, classes.materialIcon)}>
            <AllOutIcon style={{ verticalAlign: '-0.125em', width: '16px', height: '18px' }} />
          </span>
        </Tooltip>
      );
    case CONSUMER_SERVICE.SBOM:
      return (
        <Tooltip title={CONSUMER_SERVICE.SBOM_LABEL}>
          <span className={clsx(classes.roundedIcon, classes.iconGray, classes.margin)}>
            <FontAwesomeIcon icon={faList} style={{ width: 16 }} />
          </span>
        </Tooltip>
      );
    case CONSUMER_SERVICE.MACHINE_LEARNING:
      return (
        <Tooltip title={CONSUMER_SERVICE.MACHINE_LEARNING_LABEL}>
          <span className={clsx(classes.roundedIcon, classes.iconBlue, classes.margin)}>
            <FontAwesomeIcon icon={faBrain} style={{ width: 16 }} />
          </span>
        </Tooltip>
      );
    case CONSUMER_SERVICE.PARSER:
      return (
        <Tooltip title={CONSUMER_SERVICE.PARSER_LABEL}>
          <span className={clsx(classes.roundedIcon, classes.iconYellow, classes.margin)}>
            <FontAwesomeIcon icon={faFingerprint} style={{ width: 16 }} />
          </span>
        </Tooltip>
      );
    default:
      return null;
  }
}
