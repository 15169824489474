import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import NRSwitchStyles from './NRSwitch.styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(NRSwitchStyles);

function NRSwitch({
  className,
  size = 'medium',
  value,
  onChange,
  color = 'secondary',
  label,
  labelPlacement = 'start',
  error,
  disabled,
  helperText,
  values,
  noWrapLabel = false
}) {
  const classes = useStyles();
  return (
    <FormControl className={className} classes={{ root: classes.root }} size={size} disabled={disabled} error={error}>
      <FormGroup aria-label={`switch-${label}`} row>
        <FormControlLabel
          classes={{ root: noWrapLabel && classes.labelRoot }}
          control={
            !values ? (
              <Switch size={size} value={value} onChange={onChange} color={color} checked={value} />
            ) : (
              <Grid classes={{ root: classes.grid }} component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body1">{values[0]}</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    classes={{ switchBase: classes[color], track: classes[`${color}Track`] }}
                    size={size}
                    checked={value}
                    onChange={onChange}
                    value={value}
                    color={color}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">{values[1]}</Typography>
                </Grid>
              </Grid>
            )
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default NRSwitch;
