export default theme => ({
  mainContainer: {
    padding: theme.spacing(1.5, 0, 0)
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex'
  },
  title: {
    margin: theme.spacing(0, 0, 1.5, 0),
    ...theme.typography.subtitle1
  },
  icon: {
    color: theme.palette.text.iconInfo,
    margin: theme.spacing(0, 0, 0, 0.5),
    fontSize: theme.spacing(2.5)
  },
  divider: {
    marginTop: theme.spacing(3)
  }
});
