function NRRemediateIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6801 7.75999C13.6601 8.73999 13.9201 10.16 13.4901 11.38L16.9001 14.79C17.1001 14.99 17.1001 15.3 16.9001 15.5L15.5001 16.9C15.3001 17.1 14.9901 17.1 14.7901 16.9L11.3801 13.49C10.1601 13.92 8.74008 13.66 7.76008 12.68C6.65008 11.57 6.46008 9.89999 7.17008 8.57999L9.52008 10.93L10.9301 9.51999L8.58008 7.16999C9.89008 6.45999 11.5701 6.64999 12.6801 7.75999Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
        fill={color}
      />
    </svg>
  );
}
export default NRRemediateIcon;
