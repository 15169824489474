import makeStyles from '@mui/styles/makeStyles';
import { getSeverityColor } from '../utils/palette.utils';

function NRSeverityComponent({ value }) {
  const classes = makeStyles(theme => ({
    severityContainer: {
      borderLeft: `3px solid ${getSeverityColor(value, theme)}`,
      paddingLeft: theme.spacing(1),
      textTransform: 'uppercase'
    }
  }))();

  return <div className={classes.severityContainer}>{value}</div>;
}
export default NRSeverityComponent;
