import { alpha } from '@mui/material';

export default theme => ({
  base: {
    minWidth: 'auto',
    borderTopLeftRadius: 20,
    zIndex: 501,
    top: props => props.top,
    borderBottomLeftRadius: 20,
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  },
  closed: {
    position: 'fixed',
    right: 0,
    border: `solid 1px ${alpha(theme.palette.neutral[200], 0.4)}`,
    '&:hover': {
      border: `solid 1px ${theme.palette.neutral[200]}`
    }
  },
  open: {
    position: 'absolute',
    left: -30
  },
  button: {
    color: theme.palette.text.icon
  }
});
