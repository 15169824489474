import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    color: alpha(theme.palette.secondary.dark, 0.54),
    '&>svg': {
      marginTop: -2
    }
  }
}));

function ToggleComponent({ buttonRef, ...props }) {
  const classes = useStyles();

  return (
    <div id="toggle-component" className={classes.mainContainer} ref={buttonRef} onClick={props.onToggle} {...props}>
      {props.children}
    </div>
  );
}
export default ToggleComponent;
