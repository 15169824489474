function NRKEVTwoToneIcon({ height = '48', width = '48', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.25" d="M7.22222 4H15.5L18 6L21 6.5L22 8.5L20.5 12L18.5 10.5L16 12L16.5 15H6L7.5 12H4L6.5 8L3.5 4H7.22222Z" fill={color} />
      <path
        d="M15.2125 3C16.0335 3 16.6501 3.55771 16.9864 4.00611L16.9956 4.01839L17.6796 4.99134H18.2757L18.2998 4.99042C18.3308 4.98939 18.3734 4.98831 18.4263 4.98776C18.5318 4.98666 18.679 4.98769 18.8555 4.99577C19.2043 5.01173 19.6869 5.05605 20.195 5.1743C21.1232 5.39034 22.5886 5.97151 22.9113 7.5621C23.0529 8.22312 23.007 8.9412 22.8989 9.59092C22.7884 10.2553 22.6002 10.9197 22.4054 11.4994C21.5297 13.0989 20.4369 13.4996 20 13.5L19.4402 12.417C19.0007 11.659 18.396 11.548 17.9537 11.725L17.9389 11.7309L17.924 11.7363C17.6 11.8542 17.3261 12.0833 17.1191 12.3182C17.0983 12.3418 17.0786 12.365 17.06 12.3876C16.8733 12.5742 17 12.9475 17 12.9475L17.2889 14.1334L19.1454 19.1333L21.0515 14H23L20.2751 20.982H18.0044L15.3502 14.1813C15.1551 13.7278 14.931 13.1933 14.7691 12.7201C14.6712 12.4484 14.7489 12.1268 14.8 12C14.9587 11.7048 15.1676 11.482 15.3875 11.2476C15.4638 11.1662 15.5414 11.0834 15.6188 10.9957C15.9533 10.6162 16.4861 10.1349 17.2257 9.86218C18.4273 9.3883 19.6889 9.72254 20.5833 10.637C20.727 10.1846 20.8513 9.71226 20.9261 9.26271C21.0154 8.72579 21.0223 8.28888 20.955 7.97796L20.9533 7.97015L20.9517 7.96232C20.8814 7.61095 20.5323 7.30626 19.7416 7.12224C19.3894 7.04027 19.036 7.00612 18.764 6.99368C18.6301 6.98755 18.5206 6.98689 18.4471 6.98765C18.4104 6.98803 18.3829 6.98876 18.3662 6.98932L18.3493 6.98996L18.3479 6.99001L18.3231 6.99134H16.6409L15.3784 5.19565C15.3077 5.10354 15.2393 5.04305 15.1906 5.01141C15.1831 5.00651 15.1768 5.00278 15.1718 5H5.5L8.50002 8L6.00002 11H9.70002L6.6043 15.591L5.3725 17.5082L7.69917 20.982H5.65756L3.85369 18.2306H2.88752V20.982H1.00002V14H2.88752V16.8064H3.85198L5.58736 14H5.58967L6.20002 13H2.00002L5.5 8L0.962646 3H15.2125Z"
        fill={color}
      />
      <path
        d="M9.92607 14.0756C10.1609 14.0253 10.3925 14 10.6206 14C11.1921 14 11.6954 14.0061 12.1305 14.0184C12.5722 14.0306 12.9685 14.0459 13.3194 14.0643C13.6705 14.0766 13.9916 14.095 14.2825 14.1196L14.373 14.1272V15.4517H11.123C10.8983 15.4517 10.7389 15.4956 10.6327 15.5709L10.6292 15.5734C10.5333 15.643 10.4798 15.7501 10.4798 15.9135V16.7994L13.825 16.8728V18.1368L10.4798 18.2101V19.0501C10.4798 19.1957 10.5074 19.299 10.5526 19.3691C10.6081 19.4372 10.6779 19.486 10.7642 19.5163C10.8686 19.5439 10.9767 19.5578 11.0887 19.5578H14.373V20.8817L14.2832 20.8899C13.9465 20.9206 13.572 20.9452 13.1598 20.9636C12.3426 20.9865 10.5879 21.0222 10.1067 20.9813C9.86056 20.9557 9.62248 20.8918 9.39277 20.79L9.39158 20.7895C9.1626 20.6844 8.97083 20.5267 8.81679 20.3183L8.81454 20.3151C8.66283 20.0917 8.59229 19.7952 8.59229 19.4353V15.6476C8.59229 15.3361 8.65161 15.0679 8.7761 14.8479C8.89807 14.6324 9.06183 14.4609 9.26709 14.3356C9.46736 14.2133 9.68724 14.1268 9.92607 14.0756Z"
        fill={color}
      />
      <path d="M9 7C11.4835 7 13.5107 6.93361 16 7L14.0113 9.49999C12.205 8.46764 10.9665 7.71404 9 7Z" fill={color} />
    </svg>
  );
}

export default NRKEVTwoToneIcon;
