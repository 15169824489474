import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = theme => ({
  pager: {
    '& div:nth-of-type(2) .Pagination-button': {
      display: 'none'
    }
  }
});

const MyPager = ({ classes, ...restProps }) => {
  return <PagingPanel.Container {...restProps} className={`${classes.pager} custom-pager`} />;
};

export const CursorPagerComponent = withStyles(styles, { name: 'MyPager' })(MyPager);
