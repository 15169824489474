import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

/**
 *
 * @param values array of obj = { value: string, title: string }
 * @param onChange
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export default function NRMultipleSelectCheckmarks({ values, onChange, value = [] }) {
  const renderSelectedValues = selected => {
    // Selected is an array of VALUES
    // I need to show a string of TITLES
    let toShow = [];
    selected.map(each => {
      const found = values.find(e => e?.value === each);
      toShow.push(found?.title);
    });
    return toShow.join(', ');
  };

  return (
    <div>
      <FormControl sx={{ width: 'calc(100% - 16px)' }} size="small">
        <Select
          id="multiple-checkbox"
          SelectDisplayProps={{ style: { paddingTop: 3, paddingBottom: 3 } }}
          multiple
          color={'secondary'}
          value={value}
          onChange={onChange}
          input={<OutlinedInput />}
          renderValue={renderSelectedValues}
          sx={{ height: 32 }}
        >
          {values.map(({ value: val, title }) => (
            <MenuItem key={val} value={val}>
              <Checkbox checked={!!value && value.indexOf(val) > -1} />
              <ListItemText primary={title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
