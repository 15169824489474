import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { CellComponent } from '../CellComponent/CellComponent';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

function EditRowCustomCellComponent({ ...props }) {
  const useStyles = makeStyles({
    underline: { '& > div::before': { borderBottom: '1px dashed red' } }
  });

  const classes = useStyles();
  const { EditFormatter, disableEdit } = props.column;

  if (!!EditFormatter) {
    return (
      <CellComponent {...props}>
        <EditFormatter isEditing={true} {...props} />
      </CellComponent>
    );
  }
  if (disableEdit) {
    return <CellComponent {...props} />;
  }
  return (
    <TableEditRow.Cell
      {...props}
      style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding: '10px' }}
      className={clsx(props.className, classes.underline)}
    />
  );
}
export default EditRowCustomCellComponent;
