export default theme => ({
  descriptionContainer: {
    padding: theme.spacing(1.5, 0, 1.5)
  },
  componentContainer: {
    padding: theme.spacing(3, 0, 1.5),
    display: 'flex'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
  },
  title: {
    ...theme.typography.subtitle1,
    marginBottom: theme.spacing(1)
  },
  description: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(3)
  },
  data20: {
    width: '20%'
  },
  data40: {
    width: '40%'
  }
});
