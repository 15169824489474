import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import StickyNoteIcon from '../../../../NRIcons/StickyNoteIcon';
import { useTheme } from '@mui/material/styles';
import { CONSTANTS } from '../../../../constants/constants';
import moment from 'moment';
import { REMEDIATION_CONSTANTS } from '../../../NRRemediation/NRRemediation.constants';

export function RemediationDetailsFormatter({ value, column, row }) {
  const theme = useTheme();
  const { HEADER } = CONSTANTS.DATE_FORMAT;

  const { status, justification, description, createdAt, author, response } = row?.currentRemediation || {};

  const { UNSPECIFIED } = REMEDIATION_CONSTANTS.STATUS.VALUES;
  const { VEX_JUSTIFICATION, VEX_RESPONSES } = REMEDIATION_CONSTANTS;

  const TooltipContent = () => (
    <Box m={0.5}>
      {!!justification && (
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} children={'Justification'} />
          <Typography variant="caption" children={VEX_JUSTIFICATION[justification]} />
        </Box>
      )}
      {!!response && (
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} children={'Response'} />
          <Typography variant="caption" children={VEX_RESPONSES[response]} />
        </Box>
      )}
      {!!description && (
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} children={'Detail'} />
          <Typography variant="caption" children={description} />
        </Box>
      )}
      {!!createdAt && (
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} children={'Updated On'} />
          <Typography variant="caption" children={moment(createdAt).format(HEADER)} />
        </Box>
      )}
      {!!author && (
        <Box display="flex" flexDirection="column">
          <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} children={'Updated By'} />
          <Typography variant="caption" children={author} />
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {!!status && status !== UNSPECIFIED && (
        <Tooltip placement="left" title={!!status || !!justification || !!description || !!updatedAt || !!author ? <TooltipContent /> : null}>
          <Box display={'flex'} alignItems={'center'} ml={theme.spacing(1)}>
            <StickyNoteIcon color={theme.palette.text.iconInfo} fillOpacity={1} />
          </Box>
        </Tooltip>
      )}
    </>
  );
}

export const RemediationDetailsProvider = props => <DataTypeProvider formatterComponent={RemediationDetailsFormatter} {...props} />;
