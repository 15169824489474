import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NRCircleWarningIcon from '../../../NRIcons/NRCircleWarningIcon';
import React from 'react';
import { useTheme } from '@mui/material/styles';

function NRRemediationAlert() {
  const theme = useTheme();
  return (
    <Box
      mt={2}
      sx={{ backgroundColor: theme.palette.info.background, border: `1px solid ${theme.palette.info.main}`, borderRadius: '4px' }}
      p={1}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box sx={{ padding: 1 }} display={'flex'}>
        <NRCircleWarningIcon color={theme.palette.info.main} width={'24'} height={'24'} />
      </Box>
      <Box ml={1}>
        <Typography variant={'body2'}>
          The selected vulnerabilities currently do not have the same VEX Status. This operation will set them to a new status.
        </Typography>
      </Box>
    </Box>
  );
}

export default NRRemediationAlert;
