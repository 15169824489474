import { alpha } from '@mui/material/styles';

export default theme => ({
  masterDiv: {
    padding: theme.spacing(1.5, 0, 0.5, 0)
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  statusIcon: {
    padding: theme.spacing(1)
  },
  remainingTime: {
    opacity: 0.6,
    fontWeight: 400,
    fontSize: 14,
    cursor: 'default',
    paddingBottom: theme.spacing(1)
  },
  nameContainer: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingBottom: theme.spacing(0.5)
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  successIconStyles: {
    color: theme.palette.green[500]
  },
  errorIconStyles: {
    color: theme.palette.red[500]
  },
  uploadDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%;'
  },
  assetDataContainer: {
    width: 'calc(100% - 40px - 46px)'
  },
  assetDataContainerNoAction: {
    width: 'calc(100% - 46px)'
  },
  uploadActionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    paddingRight: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  assetLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'unset',
    fontWeight: 400,
    fontSize: 14
  },
  colorPrimaryProgressBar: {
    backgroundColor: alpha(theme.palette.info.main, 0.15)
  },
  barColorPrimaryProgressBar: {
    backgroundColor: theme.palette.info.main
  }
});
