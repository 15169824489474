import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';

function StepperIndicator(props) {
  const { completed, number, active } = props;
  const theme = useTheme();

  return (
    <>
      {completed && (
        <div
          style={{
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
            position: 'relative'
          }}
        >
          <div
            id={'check'}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: theme.palette.secondary.contrastText,
              fontSize: theme.spacing(2)
            }}
          >
            <DoneIcon fontSize={'18px'} sx={{ display: 'flex' }} />
          </div>
        </div>
      )}
      {!completed && (
        <div
          style={{
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
            opacity: !completed && !active ? 0.3 : 0.87,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.secondary.contrastText
          }}
        >
          <Typography variant={'subtitle2'}>{number + 1}</Typography>
        </div>
      )}
    </>
  );
}

export default StepperIndicator;
