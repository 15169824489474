import { json2csv } from 'json-2-csv';
import customTheme from '../components/customTheme';
import { availableFilterOperations } from '../components/NRReactGrid/NRReactGrid';
import { CONSTANTS } from '../constants/constants';
const { UNDETERMINED, NEGLIGIBLE, CONSERVATIVE, MODERATE, SIGNIFICANT, SEVERE } = CONSTANTS.RISK_METER;

export const isEmpty = obj => !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
export const transformToQueryParams = params =>
  !isEmpty(params)
    ? `?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`
    : '';
export const findNode = (id, currentNode) => {
  let i, currentChild, result;

  if (id === currentNode.id) {
    return currentNode;
  } else if (!!currentNode.children?.length) {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (i = 0; i < currentNode.children.length; i += 1) {
      currentChild = currentNode.children[i];

      // Search in the current child
      result = findNode(id, currentChild);

      // Return the result if the node has been found
      if (result !== false) {
        return result;
      }
    }

    // The node has not been found and we have no more options
    return false;
  } else {
    return false;
  }
};

export const findNodeByPath = (path, currentNode) => {
  let i, currentChild, result;

  if (path === currentNode.path) {
    return currentNode;
  } else if (!!currentNode.children?.length) {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (i = 0; i < currentNode.children.length; i += 1) {
      currentChild = currentNode.children[i];

      // Search in the current child
      result = findNodeByPath(path, currentChild);

      // Return the result if the node has been found
      if (result !== false) {
        return result;
      }
    }

    // The node has not been found and we have no more options
    return false;
  } else {
    return false;
  }
};

export const findNodeByFilePath = (filePath, currentNode) => {
  let i, currentChild, result;

  if (filePath === currentNode.path) {
    return currentNode;
  } else if (!!currentNode.children?.length) {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (i = 0; i < currentNode.children.length; i += 1) {
      currentChild = currentNode.children[i];

      // Search in the current child
      result = findNodeByFilePath(filePath, currentChild);

      // Return the result if the node has been found
      if (result !== false) {
        return result;
      }
    }

    // The node has not been found and we have no more options
    return false;
  } else {
    return false;
  }
};

export const ArrayMove = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const splitCamelCase = str => {
  return str.replace(/([A-Z])/g, ' $1');
};

export const splitCamelCaseWithInitials = str => {
  return str.replace(/([A-Z])/g, ' $1') + ` (${str.replace(/([a-z])/g, '')})`;
};

export const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export function getProp(object, keys, defaultVal) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
}

export function clearTypename(obj) {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach(k => {
    if (k !== '__typename') {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function cloneDeep(entity, cache = new WeakMap()) {
  const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'WeakMap', 'WeakSet'];
  const entityType = Object.prototype.toString.call(entity);
  if (!new RegExp(referenceTypes.join('|')).test(entityType)) return entity;
  if (cache.has(entity)) {
    return cache.get(entity);
  }
  const c = new entity.constructor();

  if (entity instanceof Map || entity instanceof WeakMap) {
    entity.forEach((value, key) => c.set(cloneDeep(key), cloneDeep(value)));
  }
  if (entity instanceof Set || entity instanceof WeakSet) {
    entity.forEach(value => c.add(cloneDeep(value)));
  }
  cache.set(entity, c);
  return Object.assign(c, ...Object.keys(entity).map(prop => ({ [prop]: cloneDeep(entity[prop], cache) })));
}

/**
 * Comparison function to order vulnerabilities by Critical, High, Medium and Low.
 * @param rowA
 * @param rowB
 * @returns {number}
 */
export const weightVulnerabilities = (rowA, rowB) => {
  const rowAnew = [rowA.critical.count, rowA.high.count, rowA.medium.count, rowA.low.count];
  const rowBnew = [rowB.critical.count, rowB.high.count, rowB.medium.count, rowB.low.count];
  const both = rowAnew.map((a, i) => {
    return [a, rowBnew[i]];
  });

  for (let i = 0; i < 4; i++) {
    if (both[i][0] != both[i][1]) {
      return both[i][0] - both[i][1];
    }
  }

  return 0;
};

/**
 * Round numbers
 * for examples refer to https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
 */
export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const capitalizeFirstLetter = str => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;
};

export const capitalizeFirstWord = str => {
  return !!str
    ? str
        .split(' ')
        .map(e => capitalizeFirstLetter(e))
        .join(' ')
    : str;
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
  //number = Number(number);
  forceLetter = forceLetter || false;
  if (forceLetter !== false) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter);
  }
  let abbr;
  if (number >= 1e12) {
    abbr = 'T';
  } else if (number >= 1e9) {
    abbr = 'B';
  } else if (number >= 1e6) {
    abbr = 'M';
  } else if (number >= 1e3) {
    abbr = 'K';
  } else {
    abbr = '';
  }
  return annotate(number, maxPlaces, forcePlaces, abbr);
}

function annotate(number, maxPlaces, forcePlaces, abbr) {
  // set places to false to not round
  let rounded = 0;
  switch (abbr) {
    case 'T':
      rounded = number / 1e12;
      break;
    case 'B':
      rounded = number / 1e9;
      break;
    case 'M':
      rounded = number / 1e6;
      break;
    case 'K':
      rounded = number / 1e3;
      break;
    case '':
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    let test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
    if (test.test('' + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces);
  }
  return rounded + abbr;
}

export function omit(obj, omitKey) {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export const flattenObject = function (ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object') {
      if (Array.isArray(ob[i])) {
        toReturn[i] = JSON.stringify(ob[i]);
      } else {
        var flatObject = flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[`entry.${x}`] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function getCSVKeys(array) {
  let baseLevelKeys = Object.keys(array[0])
    .map(key => {
      if (!key.startsWith('__') && key !== 'entry') {
        return key;
      }
      return '';
    })
    .filter(prop => !!prop);

  let entryKeys = !!array[0].entry
    ? Object.keys(array[0].entry)
        .map(entryKey => {
          if (!entryKey.startsWith('__') && !baseLevelKeys.includes(entryKey)) {
            return { field: `entry.${entryKey}`, title: entryKey };
          }
          return '';
        })
        .filter(prop => !!prop)
    : [];

  return [...baseLevelKeys, ...entryKeys];
}

export function copyAsCSV(array, filterUnwantedKeys = true) {
  let options = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ',', // Comma field delimiter
      eol: '\n' // Newline delimiter
    },
    ...(filterUnwantedKeys && { keys: getCSVKeys(array) })
  };
  json2csv(
    array,
    (err, data) => {
      navigator.clipboard.writeText(data);
    },
    options
  );
}

export const generateRandom = () => Math.floor(Math.random() * 100) + 1;

export const isArrayEmpty = array => {
  if (!Array.isArray(array)) return true;
  for (let i = 0; i < array.length; i++) {
    if (array[i]) return false;
  }
  return true;
};

export const isAnyUndefined = array => {
  if (!Array.isArray(array)) return true;
  if (array.length === 0) return true;
  for (let i = 0; i < array.length; i++) {
    if (!array[i]) return true;
  }
  return false;
};

export const getGroupFromPurl = purl => {
  //"pkg:npm/merge-descriptors@1.0.1",
  const group = purl?.split(':')[1]?.split('/')[0]; // Group would be npm
  return group;
};

export const getNameFromPath = path => {
  return path.replace(/^.*[\\\/]/, '');
};

export const includesPermission = (permissions, permissionToCheck) => {
  return !!permissions && Array.isArray(permissions) && permissions.includes(permissionToCheck);
};

export const getWeightedColor = (i, disabled) => {
  if (disabled) return customTheme.palette.secondary.main;
  if (i < 0 || i > 10) {
    return customTheme.palette.neutral[200];
  }
  if (i === 0) {
    return customTheme.palette.severityPalette[0].main;
  } else if (i <= 2) {
    return customTheme.palette.severityPalette[1].main;
  } else if (i <= 4) {
    return customTheme.palette.severityPalette[2].main;
  } else if (i <= 6) {
    return customTheme.palette.severityPalette[3].main;
  } else if (i <= 8) {
    return customTheme.palette.severityPalette[4].main;
  } else if (i <= 10) {
    return customTheme.palette.severityPalette[5].main;
  } else {
    return customTheme.palette.neutral[200]; // change gray
  }
};

export const getWeightedColorAlt = (i, disabled) => {
  if (disabled) return customTheme.palette.secondary.main;
  if (i < 0 || i > 10) {
    return customTheme.palette.neutral[200];
  }
  if (i === 0) {
    return customTheme.palette.severityPalette[0].alt;
  } else if (i <= 2) {
    return customTheme.palette.severityPalette[1].alt;
  } else if (i <= 4) {
    return customTheme.palette.severityPalette[2].alt;
  } else if (i <= 6) {
    return customTheme.palette.severityPalette[3].alt;
  } else if (i <= 8) {
    return customTheme.palette.severityPalette[4].alt;
  } else if (i <= 10) {
    return customTheme.palette.severityPalette[5].alt;
  } else {
    return customTheme.palette.neutral[200]; // change gray
  }
};

export const getWeightedCategory = i => {
  if (i === 0) {
    return UNDETERMINED;
  } else if (i <= 2) {
    return NEGLIGIBLE;
  } else if (i <= 4) {
    return CONSERVATIVE;
  } else if (i <= 6) {
    return MODERATE;
  } else if (i <= 8) {
    return SIGNIFICANT;
  } else if (i <= 10) {
    return SEVERE;
  } else {
    return NEGLIGIBLE;
  }
};

export const transformDataAnalyticCryptoCount = resp => {
  const objKeys = [...resp.measures];
  const rows = [];
  resp.rows.map(row => {
    let parsedEntries = {};
    objKeys.forEach((key, index) => {
      parsedEntries[key] = row[index];
    });
    rows.push(parsedEntries);
  });
  return rows[0];
};

export const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const isNumber = value => {
  return typeof value === 'number' && isFinite(value);
};

export const sumValuesFromObjectKeys = obj => {
  const values = Object.keys(clearTypename(obj)).map(k => {
    return obj[k];
  });
  return values.reduce((p, c) => {
    return p + c;
  }, 0);
};

export function camelCaseToHuman(name) {
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(' ');
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

export function clearEmptyValues(obj) {
  const newObj = { ...obj };
  Object.keys(obj).forEach(e => {
    if (!obj[e]) {
      delete newObj[e];
    }
  });
  return newObj;
}

export function deduplicateData(arr) {
  if (!arr?.length) return arr;
  return Array.from(new Set(arr.map(obj => JSON.stringify(obj)))).map(item => JSON.parse(item));
}

export function extractPartFromPathname() {
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  const complexSegment = segments[2];
  const assetID = complexSegment.split('%7C')[0];
  return assetID;
}

export const getDataFromComposedAssetId = composedAssetId => {
  return {
    assetId: composedAssetId.split('|')[0],
    projectId: composedAssetId.split('|')[1],
    runId: composedAssetId.split('|')[2],
    snapshotId: composedAssetId.split('|')[3]
  };
};

export const getAssetIdFromPath = () => {
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  const complexSegment = segments[2];
  return complexSegment?.split('%7C')?.join('|');
};

export const showAsPercentile = (value, toFixed = 1) => {
  if (!value) return value;
  return (value * 100).toFixed(toFixed) + ' %';
};

export function getTableFilterOperation(operation) {
  return availableFilterOperations.find(tableOp => operation.toLowerCase() === tableOp.toLowerCase());
}

export function toBytes(inputStr) {
  const units = {
    b: 1,
    kb: 1024,
    mb: Math.pow(1024, 2),
    gb: Math.pow(1024, 3),
    tb: Math.pow(1024, 4)
    // Add more units as needed
  };

  // Remove any commas, then split the string into value and unit
  const [value, unit] = inputStr.replace(',', '.').toLowerCase().split(/\s+/);

  // Convert the value to a float (because of potential decimals) and multiply by the unit's byte equivalent
  return parseFloat(value) * (units[unit] || 0);
}
