function NRRansomwareIcon({ height = '24', width = '24', color = 'currentColor' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H8L7 20H6V10H18V20H17L16 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5881 21.1226V22.0909H12.2869V21.1224C12.8351 21.0915 13.3124 20.9868 13.7187 20.8082C14.2131 20.5895 14.5909 20.2869 14.8523 19.9006C15.1136 19.5114 15.2443 19.0611 15.2443 18.5497C15.2443 18.1719 15.1733 17.8423 15.0312 17.5611C14.8892 17.2798 14.696 17.0398 14.4517 16.8409C14.2102 16.6421 13.9361 16.4773 13.6292 16.3466C13.3253 16.2159 13.0085 16.1122 12.679 16.0355L12.2869 15.9375V13.5101C12.6085 13.5484 12.8798 13.6459 13.1008 13.8026C13.3963 14.0099 13.5639 14.2997 13.6037 14.6719H15.125C15.1165 14.1804 14.9787 13.7457 14.7116 13.3679C14.4446 12.9872 14.0767 12.6903 13.6079 12.4773C13.2206 12.2978 12.7803 12.1929 12.2869 12.1626V11.1818H11.5881V12.1689C11.1329 12.2059 10.7167 12.3086 10.3395 12.4773C9.85652 12.6903 9.47442 12.9901 9.19317 13.3764C8.91476 13.7628 8.77555 14.2131 8.77555 14.7273C8.77555 15.3523 8.98294 15.8537 9.39771 16.2315C9.81533 16.6065 10.3835 16.8864 11.1023 17.071L11.5881 17.1961V19.7632C11.3873 19.7407 11.1998 19.697 11.0256 19.6321C10.7613 19.5327 10.5469 19.3821 10.3821 19.1804C10.2173 18.9787 10.1236 18.7259 10.1008 18.4219H8.54544C8.56817 19.0014 8.71874 19.4929 8.99715 19.8963C9.2784 20.2997 9.6676 20.6065 10.1648 20.8168C10.578 20.9905 11.0525 21.0925 11.5881 21.1226ZM12.2869 19.76C12.4827 19.7361 12.6616 19.692 12.8239 19.6278C13.0852 19.5227 13.2898 19.3778 13.4375 19.1932C13.5852 19.0057 13.6605 18.7869 13.6633 18.5369C13.6605 18.3097 13.5937 18.1222 13.4631 17.9744C13.3324 17.8239 13.1491 17.6989 12.9133 17.5994C12.7293 17.5187 12.5205 17.445 12.2869 17.3784V19.76ZM11.5881 15.758V13.515C11.4119 13.5389 11.2529 13.5807 11.1108 13.6406C10.875 13.7401 10.6946 13.875 10.5696 14.0455C10.4446 14.2159 10.3807 14.4105 10.3778 14.6293C10.3778 14.8111 10.419 14.9688 10.5014 15.1023C10.5866 15.2358 10.7017 15.3494 10.8466 15.4432C10.9915 15.5341 11.152 15.6108 11.3281 15.6733C11.4144 15.7039 11.5011 15.7322 11.5881 15.758Z"
        fill={color}
      />
    </svg>
  );
}

export default NRRansomwareIcon;
