function NRNoDataPresentIcon({ height = '100', width = '100', iconColor = '#CACAC9', backgroundColor = '#CACAC9"' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill={backgroundColor} />
      <path
        d="M74 39.334C70.1333 39.334 67.9733 43.174 68.8533 46.0273L59.3866 55.5207C58.5866 55.2807 57.4133 55.2807 56.6133 55.5207L49.8133 48.7207C50.72 45.8673 48.56 42.0007 44.6666 42.0007C40.8 42.0007 38.6133 45.8407 39.52 48.7207L27.36 60.854C24.5066 59.974 20.6666 62.134 20.6666 66.0007C20.6666 68.934 23.0666 71.334 26 71.334C29.8666 71.334 32.0266 67.494 31.1466 64.6407L43.28 52.4807C44.08 52.7207 45.2533 52.7207 46.0533 52.4807L52.8533 59.2807C51.9466 62.134 54.1066 66.0007 58 66.0007C61.8666 66.0007 64.0533 62.1607 63.1466 59.2807L72.64 49.814C75.4933 50.694 79.3333 48.534 79.3333 44.6673C79.3333 41.734 76.9333 39.334 74 39.334Z"
        fill={iconColor}
      />
      <path d="M58 42L60.5067 36.48L66 34L60.5067 31.52L58 26L55.5467 31.52L50 34L55.5467 36.48L58 42Z" fill={iconColor} />
      <path
        d="M27.3333 47.3333L28.6666 42L34 40.6667L28.6666 39.3333L27.3333 34L26 39.3333L20.6666 40.6667L26 42L27.3333 47.3333Z"
        fill={iconColor}
      />
    </svg>
  );
}
export default NRNoDataPresentIcon;
