import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { makeStyles } from '@mui/styles';
import { isEmpty } from '../../utils/utils';
import ReportPopoverStyles from './ReportPopover.styles';
import moment from 'moment';
import { CONSTANTS } from '../../constants/constants';
import NRLoader from '../NRLoader/NRLoader';
import Tooltip from '@mui/material/Tooltip';
const { MONTH_DAY_YEAR_TIME_SHORT } = CONSTANTS.DATE_FORMAT;
const { CREATED_BY, CREATED_ON, IMAGE_INFORMATION, MD5_HASH, MD5_HASH_TOOLTIP, REPORT_INFORMATION, SHA256_HASH, SHA256_HASH_TOOLTIP } =
  CONSTANTS.REPORT.POPOVER;
const useStyles = makeStyles(ReportPopoverStyles);

function ReportPopover({ reportData, org, isReportDataLoading, onCopySHA256, onCopyMD5 }) {
  const { digest } = reportData || {};
  const classes = useStyles();
  return (
    <div className={classes.popoverMain}>
      {!isEmpty(reportData) && !isReportDataLoading && (
        <>
          <div className={classes.topSection}>
            <div className={classes.title}>{REPORT_INFORMATION}</div>
            <div className={classes.topSubSection}>
              <div className={classes.createdBy}>
                <div id="created-by-subtitle" className={classes.subtitle}>
                  {CREATED_BY}
                </div>
                <div aria-labelledby="created-by-subtitle">{org}</div>
              </div>
              <div className={classes.endDate}>
                <div id="created-on-subtitle" className={classes.subtitle}>
                  {CREATED_ON}
                </div>
                <div aria-labelledby="created-on-subtitle">{moment(reportData.endTime).format(MONTH_DAY_YEAR_TIME_SHORT)}</div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.title}>{IMAGE_INFORMATION}</div>
            <div className={classes.subSection}>
              <div id="sha256-subtitle" className={classes.subtitle}>
                {SHA256_HASH}
              </div>
              <span aria-labelledby="sha256-subtitle">
                {digest.sha256}
                <Tooltip title={SHA256_HASH_TOOLTIP}>
                  <FileCopyOutlinedIcon data-testid="copy-sha256" className={classes.icon} onClick={() => onCopySHA256()} />
                </Tooltip>
              </span>
            </div>
            <div>
              <div id="md5-subtitle" className={classes.subtitle}>
                {MD5_HASH}
              </div>
              <span aria-labelledby="md5-subtitle">
                {digest.md5}
                <Tooltip title={MD5_HASH_TOOLTIP}>
                  <FileCopyOutlinedIcon data-testid="copy-md5" className={classes.icon} onClick={() => onCopyMD5()} />
                </Tooltip>
              </span>
            </div>
          </div>
        </>
      )}
      {isReportDataLoading && (
        <div data-testid="loader" className={classes.loaderContainer}>
          <NRLoader />
        </div>
      )}
    </div>
  );
}
export default ReportPopover;
