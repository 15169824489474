import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CONSTANTS } from '../../constants/constants';
const { SHOW_MORE, SHOW_LESS } = CONSTANTS;

function NRShowMore({ items, itemsToShowByDefault = 10, openByDefault = false, maxReached, stateToRefresh }) {
  const [showMore, setShowMore] = useState(openByDefault);
  const [showShowMoreLabel] = useState(items.length > itemsToShowByDefault);
  const [itemsToShow, setItemsToShow] = useState(items.slice(0, itemsToShowByDefault));
  const targetRef = useRef();

  const useStyles = makeStyles(theme => ({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    itemsContainer: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    showMore: {
      ...theme.typography.body2,
      marginTop: theme.spacing(1),
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.text.secondary
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    if (showMore) {
      setItemsToShow(items);
    } else {
      setItemsToShow(items.slice(0, itemsToShowByDefault));
    }
  }, [showMore, stateToRefresh]);

  return (
    <div className={classes.mainContainer}>
      <div ref={targetRef} className={classes.itemsContainer}>
        {itemsToShow}
      </div>
      {showMore && maxReached}
      {showShowMoreLabel && (
        <div onClick={() => setShowMore(prev => !prev)} className={classes.showMore}>
          {showMore ? SHOW_LESS : SHOW_MORE}
        </div>
      )}
    </div>
  );
}
export default NRShowMore;
