import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import NRCollapsibleComponentStyles from './NRCollapsibleComponent.styles';
const useStyles = makeStyles(NRCollapsibleComponentStyles);

function NRCollapsibleComponent({
  title,
  titleComponent,
  headerIcon,
  subheader,
  collapsibleContent,
  openOnTitle = false,
  openByDefault = false,
  titleExtraValue,
  titleExtraStyles,
  titleWidth = 150,
  disableOpen,
  autoScrollIntoViewId,
  index,
  handleOnClick,
  contentMaxHeight
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(openByDefault);
  const [isAutoScrollAvailable] = useState(autoScrollIntoViewId != null);
  const collapsibleContentId = `nr-collapsible-id-${title?.toLowerCase().replace(' ', '-')}-${autoScrollIntoViewId}-${index}`;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
   * AutoScroll handling
   */

  useEffect(() => {
    if (expanded && isAutoScrollAvailable) {
      let elem = document.querySelector(`#${collapsibleContentId}`);
      !!elem && autoScroll(elem);
    }
  }, [expanded]);

  const autoScroll = elem => {
    setTimeout(() => {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 300);
  };

  return (
    <div
      className={clsx(classes.mainContainer, { [classes.openOnTitle]: openOnTitle && !disableOpen })}
      {...(openOnTitle && !disableOpen && { onClick: handleExpandClick })}
    >
      <CardHeader
        classes={{ title: classes.title, root: classes.root, action: classes.action, avatar: classes.icon }}
        action={
          <>
            {!disableOpen && (
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </>
        }
        title={
          <>
            {!titleComponent && (
              <div className={classes.titleContainer}>
                <div className={clsx({ [titleExtraStyles]: !!titleExtraStyles })} style={{ width: titleWidth }}>
                  {title}
                </div>
                {titleExtraValue != null && (
                  <span className={clsx(classes.titleExtra, { [classes.titleExtraClickable]: !!handleOnClick })}>{titleExtraValue}</span>
                )}
              </div>
            )}
            {!!titleComponent && titleComponent}
          </>
        }
        subheader={subheader}
        avatar={headerIcon}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <CardContent
          id={collapsibleContentId}
          classes={{ root: classes.cardContentRoot }}
          sx={{ maxHeight: contentMaxHeight, ...(contentMaxHeight && { overflowY: 'auto' }) }}
        >
          {collapsibleContent}
        </CardContent>
      </Collapse>
    </div>
  );
}
export default NRCollapsibleComponent;
