import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';

function NRKeyValueDataDisplay({
  uniqueKey,
  dataKey,
  value,
  keyWidth = '40%',
  minWidth = 168,
  valueMaxWidth = 100,
  showDash,
  showTooltip = false,
  tooltipValue,
  wrapLine = false,
  hasCopy = false,
  valueAltColor,
  endAdornment
}) {
  const useStyles = makeStyles(theme => ({
    mainContainer: {
      ...theme.typography.body2,
      display: 'flex',
      margin: theme.spacing(0.5, 0),
      '&:first-child': {
        marginTop: 0
      }
    },
    key: {
      textTransform: 'capitalize',
      width: keyWidth,
      minWidth: minWidth,
      whiteSpace: 'nowrap'
    },
    value: {
      color: valueAltColor || theme.palette.text.secondary,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginLeft: theme.spacing(0.5),
      maxWidth: valueMaxWidth,
      cursor: 'default'
    },
    oneLinerValue: {
      color: valueAltColor || theme.palette.text.secondary,
      marginLeft: theme.spacing(0.5)
    },
    icon: {
      width: '0.875rem',
      height: '0.875rem',
      marginLeft: 6,
      marginBottom: -2,
      cursor: 'pointer',
      color: theme.palette.text.icon
    },
    endAdornment: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1)
    }
  }));

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const valuePrefix = `${showDash ? ' — ' : ''}`;

  const onCopyThumbprint = (title, value) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar(`${title} copied!`, { variant: 'success', preventDuplicate: true, autoHideDuration: 1000 });
  };

  return (
    <div {...(!!uniqueKey && { key: uniqueKey })} className={classes.mainContainer}>
      {!wrapLine && (
        <>
          <div className={classes.key}>{dataKey}</div>
          <>
            <Tooltip title={showTooltip ? (!!tooltipValue ? tooltipValue : value) : ''}>
              <div className={classes.value}>
                {valuePrefix}
                {value}
              </div>
            </Tooltip>
            {hasCopy && (
              <div className={classes.value}>
                <FileCopyOutlinedIcon className={classes.icon} onClick={() => onCopyThumbprint(dataKey, value)} />
              </div>
            )}
            {!!endAdornment && <div className={classes.endAdornment}>{endAdornment}</div>}
          </>
        </>
      )}
      {wrapLine && (
        <div>
          <span className={classes.key}>{dataKey}</span>
          <>
            <Tooltip title={showTooltip ? (!!tooltipValue ? tooltipValue : value) : ''}>
              <span className={classes.oneLinerValue}>
                {valuePrefix}
                {value}
              </span>
            </Tooltip>
            {hasCopy && (
              <div className={classes.value}>
                <FileCopyOutlinedIcon className={classes.icon} onClick={() => onCopyThumbprint(dataKey, value)} />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}
export default NRKeyValueDataDisplay;
