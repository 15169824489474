import { useContext } from 'react';
import { AssetsContext } from '../../DataShare/NRAssetsDataShare';
import NRTaskProgress from './NRTaskProgress';
import { CONSTANTS } from '../../constants/constants';
import NRTaskIndeterminateProgress from './NRTaskIndeterminateProgress';

function NRTaskProgressContainer() {
  const { tasks } = useContext(AssetsContext);

  return (
    <div>
      {tasks?.map(eachTask => (
        <div key={`${eachTask.id}`}>
          {eachTask.type === CONSTANTS.TASKS.TYPE.DOWNLOAD && <NRTaskIndeterminateProgress task={eachTask} />}
          {eachTask.type === CONSTANTS.TASKS.TYPE.UPLOAD && <NRTaskProgress task={eachTask} tasks={tasks} />}
        </div>
      ))}
    </div>
  );
}

export default NRTaskProgressContainer;
