export default theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    ...theme.typography.label
  },
  icon: {
    marginBottom: theme.spacing(1)
  }
});
