import { drawerWidth } from '../NRMenu.styles';

export default theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& > .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowX: 'hidden',
      borderRight: 'none'
    }
  },
  drawerPaper: {
    overflowX: 'hidden',
    width: drawerWidth,
    backgroundColor: theme.palette.backgroundAlt.paper,
    color: theme.palette.backgroundAlt.color
  },
  logo: {
    padding: theme.spacing(1.75, 2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    color: theme.palette.primary.main
  }
});
