export default theme => ({
  iconBlue: {
    color: theme.palette.blue.contrastText,
    backgroundColor: theme.palette.blue[500]
  },
  iconRed: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  },
  iconGray: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main
  },
  iconYellow: {
    color: theme.palette.white,
    backgroundColor: theme.palette.orange[500]
  },
  roundedIcon: {
    borderRadius: '10%',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: theme.spacing(0.7)
  },
  margin: {
    marginRight: theme.spacing(1)
  },
  icon: {
    width: 16
  },
  materialIcon: {
    display: 'inline',
    borderRadius: '10%',
    padding: '5.6px'
  }
});
