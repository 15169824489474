import { FixedSizeList as List } from 'react-window';

function NRFixedVirtualizedList({ list, Component, itemHeight = 100, listHeight = 300, width = '580px' }) {
  return (
    <List height={Math.min(list?.length * itemHeight, listHeight)} itemCount={list?.length} itemSize={itemHeight} width={width}>
      {Component}
    </List>
  );
}

export default NRFixedVirtualizedList;
