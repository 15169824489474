import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import NRMenuItem from '../../../components/NRMenuItem/NRMenuItem';
import NRSideNavbarStyles from './NRSideNavbar.styles';
import makeStyles from '@mui/styles/makeStyles';
import NRDashboardLight from '../../../components/../NRIcons/NRDashboardLight/NRDashboardLight';
import { useLocation } from 'react-router-dom';
import routes from '../../../routes';
import { CONSTANTS } from '../../../constants/constants';
import NRSearchIcon from '../../../components/../NRIcons/NRSearchIcon';
import NetRisePlainLogo from '../../../components/../NRIcons/NetRisePlainLogo';
import NRSettingsIcon from '../../../components/../NRIcons/NRSettingsIcon';
import NRAssetIcon from '../../../NRIcons/NRAssetIcon';
const { ASSETS, SEARCH, OVERVIEW } = CONSTANTS.SIDE_NAVBAR.MENU_ITEMS;

const useStyles = makeStyles(NRSideNavbarStyles);

function NRSideNavbar() {
  const classes = useStyles();
  const [selected, setSelected] = useState();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    routes.map(route => pathname.startsWith(route.path.split(':')[0]) && setSelected(route.menu));
  }, [pathname]);

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div>
        <div className={classes.logo}>
          <NetRisePlainLogo />
        </div>
        <List>
          <NRMenuItem route={'/'} text={OVERVIEW} icon={<NRDashboardLight />} selected={selected} />
          <NRMenuItem route={'/assets'} text={ASSETS} icon={<NRAssetIcon />} selected={selected} />
          <NRMenuItem route={'/search'} text={SEARCH} icon={<NRSearchIcon />} selected={selected} />
          <NRMenuItem route={'/settings'} text={'Settings'} icon={<NRSettingsIcon />} selected={selected} />
        </List>
      </div>
    </Drawer>
  );
}

export default NRSideNavbar;
