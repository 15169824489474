import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CONSTANTS } from '../../../../constants/constants';
import NRKnownExploitsStyles from './NRKnownExploits.styles';
import { makeStyles } from '@mui/styles';
import NRCollapsibleComponent from '../../../NRCollapsibleComponent/NRCollapsibleComponent';
import NRAttackIcon from '../../../../NRIcons/NRAttackIcon';
import NRActorIcon from '../../../../NRIcons/NRActorIcon';
import NRRansomwareIcon from '../../../../NRIcons/NRRansomwareIcon';
import NRBotnetIcon from '../../../../NRIcons/NRBotnetIcon';
import KnownExploitInfo from './KnownExploitInfo/KnownExploitInfo';
import { useContext, useEffect } from 'react';
import BotnetInfo from './BotnetInfo/BotnetInfo';
import ThreatActorsInfo from './ThreatActorsInfo/ThreatActorsInfo';
import { VulnDrawerTabsContext } from '../NRVulnerabilityDetails';
import NRExploitCodeIcon from '../../../../NRIcons/NRExploitCodeIcon';
import RansomwareInfo from './RansomwareInfo/RansomwareInfo';
import clsx from 'clsx';
import KnownAttacksInfo from './KnownAttacks/KnownAttacks';
import NRKevIcon from '../../../../NRIcons/NRKevIcon';
import KevInfo from './KEVInfo/KEVInfo';
import NRLoader from '../../../NRLoader/NRLoader';
const useStyles = makeStyles(NRKnownExploitsStyles);

const { TITLE, TOOLTIP, THREAT_ACTORS, ATTACKS, BOTNETS, EXPLOIT_CODE, RANSOMWARE, CISA_KEV } =
  CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.KNOWN_EXPLOITS_SECTION;

const NRKnownExploits = ({ cveDetails, isLoading, threshold, scrollTarget, index, scrollableId, extraMainContainerClasses }) => {
  const { setScrollSpyTabsValue } = useContext(VulnDrawerTabsContext);
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: !!scrollTarget ? scrollTarget : undefined
  });
  useEffect(() => {
    setScrollSpyTabsValue(index, trigger);
  }, [trigger]);

  return (
    <div id={scrollableId} className={clsx(classes.mainContainer, extraMainContainerClasses)}>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{TITLE}</div>
          <Tooltip title={TOOLTIP} placement="right">
            <InfoOutlinedIcon className={classes.icon} />
          </Tooltip>
        </div>
        <>
          {isLoading ? (
            <Box sx={{ height: '216px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <NRLoader />
            </Box>
          ) : (
            <>
              <NRCollapsibleComponent
                headerIcon={<NRKevIcon width={'24'} height={'24'} />}
                title={CISA_KEV}
                collapsibleContent={<KevInfo data={cveDetails?.exploit?.references?.knownExploitedVulnerabilities} />}
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.knownExploitedVulnerabilities?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.knownExploitedVulnerabilities?.length}
                autoScrollIntoViewId={0}
              />
              <NRCollapsibleComponent
                headerIcon={<NRActorIcon />}
                title={THREAT_ACTORS}
                collapsibleContent={<ThreatActorsInfo data={cveDetails?.exploit?.references?.threatActors} />}
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.threatActors?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.threatActors?.length}
                autoScrollIntoViewId={1}
              />
              <NRCollapsibleComponent
                headerIcon={<NRAttackIcon />}
                title={ATTACKS}
                collapsibleContent={<KnownAttacksInfo data={cveDetails?.exploit?.references?.knownAttacks?.map(e => e?.displayName)} />}
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.knownAttacks?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.knownAttacks?.length}
                autoScrollIntoViewId={2}
              />
              <NRCollapsibleComponent
                headerIcon={<NRBotnetIcon />}
                title={BOTNETS}
                collapsibleContent={<BotnetInfo data={cveDetails?.exploit?.references?.botnets} />}
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.botnets?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.botnets?.length}
                autoScrollIntoViewId={3}
              />
              <NRCollapsibleComponent
                headerIcon={<NRExploitCodeIcon />}
                title={EXPLOIT_CODE}
                collapsibleContent={<KnownExploitInfo data={cveDetails?.exploit?.references?.exploits} />}
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.exploits?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.exploits?.length}
                autoScrollIntoViewId={4}
              />
              <NRCollapsibleComponent
                headerIcon={<NRRansomwareIcon />}
                title={RANSOMWARE}
                collapsibleContent={
                  <RansomwareInfo
                    data={{
                      ransomwareDatetime: cveDetails?.exploit?.ransomwareDatetime,
                      recentRansomwareDatetime: cveDetails?.exploit?.recentRansomwareDatetime,
                      ransomwareData: cveDetails?.exploit?.references?.ransomware
                    }}
                  />
                }
                openOnTitle
                titleExtraValue={cveDetails?.exploit?.references?.ransomware?.length || 0}
                disableOpen={!cveDetails?.exploit?.references?.ransomware?.length}
                autoScrollIntoViewId={5}
              />
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default NRKnownExploits;
