export const REMEDIATION_CONSTANTS = {
  STATUS: {
    LABELS: {
      UNSPECIFIED: 'Unreviewed',
      NOT_AFFECTED: 'Not Affected',
      AFFECTED: 'Exploitable',
      FIXED: 'Resolved',
      UNDER_INVESTIGATION: 'In Triage',
      FALSE_POSITIVE: 'False Positive',
      RESOLVED_WITH_PEDIGREE: 'Resolved with Pedigree'
    },
    VALUES: {
      UNSPECIFIED: 'UNSPECIFIED',
      NOT_AFFECTED: 'NOT_AFFECTED',
      AFFECTED: 'AFFECTED',
      FIXED: 'FIXED',
      UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
      FALSE_POSITIVE: 'FALSE_POSITIVE',
      RESOLVED_WITH_PEDIGREE: 'RESOLVED_WITH_PEDIGREE'
    },
    DESCRIPTIONS: {
      UNSPECIFIED: 'This issue has either not been reviewed or remediated.',
      NOT_AFFECTED: 'No remediation is required regarding this vulnerability.',
      AFFECTED: 'Actions are recommended to remediate or address this vulnerability.',
      FIXED: 'Represents that these product versions contain a fix for the vulnerability.',
      UNDER_INVESTIGATION: 'It is not yet known whether these product versions are affected by the vulnerability. An update will be provided later.',
      FALSE_POSITIVE: 'FALSE_POSITIVE',
      RESOLVED_WITH_PEDIGREE: 'RESOLVED_WITH_PEDIGREE'
    },
    SHOW_ALERT: {
      UNSPECIFIED: false,
      AFFECTED: false,
      FALSE_POSITIVE: true,
      UNDER_INVESTIGATION: false,
      NOT_AFFECTED: true,
      FIXED: true,
      RESOLVED_WITH_PEDIGREE: true
    }
  },
  VEX_JUSTIFICATION: {
    CODE_NOT_PRESENT: 'Code not present',
    CODE_NOT_REACHABLE: 'Code not reachable',
    REQUIRES_CONFIGURATION: 'Requires configuration',
    REQUIRES_DEPENDENCY: 'Requires dependency',
    REQUIRES_ENVIRONMENT: 'Requires environment',
    PROTECTED_BY_COMPILER: 'Protected by compiler',
    PROTECTED_AT_RUNTIME: 'Protected at runtime',
    PROTECTED_AT_PERIMETER: 'Protected at perimeter',
    PROTECTED_BY_MITIGATING_CONTROL: 'Protected by mitigating control'
  },
  VEX_RESPONSES: {
    CANNOT_FIX: 'Cannot fix',
    WILL_NOT_FIX: 'Will not fix',
    UPDATE: 'Update',
    ROLLBACK: 'Rollback',
    WORKAROUND_AVAILABLE: 'Workaround available'
  },
  SUCCESS: {
    MESSAGE: `Status changed for ${0} vulnerabilities.`
  },
  VEX_TOOLTIP:
    'Vulnerability Exploitability eXchange (VEX) is a form of a security advisory. The goal of VEX is to allow a software supplier or other parties to assert the status of specific vulnerabilities in a particular product.'
};
