import { createContext, useState } from 'react';

export const FiltersContext = createContext({
  data: null,
  setData: () => {},
  openDrawer: null,
  setOpenDrawer: () => {},
  drawerContent: null,
  setDrawerContent: () => {},
  drawerTitle: null,
  setDrawerTitle: () => {},
  hasBack: null,
  setHasBack: () => {},
  drawerTitleExtraComponents: null,
  setDrawerTitleExtraComponents: () => {}
});

function NRReportFilterContextWrapper({ children }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState([]);
  const [hasBack, setHasBack] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerTitleExtraComponents, setDrawerTitleExtraComponents] = useState();
  const [data, setData] = useState();

  const defaultValues = {
    data,
    setData,
    openDrawer,
    setOpenDrawer,
    drawerContent,
    setDrawerContent,
    drawerTitle,
    setDrawerTitle,
    hasBack,
    setHasBack,
    drawerTitleExtraComponents,
    setDrawerTitleExtraComponents
  };

  return <FiltersContext.Provider value={defaultValues}>{children}</FiltersContext.Provider>;
}

export default NRReportFilterContextWrapper;
