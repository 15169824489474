export const API_CONSTANTS = {
  FILES: '/files',
  FILES_COUNT: '/files/count',
  FIRMWARE_IMAGES: '/firmware-images',
  FIRMWARE_IMAGES_COUNT: '/firmware-images/count',
  FIRMWARE_IMAGES_UPLOAD: '/firmware-images/upload',
  JOBS: '/jobs',
  JOB_DEFINITIONS: '/job-definitions',
  LOGIN: '/users/login',
  LOGOUT: '/users/logout',
  TAGS: '/tags',
  TASKS: '/tasks',
  USERS: '/users',
  INVITE_USER: '/users/action'
};

export const CVE_DETAILS_URL = 'https://nvd.nist.gov/vuln/detail/';
export const LAST_ORG_USED_KEY = '|lastOrgUsedId';
