import NRNoDataPresentIcon from '../../NRIcons/NRNoDataPresentIcon';
import { CONSTANTS } from '../../constants/constants';
import makeStyles from '@mui/styles/makeStyles';
import NRNoDataPresentComponentStyles from './NRNoDataPresentComponent.styles';
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles(NRNoDataPresentComponentStyles);

function NRNoDataPresentComponent({ text = CONSTANTS.CHARTS.NO_DATA_PRESENT, subtext }) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.icon} data-testid={'no-data-present-icon'}>
        <NRNoDataPresentIcon iconColor={theme.palette.noData.color} backgroundColor={theme.palette.noData.background} />
      </div>
      <span className={classes.text}>{text}</span>
      {!!subtext && subtext}
    </div>
  );
}

export default NRNoDataPresentComponent;
