import { createContext, useState } from 'react';

export const SBOMContext = createContext({
  groupedBy: null,
  setGroupedBy: () => {},
  isGroupedByChanging: null,
  setIsGroupedByChanging: () => {},
  queryFilter: null,
  setQueryFilter: () => {},
  filtersVisibility: null,
  setFiltersVisibility: () => {},
  typeFilter: null,
  setTypeFilter: () => {},
  licenseFilter: null,
  setLicenseFilter: () => {},
  nameFilter: null,
  setNameFilter: () => {},
  versionFilter: null,
  setVersionFilter: () => {},
  openDownloadSBOM: null,
  setOpenDownloadSBOM: () => {}
});

function NRSBOMDataShare({ children }) {
  const [groupedBy, setGroupedBy] = useState('');
  const [queryFilter, setQueryFilter] = useState({});
  const [isGroupedByChanging, setIsGroupedByChanging] = useState(false);
  const [filtersVisibility, setFiltersVisibility] = useState(false);
  const [typeFilter, setTypeFilter] = useState(null);
  const [licenseFilter, setLicenseFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState(null);
  const [versionFilter, setVersionFilter] = useState(null);
  const [openDownloadSBOM, setOpenDownloadSBOM] = useState(false);
  const defaultValues = {
    groupedBy,
    setGroupedBy,
    isGroupedByChanging,
    setIsGroupedByChanging,
    filtersVisibility,
    setFiltersVisibility,
    queryFilter,
    setQueryFilter,
    typeFilter,
    setTypeFilter,
    licenseFilter,
    setLicenseFilter,
    nameFilter,
    setNameFilter,
    versionFilter,
    setVersionFilter,
    openDownloadSBOM,
    setOpenDownloadSBOM
  };
  return <SBOMContext.Provider value={defaultValues}>{children}</SBOMContext.Provider>;
}

export default NRSBOMDataShare;
