function NetRisePlainLogo({ color = 'currentColor', height = '35', width = '40' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.3203 0.13648L24.4368 3.97869C24.1486 4.2956 23.9682 4.56547 23.9682 5.41595V7.78465C23.9671 7.83091 23.9628 7.8953 23.957 7.96084C23.9574 7.97832 23.96 7.99536 23.9625 8.01252C23.9654 8.0313 23.9682 8.0502 23.9682 8.06995C23.9682 10.2648 22.1845 12.0444 19.984 12.0444C17.7838 12.0444 16.0006 10.2648 16.0006 8.06995C16.0006 8.04745 16.0036 8.02604 16.0066 8.00466C16.0086 7.99015 16.0107 7.97565 16.0118 7.96084C16.0107 7.94592 16.0096 7.93106 16.0085 7.91646C16.0049 7.86693 16.0014 7.82038 16.0006 7.78465V5.41595C16.0006 4.56547 15.8197 4.2956 15.5323 3.97869L9.64773 0.13648C9.15671 -0.131464 8.40491 -0.0239049 8.3825 0.631113V7.42496C8.3825 8.3271 9.0211 9.43358 9.80381 9.88465C11.7962 11.0298 13.5266 12.0266 15.4513 13.1353L15.4517 13.1356C16.4196 13.6931 17.4366 14.279 18.5608 14.9262C18.9529 15.1506 19.4683 15.2647 19.984 15.2647C20.5009 15.2647 21.0163 15.1506 21.408 14.9262C22.6603 14.2052 23.7818 13.5595 24.8512 12.9439C26.6454 11.9109 28.2926 10.9626 30.1638 9.88465C30.9469 9.43358 31.587 8.3271 31.587 7.42496V0.631113C31.5712 0.193149 31.2301 0 30.8592 0C30.6749 0 30.4833 0.0478077 30.3203 0.13648Z"
        fill={color}
      />
      <path
        d="M30.9595 11.4696C27.988 13.1755 25.5296 14.5873 22.1964 16.502C21.8058 16.7271 21.4492 17.1161 21.1896 17.5607C20.9312 18.0075 20.7619 18.5075 20.7619 18.9594V29.0483C20.7581 29.9505 21.389 31.0574 22.1709 31.5084L28.0617 34.9165C28.64 35.2226 29.1109 34.6293 29.1248 34.0695L28.7432 27.0632C28.6141 26.6557 28.4685 26.3654 27.731 25.9402L25.6788 24.752C25.639 24.7269 25.5857 24.6907 25.5304 24.6525C25.5138 24.6438 25.497 24.6372 25.4804 24.6306C25.4639 24.6241 25.4476 24.6176 25.4319 24.6093C23.5265 23.5071 22.8775 21.074 23.9809 19.1741C25.0838 17.2746 27.522 16.6281 29.4266 17.7287C29.444 17.7392 29.4597 17.7515 29.4756 17.7641C29.4879 17.7737 29.5003 17.7835 29.5139 17.7927C29.5753 17.8205 29.6321 17.8483 29.6727 17.8702L31.7268 19.0581C32.4639 19.4845 32.7884 19.4648 33.2072 19.3742L39.4886 16.2217C39.9668 15.9318 40.2504 15.2278 39.6941 14.8781L33.8037 11.4727C33.4108 11.2464 32.8935 11.1327 32.3762 11.1327C31.8628 11.1327 31.3501 11.2445 30.9595 11.4696Z"
        fill={color}
      />
      <path
        d="M0.305777 14.8781L6.19693 11.4727C6.58867 11.2464 7.10597 11.1327 7.62365 11.1327C8.13708 11.1327 8.65051 11.2445 9.04109 11.4696C11.7469 13.0237 14.7747 14.7626 17.8035 16.502C18.1952 16.7271 18.5506 17.1161 18.8106 17.5607C19.0691 18.0075 19.2379 18.5075 19.2379 18.9594V29.0483C19.2429 29.9505 18.6117 31.0574 17.8305 31.5084L11.9382 34.9165C11.3598 35.2226 10.8901 34.6293 10.8754 34.0695L11.2575 27.0632C11.3865 26.6557 11.5314 26.3654 12.2685 25.9402L14.3211 24.752C14.3616 24.7269 14.4157 24.6907 14.4702 24.6525C14.4869 24.6437 14.5039 24.6369 14.5208 24.6303C14.5371 24.6238 14.5532 24.6175 14.5687 24.6093C16.4733 23.5071 17.122 21.074 16.0198 19.1741C14.9168 17.2746 12.4787 16.6281 10.5744 17.7287C10.5555 17.7397 10.5389 17.7526 10.5221 17.7657C10.5103 17.7748 10.4984 17.784 10.4856 17.7927C10.4249 17.8205 10.3681 17.8483 10.3276 17.8702L8.27346 19.0581C7.53557 19.4845 7.21182 19.4648 6.79265 19.3742L0.510919 16.2217C0.0330285 15.9318 -0.250152 15.2278 0.305777 14.8781Z"
        fill={color}
      />
    </svg>
  );
}
export default NetRisePlainLogo;
