import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import X from 'react-feather/dist/icons/x';
import FilterCellStyles from './FilterCell.styles';
import { CONSTANTS } from '../../../../constants/constants';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import clsx from 'clsx';
import NRMultipleSelectCheckmarks from '../../../NRMultipleSelectCheckmarks/NRMultipleSelectCheckmarks';
const { FILTER, CLEAR_FILTER_TOOLTIP } = CONSTANTS.TABLE;
const { TRUE, FALSE, ALL } = CONSTANTS.TABLE.FILTER_VALUES;
const useStyles = makeStyles(FilterCellStyles);

/**
 * Filter
 */
const FilterCellBase = ({ filter, onFilter, column, iconComponent, ...props }) => {
  const classes = useStyles();

  return (
    <th className={classes.th}>
      {/* we use align center or undefined (meaning left) so we translate to justifyContent: center || flex-start */}
      <div
        className={clsx(classes.innerTh, { [classes.showToggleComponent]: !!filter })}
        style={{ justifyContent: props?.tableColumn?.align || 'flex-start' }}
      >
        {!column.hideFilter && (
          <>
            <TextField
              id={`${column.name}-filter`}
              variant={'outlined'}
              color={'secondary'}
              inputProps={{ style: { textAlign: 'start', padding: 3 } }}
              className={classes.textField}
              classes={{ root: classes.textfieldRoot }}
              placeholder={FILTER}
              value={filter ? filter.value : ''}
              onChange={e =>
                onFilter(
                  e.target.value
                    ? { value: !!props.tableColumn.column.type ? Number(e.target.value) : e.target.value, operation: props.children[0].props.value }
                    : null
                )
              }
              {...(!!props.tableColumn.column.type && { type: props.tableColumn.column.type })}
              InputProps={{
                endAdornment: (
                  <>
                    {!!filter?.value && (
                      <InputAdornment classes={{ positionEnd: classes.inputAdornmentStyles }} position="end">
                        <Tooltip title={CLEAR_FILTER_TOOLTIP} placement="top">
                          <X size={16} onClick={() => onFilter(null)} />
                        </Tooltip>
                      </InputAdornment>
                    )}
                  </>
                ),
                startAdornment: <>{props.children[0]}</>
              }}
            />
          </>
        )}
      </div>
    </th>
  );
};

const FilterSelectCell = props => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(props?.filter?.value == null ? '' : props?.filter?.value);

  const getFilterValue = val => {
    return val === 1
      ? { value: 1, operation: 'greaterThanOrEqual', columnName: props.column.name }
      : val === 0
      ? { value: 0, operation: 'equal', columnName: props.column.name }
      : null;
  };

  const handleSetSelectValue = e => {
    setSelectValue(e.target.value);
    props.onFilter(getFilterValue(e.target?.value));
  };

  return (
    <th className={classes.th}>
      <Select
        style={{ width: 'calc(100% - 16px)', height: 32 }}
        variant={'outlined'}
        classes={{ root: classes.rootSelect, select: classes.selectedSelect }}
        id={`select-filter-${props.column.name}`}
        value={selectValue}
        onChange={handleSetSelectValue}
        color={'secondary'}
      >
        <MenuItem value={''}>{ALL}</MenuItem>
        <MenuItem value={1}>{props.column.filterValues?.true || TRUE}</MenuItem>
        <MenuItem value={0}>{props.column.filterValues?.false || FALSE}</MenuItem>
      </Select>
    </th>
  );
};

const OnOffFilterCell = props => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(props?.filter?.value || props?.filter?.value === false ? props?.filter?.value : null);

  const getFilterValue = val => {
    return val === 'on'
      ? { value: 'on', operation: 'contains', columnName: props.column.name }
      : val === 'off'
      ? { value: 'off', operation: 'contains', columnName: props.column.name }
      : null;
  };

  const handleSetSelectValue = e => {
    setSelectValue(e.target.value);
    let value = getFilterValue(e.target?.value);
    props.onFilter(value);
  };

  return (
    <th className={classes.th}>
      <Select
        style={{ width: 'calc(100% - 16px)', height: 32 }}
        variant={'outlined'}
        classes={{ root: classes.rootSelect, select: classes.selectedSelect }}
        id={`select-filter-${props.column.name}`}
        value={selectValue}
        onChange={handleSetSelectValue}
        color={'secondary'}
      >
        <MenuItem value={null}>{ALL}</MenuItem>
        <MenuItem value={'on'}>{props.column.filterValues?.true || TRUE}</MenuItem>
        <MenuItem value={'off'}>{props.column.filterValues?.false || FALSE}</MenuItem>
      </Select>
    </th>
  );
};
const BooleanBEFilterCell = props => {
  const classes = useStyles();
  const ALL_VALUE = CONSTANTS.TABLE.FILTER_VALUES.ALL;
  const TRUE_VALUE = CONSTANTS.TABLE.FILTER_VALUES.TRUE;
  const FALSE_VALUE = CONSTANTS.TABLE.FILTER_VALUES.FALSE;
  const [selectValue, setSelectValue] = useState(props?.filter?.value || '');

  const getFilterValue = val => {
    if (!val) return null;
    return { value: val, operation: 'Equal', columnName: props.column.name };
  };

  const handleSetSelectValue = e => {
    setSelectValue(e.target.value);
    let value = getFilterValue(e.target?.value);
    props.onFilter(value);
  };

  return (
    <th className={classes.th}>
      <Select
        style={{ width: 'calc(100% - 16px)', height: 32 }}
        variant={'outlined'}
        classes={{ root: classes.rootSelect, select: classes.selectedSelect }}
        id={`select-filter-${props.column.name}`}
        value={selectValue}
        onChange={handleSetSelectValue}
        color={'secondary'}
      >
        <MenuItem value={''}>{ALL}</MenuItem>
        <MenuItem value={TRUE_VALUE}>{props.column.filterValues?.true || TRUE}</MenuItem>
        <MenuItem value={FALSE_VALUE}>{props.column.filterValues?.false || FALSE}</MenuItem>
      </Select>
    </th>
  );
};

const MultiSelectFilterCell = props => {
  const classes = useStyles();
  const [value, setValue] = useState(props?.filter?.value?.split('&') || []);
  const getFilterValue = val => {
    if (!val?.length) return null;
    return { value: val.join('&'), operation: 'CONTAINS', columnName: props.column.name };
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setValue(typeof value === 'string' ? value.split(',') : value);
    if (value) {
      let filter = getFilterValue(value);
      props.onFilter(filter);
    }
  };

  return (
    <th className={classes.th}>
      <NRMultipleSelectCheckmarks values={props?.column?.filterValues} onChange={handleChange} value={props?.filter?.value?.split('&') || []} />
    </th>
  );
};

const MultiSelectFilterCellFE = props => {
  const classes = useStyles();
  const [value, setValue] = useState(props?.filter?.value?.split('&') || []);
  const getFilterValue = val => {
    if (!val?.length) return null;
    return { value: val?.join('&'), operation: 'contains', columnName: props.column.name };
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setValue(typeof value === 'string' ? value.split(',') : value);
    if (value) {
      let filter = getFilterValue(value);
      props.onFilter(filter);
    }
  };

  return (
    <th className={classes.th}>
      <NRMultipleSelectCheckmarks values={props?.column?.filterValues} onChange={handleChange} value={value} />
    </th>
  );
};
const MultiSelectFilterCellEnum = props => {
  const classes = useStyles();
  const [value, setValue] = useState(props?.filter?.value?.split('&') || []);
  const getFilterValue = val => {
    if (!val?.length) return null;
    return { value: val?.join('&'), operation: 'enum', columnName: props.column.name };
  };

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setValue(typeof value === 'string' ? value.split(',') : value);
    if (value) {
      let filter = getFilterValue(value);
      props.onFilter(filter);
    }
  };

  return (
    <th className={classes.th}>
      <NRMultipleSelectCheckmarks values={props?.column?.filterValues} onChange={handleChange} value={value} />
    </th>
  );
};

export const FilterCell = props => {
  if (props.column.name === 'actions') {
    return <TableFilterRow.Cell {...props}>&nbsp;</TableFilterRow.Cell>;
  } else if (props.column.type === 'booleanNumber') {
    return <FilterSelectCell {...props} />;
  } else if (props.column.type === 'onOff') {
    return <OnOffFilterCell {...props} />;
  } else if (props.column.type === 'booleanBE') {
    // "True" or "False" or ''
    return <BooleanBEFilterCell {...props} />;
  } else if (props.column.type === 'multiselect') {
    return <MultiSelectFilterCell {...props} />;
  } else if (props.column.type === 'multiselectFE') {
    return <MultiSelectFilterCellFE {...props} />;
  } else if (props.column.type === 'multiselectEnum') {
    return <MultiSelectFilterCellEnum {...props} />;
  }
  return <FilterCellBase {...props} />;
};
