export default theme => ({
  mainBox: {
    margin: theme.spacing(2, 0, 1, 0)
  },
  boxContainer: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    borderLeft: `solid ${theme.palette.divider} 1px`,
    borderRight: `solid ${theme.palette.divider} 1px`,
    borderTop: `solid ${theme.palette.divider} 1px`,
    '&:first-child': {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0)
    },
    '&:last-child': {
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      borderBottom: `solid ${theme.palette.divider} 1px`
    }
  },
  selectedBoxContainer: {
    border: `solid ${theme.palette.secondary.main} 2px`,
    '&:last-child': {
      borderBottom: `solid ${theme.palette.secondary.main} 2px`
    }
  },
  alertBox: {
    display: 'flex',
    alignItems: 'center'
  },
  mainBoxStep2: {
    marginTop: theme.spacing(2)
  },
  vexStatuses: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0, 2, 1)
  },
  optionalContainer: {
    margin: theme.spacing(2, 0, 1, 0)
  },
  selectedOption: {
    padding: theme.spacing(2)
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  optionalExport: {
    margin: theme.spacing(2, 0)
  },
  selectedVex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.text.border}`,
    padding: theme.spacing(2)
  },
  innerSelectedVex: {
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    marginTop: '3px'
  },
  vexJustificationSelect: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  vexTooltipIcon: {
    width: '17px',
    height: '17px',
    marginTop: -2,
    color: theme.palette.text.iconInfo
  },
  boxTooltipIcon: {
    paddingLeft: theme.spacing(1)
  }
});
