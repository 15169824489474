import { useEffect, useState } from 'react';
import { isEmpty } from '../utils/utils';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/client';
import CustomError from '../components/CustomError/CustomError';

function useNRLazyQuery({ query, options, qlObjKey, transformData, defaultValue }) {
  const [makeCall, { loading, error, response, called }] = useLazyQuery(query, { ...options, notifyOnNetworkStatusChange: true, onCompleted });
  const { enqueueSnackbar } = useSnackbar();
  const [total, setTotal] = useState(0);
  const [result, setResult] = useState(null);

  const handleMakeCall = options => {
    if (defaultValue !== undefined) {
      setResult(defaultValue);
    }
    makeCall(options);
  };

  function onCompleted(data) {
    if (data && data[qlObjKey]) {
      if (data[qlObjKey].items) {
        setResult(!!transformData ? transformData(data[qlObjKey].items) : data[qlObjKey].items);
      } else {
        setResult(!!transformData ? transformData(data[qlObjKey]) : data[qlObjKey]);
      }
      if (data[qlObjKey].total || data[qlObjKey].total === 0) {
        setTotal(data[qlObjKey].total);
      }
    }
  }

  useEffect(() => {
    if (error && !isEmpty(error)) {
      enqueueSnackbar(<CustomError message={error?.message} />, { variant: 'error' });
      console.log(error);
    }
  }, [error]);

  return [result, loading, called, handleMakeCall, total, error];
}

export default useNRLazyQuery;
