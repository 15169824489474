import NRRightTotalStyles from './NRRightTotal.styles';
import makeStyles from '@mui/styles/makeStyles';
import { capitalizeFirstWord } from '../../utils/utils';
import clsx from 'clsx';
const useStyles = makeStyles(NRRightTotalStyles);

function NRTitleWithRightTotal({ title, totalCount = 0, handleTotalClick, titleComponent, showTotal = true }) {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      {titleComponent}
      {!titleComponent && <div className={classes.title}>{capitalizeFirstWord(title)}</div>}
      {showTotal && (
        <div>
          <span className={clsx({ [classes.totalCount]: !!totalCount && !!handleTotalClick })} onClick={handleTotalClick}>
            {totalCount}
          </span>{' '}
          Total
        </div>
      )}
    </div>
  );
}

export default NRTitleWithRightTotal;
