import { Table } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material';

function RowComponent(props) {
  const classes = makeStyles(theme => ({
    rowHover: {
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.black, 0.04)} !important`
      }
    },
    overrideNoWrap: {
      '& > td': {
        whiteSpace: 'unset !important',
        wordBreak: 'break-word'
      }
    }
  }))();

  return <Table.Row {...props} className={clsx(classes.rowHover, classes.overrideNoWrap)} />;
}

export default RowComponent;
