export default theme => ({
  headerCellStyle: {
    color: theme.palette.text.secondary,
    ...theme.typography.label
  },
  focusedBorder: {
    '&:focus': {
      border: 'unset',
      borderBottom: '1px solid rgb(224, 224, 224) !important'
    }
  },
  noData: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  emptyCell: {
    color: theme.palette.text.secondary
  }
});
