function StickyNoteIcon({ height = '24', width = '24', color = 'currentColor', fillOpacity = '0.6' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5V14H14V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H15L21 15V5C21 3.9 20.1 3 19 3ZM12 14H7V12H12V14ZM17 10H7V8H17V10Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

export default StickyNoteIcon;
