import { alpha } from '@mui/material';

export default theme => ({
  textField: {
    padding: theme.spacing(1),
    '& .MuiInputBase-adornedEnd': {
      padding: theme.spacing(0, 1),
      height: theme.spacing(4)
    }
  },
  textfieldRoot: {
    '& input[type=number]': {
      // removes numeric field up down arrow dials
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  th: {
    fontWeight: 'normal',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0),
    height: theme.spacing(6)
  },
  innerTh: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  inputAdornmentStyles: {
    cursor: 'pointer'
  },
  rootSelect: {
    paddingTop: 0,
    paddingBottom: 0
  },
  selectedSelect: {
    '&:focus': {
      background: 'none'
    }
  },
  showToggleComponent: {
    '& > #toggle-component': {
      color: alpha(theme.palette.secondary.dark, 0.54)
    }
  }
});
