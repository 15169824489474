import { Table } from '@devexpress/dx-react-grid-material-ui';
import makeStyles from '@mui/styles/makeStyles';
import CellComponentStyles from '../Cell.styles';
import NRNoDataPresentComponent from '../../NRNoDataPresentComponent/NRNoDataPresentComponent';
import NRLoader from '../../NRLoader/NRLoader';
import { forwardRef } from 'react';
import clsx from 'clsx';
const useStyles = makeStyles(CellComponentStyles);

export const EMPTY_CELL_VALUE = '-';

export const NRHandleEmptyCellValue = forwardRef(({ value }, ref) => {
  const classes = useStyles();
  return (
    <span ref={ref} className={clsx({ [classes.emptyCell]: !value })}>
      {!!value ? value : EMPTY_CELL_VALUE}
    </span>
  );
});

export const CellComponent = props => {
  const { column, value } = props;
  const classes = useStyles();
  if (!column.isLast) {
    return (
      <Table.Cell
        {...props}
        value={<NRHandleEmptyCellValue value={value} />}
        style={{
          borderLeft: 'unset',
          borderRight: 'unset',
          ...(column.name !== 'info' ? { padding: '10px 16px' } : { padding: '4px 0px' }),
          ...(!!column.actions && { textOverflow: 'unset' })
        }}
        className={classes.focusedBorder}
      />
    );
  }
  return (
    <Table.Cell {...props} style={{ padding: '10px 16px', borderLeft: 'unset', borderRight: 'unset' }}>
      <NRHandleEmptyCellValue value={value} />
    </Table.Cell>
  );
};

export const NoDataCellComponent = ({ loading, noDataComponent, ...props }) => {
  const classes = useStyles();
  return (
    <Table.Cell {...props} className={classes.focusedBorder}>
      <div className={classes.noData}>
        {!loading && <NRNoDataPresentComponent />}
        {!!loading && <NRLoader />}
        {noDataComponent}
      </div>
    </Table.Cell>
  );
};
