function ConfigIcon({ color = 'currentColor', height = '24', width = '24', ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11C16.8638 11 18.4299 9.72523 18.874 8H22V6H18.874C18.4299 4.27477 16.8638 3 15 3C12.7909 3 11 4.79086 11 7C11 9.20914 12.7909 11 15 11ZM15 9C16.1046 9 17 8.10457 17 7C17 5.89543 16.1046 5 15 5C13.8954 5 13 5.89543 13 7C13 8.10457 13.8954 9 15 9Z"
        fill={color}
      />
      <path d="M2 6H9V8H2V6Z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12602 18C5.57006 19.7252 7.13616 21 9 21C11.2091 21 13 19.2091 13 17C13 14.7909 11.2091 13 9 13C7.13616 13 5.57006 14.2748 5.12602 16H2V18H5.12602ZM11 17C11 18.1046 10.1046 19 9 19C7.89543 19 7 18.1046 7 17C7 15.8954 7.89543 15 9 15C10.1046 15 11 15.8954 11 17Z"
        fill={color}
      />
      <path d="M15 16H22V18H15V16Z" fill={color} />
    </svg>
  );
}
export default ConfigIcon;
