import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NRMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      ...theme.typography.body1,
      '& .MuiSvgIcon-root': {
        fontSize: 24,
        color: theme.palette.text.icon,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: theme.palette.background.paper
      }
    }
  }
}));
