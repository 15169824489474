export default theme => ({
  bigMessage: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    WebkitLineClamp: 2,
    maxWidth: `calc(100% - 45px)`
  }
});
