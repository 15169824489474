import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import makeStyles from '@mui/styles/makeStyles';
import ReportBreadcrumbStyles from './ReportBreadcrumb.styles';
import { useSnackbar } from 'notistack';
import { CONSTANTS } from '../../../constants/constants';
import { useState } from 'react';
import ReportPopoverContainer from '../../ReportPopover/ReportPopoverContainer';
import Typography from '@mui/material/Typography';
import { GET_ASSET_NAME } from '../../../graphql/queries/asset';
import useNRQuery from '../../../graphql/useNRQuery';
const useStyles = makeStyles(ReportBreadcrumbStyles);

function ReportBreadcrumb({ match }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [assetName] = useNRQuery({
    query: GET_ASSET_NAME,
    options: {
      context: { version: 'v3' },
      fetchPolicy: 'cache-first',
      variables: {
        args: {
          assetId: match.params.componentId
        }
      }
    },
    qlObjKey: 'asset',
    transformData: data => {
      return data?.name;
    }
  });
  const onCopyJobGUID = () => {
    navigator.clipboard.writeText(window.location.href);
    enqueueSnackbar(CONSTANTS.REPORT_URL_COPIED, { variant: 'success', preventDuplicate: true, autoHideDuration: 1000 });
  };

  return (
    <>
      <ReportPopoverContainer
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        componentId={match.params.componentId}
        popoverClassesOverride={{ paper: classes.popoverPaper }}
      />
      <Typography variant="body2" component="span" className={classes.cursor} onClick={onCopyJobGUID}>
        {assetName}
        <FileCopyOutlinedIcon className={classes.icon} />
      </Typography>
      {/* TO DO: Uncomment when digest fix is completed */}
      {/* <InfoOutlinedIcon className={`${classes.icon} ${classes.cursor}`} onClick={ev => setAnchorEl(ev.target)} /> */}
    </>
  );
}

export default ReportBreadcrumb;
