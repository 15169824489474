import { useTheme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import NRCircleWarningIcon from '../../NRIcons/NRCircleWarningIcon';
import NRValueTooltipIcon from '../NRReactGridCustomComponents/NRCustomProviders/NRValueTooltipIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NRRemediationStepsStyles from './NRRemediationSteps.styles';
const useStyles = makeStyles(NRRemediationStepsStyles);

function RemediationBox({ title, subtitle, optValue, showAlert = false, avoidSelection = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const { watch, setValue, control } = useFormContext();
  const form = watch();

  return (
    <FormControl fullWidth={true} sx={{ overflowX: 'auto' }}>
      <Controller
        control={control}
        id={'remediation-status'}
        name={'status'}
        required={false}
        render={({ onChange }) => {
          return (
            <Box
              className={clsx(classes.boxContainer, { [classes.selectedBoxContainer]: optValue === form.status && !avoidSelection })}
              onClick={() => onChange(optValue)}
            >
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'subtitle1'}>{title}</Typography>

                <Box className={classes.alertBox}>
                  {showAlert && (
                    <>
                      <NRCircleWarningIcon color={theme.palette.weightedColors[3][450]} />
                      <Typography sx={{ paddingLeft: 1 }} variant={'caption'}>
                        Removes risk from report.
                      </Typography>{' '}
                    </>
                  )}
                  <NRValueTooltipIcon
                    icon={<InfoOutlinedIcon className={classes.vexTooltipIcon} />}
                    tooltip={subtitle}
                    style={{ paddingLeft: theme.spacing(1) }}
                  />
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </FormControl>
  );
}

export default RemediationBox;
