import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import CustomErrorStyles from './CustomError.styles';

const useStyle = makeStyles(CustomErrorStyles);

function CustomError({ message }) {
  const classes = useStyle();
  return (
    <div className={clsx({ [classes.bigMessage]: message?.length > 150 })}>{message || 'Oops! Something went wrong, please try again later.'}</div>
  );
}

export default CustomError;
