import React from 'react';
import AlertCircle from 'react-feather/dist/icons/alert-circle';
import Info from 'react-feather/dist/icons/info';
import AlertTriangle from 'react-feather/dist/icons/alert-triangle';
import Bell from 'react-feather/dist/icons/bell';
import CheckCircle from 'react-feather/dist/icons/check-circle';
import X from 'react-feather/dist/icons/x';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  containerRoot: {
    maxWidth: '50vw',
    '& > div.SnackbarContent-root': {
      flexWrap: 'nowrap',
      '& > #notistack-snackbar': {
        flexGrow: 1
      }
    }
  }
}));

const NRCloseMenuIcon = styled(props => <X {...props} />)(({ theme }) => ({
  stroke: theme.palette.text.primaryAlt
}));

function NRSnackbarProvider(props) {
  const theme = useTheme();
  const classes = useStyles();

  const notistackRef = React.createRef();
  const snackbarIcons = {
    default: <Bell style={{ marginRight: theme.spacing(2) }} />,
    error: <AlertCircle style={{ marginRight: theme.spacing(2) }} />,
    info: <Info style={{ marginRight: theme.spacing(2) }} />,
    success: <CheckCircle style={{ marginRight: theme.spacing(2) }} />,
    warning: <AlertTriangle style={{ marginRight: theme.spacing(2) }} />
  };

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.text.primaryAlt
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.text.primaryAlt
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.primaryAlt
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.primaryAlt
    }
  }));

  return (
    <SnackbarProvider
      maxSnack={4}
      iconVariant={snackbarIcons}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={6000}
      preventDuplicate={true}
      classes={{ root: classes.containerRoot }}
      ref={notistackRef}
      Components={{
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent
      }}
      action={key => (
        <IconButton size="small" color="inherit" classes={{ colorInherit: classes.closeButtonColor }}>
          <Tooltip title="Dismiss">
            <NRCloseMenuIcon onClick={() => closeSnackbar(key)} />
          </Tooltip>
        </IconButton>
      )}
    >
      {props.children}
    </SnackbarProvider>
  );
}
export default NRSnackbarProvider;
