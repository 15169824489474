import { Divider, useScrollTrigger } from '@mui/material';
import NRLink from '../../../NRLink/NRLink';
import { CONSTANTS } from '../../../../constants/constants';
import { makeStyles } from '@mui/styles';
import NRReferencesStyles from './NRReferences.styles';
import { Fragment, useContext, useEffect } from 'react';
import NRShowMore from '../../../NRShowMore/NRShowMore';
import { VulnDrawerTabsContext } from '../NRVulnerabilityDetails';
import clsx from 'clsx';
import UpperCaseGreyText from '../../../UpperCaseGreyText/UpperCaseGreyText';
import NRTitleWithRightTotal from '../../../NRRightTotal/NRRightTotal';
import NRNoData from '../../../NRDependencies/NRNoData/NRNoData';

const { TITLE } = CONSTANTS.TABLE.VULNERABILITIES_TABLE.VULNERABILITY_DETAIL_DRAWER.REFERENCES_SECTION;

const useStyles = makeStyles(NRReferencesStyles);

const NRReferences = ({ cveDetails, threshold, scrollTarget, index, scrollableId, extraMainContainerClasses, showName, context, title = TITLE }) => {
  const { setScrollSpyTabsValue } = useContext(context || VulnDrawerTabsContext);
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: !!scrollTarget ? scrollTarget : undefined
  });

  useEffect(() => {
    setScrollSpyTabsValue(index, trigger);
  }, [trigger]);

  return (
    <div id={scrollableId} className={clsx(classes.mainContainer, extraMainContainerClasses)}>
      <NRTitleWithRightTotal totalCount={cveDetails?.references?.length || 0} title={title} />
      {!!cveDetails?.references?.length && (
        <NRShowMore
          maxHeight={300}
          items={cveDetails?.references?.map((reference, i) => (
            <Fragment key={`nr-reference-link-${i}`}>
              {reference?.name && !!showName && <UpperCaseGreyText text={reference?.name} />}
              <NRLink url={reference?.url} />
            </Fragment>
          ))}
        />
      )}
      {!cveDetails?.references?.length && <NRNoData noDataTitle={title} />}
      <Divider className={classes.divider} />
    </div>
  );
};
export default NRReferences;
