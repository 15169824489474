import NRMenu from '../../pages/NRMenu/NRMenu';
import NRBreadcrumbs from '../NRBreadcrumbs/NRBreadcrumbs';
import NRHelpDrawer from '../NRHelpDrawer/NRHelpDrawer';
import NRTaskManager from '../NRTaskManager/NRTaskManager';

function NRLayout({ children }) {
  return (
    <NRMenu>
      <NRHelpDrawer />
      <NRBreadcrumbs />
      <NRTaskManager />
      {children}
    </NRMenu>
  );
}
export default NRLayout;
