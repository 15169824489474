import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import ConsumerServiceIcon from '../ConsumerServiceIcon/ConsumerServiceIcon';

function NRSelect({
  className,
  size,
  variant,
  error,
  required,
  disabled,
  id,
  label,
  value,
  onChange,
  options,
  helperText,
  color = 'secondary',
  selectClasses,
  emptyValueLabel = 'None',
  showEmptyValue = true,
  displayEmpty = false,
  icon
}) {
  return (
    <FormControl className={className} size={size} variant={variant} error={error} required={!!required} disabled={disabled}>
      {!!label && (
        <InputLabel id={`label-${id}`} color={color}>
          {label}
        </InputLabel>
      )}
      <Select
        labelId={`label-${id}`}
        id={`selectId-${id}`}
        value={value}
        onChange={e => onChange(e.target.value)}
        label={label}
        data-testid={'testIdSelect'}
        color={color}
        displayEmpty={displayEmpty}
        className={selectClasses}
        {...(!!icon && { IconComponent: icon })}
      >
        {!required && showEmptyValue && (
          <MenuItem value={''} key={'empty-value'}>
            {emptyValueLabel}
          </MenuItem>
        )}
        {options &&
          options.map(({ value, label }) => (
            <MenuItem value={value} key={value} data-testid={'select-option'}>
              <ConsumerServiceIcon className={'select-icon'} serviceName={value} marginR={true} />
              {label}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default NRSelect;
