import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

function NRHighlightedText({
  style,
  text,
  color,
  backgroundColor,
  icon,
  fontSize,
  lineHeight,
  highlightAll = false,
  alphaValue = 0.08,
  tooltipContent = '',
  minWidth = null,
  showBorder = false,
  justifyContent = null,
  handleOnClick,
  textAlphaValue = 1
}) {
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      ...(justifyContent && { justifyContent })
    },
    textStyleBase: {
      textTransform: 'capitalize',
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0.5, text ? 1 : 0.5),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      color: alpha(color, textAlphaValue),
      backgroundColor: alpha(backgroundColor || color, alphaValue),
      ...(fontSize && { fontSize }),
      ...(fontSize && { lineHeight: `${lineHeight || fontSize}px` }),
      ...(minWidth && { minWidth }),
      ...(showBorder && { border: `1px solid ${alpha(color, textAlphaValue)}` }),
      ...(justifyContent && { justifyContent })
    }
  }));

  const classes = useStyles();

  return (
    <Tooltip title={tooltipContent} placement="top">
      <div style={style} className={classes.container} {...(!!handleOnClick && { onClick: () => handleOnClick() })}>
        {!highlightAll && icon && icon}
        <span className={clsx(classes.textStyleBase, classes.textStyleHigh)}>
          {highlightAll && icon && icon}
          {text}
        </span>
      </div>
    </Tooltip>
  );
}
export default NRHighlightedText;
