import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import ConfigIcon from '../../NRIcons/ConfigIcon';
import { useTheme } from '@mui/material/styles';
import { RemediationContext } from '../NRRemediation/NRRemediationContext';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ComponentContext } from '../../ContextWrappers/NRComponentContextWrapper';

function NRShowOriginalStateSelector({}) {
  const theme = useTheme();
  const { showOriginalState, setShowOriginalState } = useContext(RemediationContext);
  const { asset } = useContext(ComponentContext);

  const handleButtonChange = newState => {
    setShowOriginalState(newState);
  };

  return (
    <>
      {asset?.hasRemediation && (
        <Box display={'flex'} alignItems={'flex-start'} mr={0.5} ml={1} mt={'9px'}>
          <Box display={'flex'} alignItems={'center'}>
            <ButtonGroup color="secondary">
              <Button variant={!showOriginalState ? 'outlined' : 'contained'} onClick={() => handleButtonChange(true)}>
                <Typography variant={'body2'}>Original</Typography>
              </Button>
              <Button
                variant={showOriginalState ? 'outlined' : 'contained'}
                onClick={() => handleButtonChange(false)}
                startIcon={
                  <ConfigIcon
                    color={
                      theme.palette.mode === 'light'
                        ? !showOriginalState
                          ? theme.palette.text.iconAlt
                          : theme.palette.text.icon
                        : !showOriginalState
                        ? theme.palette.text.grayBadge.color
                        : theme.palette.text.iconInfo
                    }
                  />
                }
              >
                <Typography variant={'body2'}>Modified</Typography>
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      )}
    </>
  );
}
export default NRShowOriginalStateSelector;
