export const drawerWidth = 72;

export default theme => ({
  menu: {
    display: 'flex'
  },
  content: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.NRResponsiveDimensions.menuContent[theme.breakpoints.down('lg')].padding,
      paddingTop: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.NRResponsiveDimensions.menuContent.padding,
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight}px)`,
      marginTop: theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight
    },
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight + 16}px)`,
      marginTop: theme.mixins.toolbar.minHeight
    },
    flexGrow: 1,
    width: '80vw',
    display: 'flex',
    flexDirection: 'column'
  },
  appBarRoot: {
    boxShadow: 'unset'
  },
  appBar: {
    display: 'flex',
    flexFlow: 'column',
    paddingRight: 0
  },
  appBarLayout: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between'
  },
  avatar: {
    alignSelf: 'center',
    paddingRight: theme.spacing(2)
  },
  pendoIcon: {
    color: theme.palette.text.icon,
    display: 'flex',
    alignSelf: 'center',
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
